import React from 'react';

import { Sankey, Hint, makeWidthFlexible, makeHeightFlexible } from 'react-vis';
import { AutoSizer } from 'react-virtualized';
import { fixTranslateSankey } from './fixTranslateSankey';

const BLURRED_LINK_OPACITY = 0.4;
const FOCUSED_LINK_OPACITY = 0.7;


export default class HintSankey extends React.Component {
  state = {
    activeLink: null
  };

  _renderHint() {
    const { activeLink } = this.state;

    // calculate center x,y position of link for positioning of hint
    const x =
      activeLink.source.x1 + (activeLink.target.x0 - activeLink.source.x1) / 2;
    const y = activeLink.y0 - (activeLink.y0 - activeLink.y1) / 2;

    const hintValue = {
      [`${activeLink.source.name} ➞ ${activeLink.target.name
        }`]: activeLink.value
    };

    return <Hint x={x} y={y} value={hintValue} />;
  }

  render() {

    const {activeLink} = this.state;
    const {nodes, links} = this.props;
    const nodes2 = [...nodes]    

    //const FlexibleSankey = makeHeightFlexible(Sankey);

    // Note: d3.sankey currently mutates the `nodes` and `links` arrays, which doesn't play nice
    // with React's single-direction data flow. We create a copy of each before we pass to the sankey
    // component, just to be sure.
    return (
      <AutoSizer>
        {({ height, width }) => (
          <Sankey
            nodes={nodes2.map(d => (
            fixTranslateSankey(d), {...d}
            ))}
            links={links.map((d, i) => ({
              ...d,
              opacity:
                activeLink && i === activeLink.index
                  ? FOCUSED_LINK_OPACITY
                  : BLURRED_LINK_OPACITY
            }))}
            width={width}
            height={height}
            // do not use voronoi in combination with link mouse over
            hasVoronoi={false}
            /*nodePadding={30}*/
            nodeMargin={0}
            layout={500}
            align={"left"}
            onLinkMouseOver={node => this.state.activeLink !== node && this.setState({ activeLink: node })}
            onLinkMouseOut={() => this.setState({ activeLink: null })}
            onLinkClick={this.props.onLinkClick}
            style={{
              links: {
                cursor: "pointer"
              },
              labels: {
                fontFamily: "Roboto",
                fontSize: "12px"
              }
            }}
          />
        )}
      </AutoSizer>
    );
  }
}
