import React, { Component } from "react";
import Cross from "../../assets/settings-cross.png";
import "./Tag.scss";

class Tag extends Component {
  render() {
    let { obj } = this.props;
    let newVal;
    if (obj.hasOwnProperty("last_name")) {
      newVal = `${obj.first_name} ${obj.last_name}`;
    } else if (obj.hasOwnProperty("label")) {
      newVal = obj.label;
    }
    else {
      newVal = obj.name;
    }

    return (
      <div className="settings-tag-container">
        <span className="tag">{newVal}</span>
        <button onClick={(e) => this.props.onDelete(e,obj)}>
          <img src={Cross} alt={"Eliminar"}/>
        </button>
      </div>
    );
  }
}

export default Tag;
