import React, { Component } from "react";
import "./AddTags.scss";
import Add from "../../assets/settings-add.png";
import Tag from "../../components/Tag";
import Select from "react-select";
import { FormattedMessage } from "react-intl";

const SelectStyles = {
  control: styles => ({
    ...styles,
    backgroundColor: "white",
    width: "100%",
    height: "100%",
    minHeight: 0,
    border: "none",
    padding: "0 10px",
    borderRadius: "2px"
  }),
  option: (styles, { isSelected }) => {
    return {
      ...styles,
      border: "none",
      height: '100%',
      backgroundColor: isSelected && '#EFF3F9'
    };
  },
  input: styles => ({ ...styles, color: 'rgba(0, 18, 75, 0.5)' }),
  placeholder: styles => ({ ...styles })
};

class AddTags extends Component {
  render() {
    let { data, input, botTag, options, bots, resultFilterTag, cyclesFilter } = this.props;

    let arr = []
    if (options && options.length > 0) {
      if (bots) {
        options.forEach((user) => {
          arr.push({ value: user.id, label: `${user.first_name} ${user.last_name}` })
        })
      } else if (!resultFilterTag) {
        options.forEach((bot) => {
          arr.push({ value: bot.id, label: bot.name })
        })
      } else if (cyclesFilter) {
        options.forEach((cycle) => {
          arr.push({ value: cycle, label: cycle })
        })
      }
      else {
        options.forEach((result) => {
          arr.push({ value: result.name, label: result.name })
        })
      }
    }

    return (
      <div className="add-tags-container">
        <div className="add-input-container">
          {/* Adicionar => Agregar */}
          {/* <Select
            className="add-select"
            classNamePrefix="select"
            value={input}
            isSearchable={true}
            name="input"
            options={arr}
            noOptionsMessage={() => 'No hay opciones' }           
            placeholder={botTag ? "Asignar permiso" : (resultFilterTag) ? "Adicionar filtro" : "Asignar Bot"}
            onChange={value => this.props.onSelectChange(value)}
            styles={SelectStyles}
          /> */}

          <FormattedMessage
            id={(botTag) ? "newcamp.filter.permission" : (resultFilterTag) ? "newcamp.filter.filter" : "newcamp.filter.bot"} defaultMessage="ASIGNACION">
            {placeholder =>
              <Select
                className="add-select"
                classNamePrefix="select"
                value={input}
                isSearchable={true}
                name="input"
                options={arr}
                noOptionsMessage={() => <FormattedMessage id="newcamp.filter.no.options" />}
                placeholder={placeholder}
                // placeholder={botTag ? "Asignar permiso" : (resultFilterTag) ? "Adicionar filtro" : "Asignar bot"}
                onChange={value => this.props.onSelectChange(value)}
                styles={SelectStyles}
              />
            }
          </FormattedMessage>

          <button onClick={this.props.onAdd}>
            <img src={Add} alt="Agregar" />
          </button>
        </div>
        {data.map((obj, i) => {
          return <Tag key={i} obj={obj} onDelete={this.props.onDelete} />;
        })}
      </div>
    );
  }
}

export default AddTags
