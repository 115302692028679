import React, { Component, useState, useEffect } from 'react'
import EmptyState from '../EmptyState'
import { apiToken } from "../../lib/headers";
import { instance as axios } from "../../lib/axios";
import moment from 'moment-timezone';

import 'react-chat-elements/dist/main.css';
import { MessageBox } from 'react-chat-elements';
import ReactPlayer from 'react-player';

import './CallDetails.scss'
import { FormattedMessage } from 'react-intl';

const AuthenticatedPlayer = ({ audioUrl }) => {
  const options = {
      headers: {
          Authorization: `JWT ${apiToken()}`,
      },
      responseType: "blob"
  }
  const [url, setUrl] = useState()
  useEffect(() => {
      axios.get(audioUrl, options)
      .then(response => response.data)
          .then(data => {
            const blob = new Blob([data], {type: "audio/wav; codecs=MS_PCM"});
            setUrl(URL.createObjectURL(blob));
      });
  }, [audioUrl])
 
  
  return (
      <ReactPlayer 
        url={url}
        width="100%"
        height="54px"
        config={
          {
            file: {
              forceAudio: true
            }
          }
        }
        controls />
  )
}

class CallDetails extends Component {

  render() {
    let { data, name, surname, recording, activeBot, isInbound, datetime,timezoneBot } = this.props
    name = name ? name : isInbound ? 'Cliente' : 'Bot';
    surname = name ? surname : 'Anónimo';
    let logs = [];

    if (data) {
      let { responses, transcription } = data;
      if (responses && Array.isArray(responses)) {
        responses.forEach(each => logs.push({ ...each, user: false }));
      }
      if (transcription && Array.isArray(transcription)) {
        transcription.forEach(each => logs.push({ ...each, user: true }));
      }

      logs = logs.sort((a, b) => {
        return a.time > b.time ? 1 : -1
      })

      if (logs.length && timezoneBot)
        datetime = moment(new Date(logs[0].time * 1000)).tz(timezoneBot).format('L LT');

    }

    return (
      <div>
        <div className={'call-details-container'}>
          <div className={'call-details-title'}>
            <span>
              <FormattedMessage id="case.Detalle" />
              {/* DETALLE LLAMADA */}
              {datetime}
            </span></div>
          <div className={`call-details-content`}>
            {logs.length ?
              <div className={'call-detail-chats'}>
                {logs.map((log, i) => {
                  let avatar = 'https://ui-avatars.com/api/?background=00124B&color=fff&name=AV';
                  if (log.user) {
                    avatar = `https://ui-avatars.com/api/?background=777B8D&color=fff&name=${name}+${surname}`
                  }
                  let message = log.text instanceof Array ? log.text[0] : log.text;
                  return <MessageBox
                    key={i}
                    title={log.user ? name : (isInbound ? activeBot : 'Agente Virtual')}
                    titleColor={'#00124B'}
                    avatar={avatar}
                    date={new Date(log.time * 1000)}
                    dateString={moment(new Date(log.time * 1000)).tz(timezoneBot).format('L LT')}
                    position={log.user ? 'left' : 'right'}
                    text={message.replaceAll("<br>", "\n")} />
                })}
              </div> :
              <EmptyState
                text={<FormattedMessage id="empty.details" />}
              // text={'No hay detalles disponibles'}
              />
            }
          </div>
        </div>
        {recording &&
          <AuthenticatedPlayer audioUrl={recording} />
        }
      </div>

    )
  }
}

export default CallDetails