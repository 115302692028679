import { STORE_BOTS } from "./actions";


export default function (state = [], action) {
    switch (action.type) {
        case STORE_BOTS:
            return action.payload
        default: return state
    }
}
