import React, { Component } from "react";
import CustomDatePicker from "../CustomDatePicker";
import Cross from "../../assets/red.png";
import "./NewCaseForm.scss";
import InputMask from "react-input-mask";
import NumberFormat from "react-number-format";
import Loader from "../Loader";

class NewCaseForm extends Component {
  render() {
    const {
      name,
      nameInvalid,
      surname,
      surnameInvalid,
      id,
      idInvalid,
      product,
      productInvalid,
      debt,
      debtInvalid,
      phone,
      phoneInvalid,
      date,
      xhr
    } = this.props;

    return (
      <form className={"new-case-form-container"}>
        <img
          src={Cross}
          alt={"Cerrar"}
          className={"cross"}
          onClick={this.props.onClose}
        />
        <div className={"new-case-form"}>
          {xhr && <Loader/>}
          <div className={"new-case-form-title"}>
            <span>{"AGREGAR UN CASO"} </span>
          </div>
          <div className={`new-case-form-content ${xhr && "xhr"}`}>
            <div className={"new-case-form-inputs row"}>
              <div className="new-case-form-input">
                <input
                  value={name}
                  placeholder={"Nombre"}
                  name={"nameInput"}
                  onChange={e => this.props.onChange(e.target)}
                />
                <label className={"error"}>{nameInvalid ? "* Ingrese un nombre" : ""}</label>
              </div>
              <div className="new-case-form-input">
                <input
                  value={surname}
                  placeholder={"Apellido"}
                  name={"surnameInput"}
                  onChange={e => this.props.onChange(e.target)}
                />
                <label className={"error"}>{surnameInvalid ? "* Ingrese un apellido" : ""}</label>
              </div>
            </div>
            <div className={"new-case-form-second-row row"}>
              <div className={"field"}>
                <label>Código Identificatorio</label>
                <input
                  value={id}
                  placeholder={"000000000"}
                  name={"idInput"}
                  onChange={e => this.props.onChange(e.target)}
                />
                <label className={"error"}>{idInvalid ? "* Ingrese un código identificatorio válido" : ""}</label>
              </div>
            </div>
            <div className={"new-case-form-third-row row"}>
              <div className={"field"}>
                <label>Producto</label>
                <input
                  value={product}
                  placeholder={"Producto"}
                  name={"productInput"}
                  onChange={e => this.props.onChange(e.target)}
                />
                <label className={"error"}>{productInvalid ? "* Ingrese un producto válido" : ""}</label>
              </div>
              <div className={"field"}>
                <label>Deuda</label>
                <NumberFormat
                  value={debt}
                  placeholder={"00.000,00"}
                  name={"debtInput"}
                  onChange={e => this.props.onChange(e.target)}
                  thousandSeparator={"."}
                  decimalScale={2}
                  fixedDecimalScale
                  decimalSeparator={","}
                />
                <label className={"error"}>{debtInvalid ? "* Ingrese una deuda válida" : ""}</label>
              </div>
            </div>
            <div className={"new-case-form-forth-row row"}>
              <div className={"field"}>
                <label>Teléfono</label>
                <InputMask
                  value={phone}
                  placeholder={"+54 (911) 0000-0000"}
                  mask={"+54 (999) 9999-9999"}
                  name={"phoneInput"}
                  onChange={e => this.props.onChange(e.target)}
                />
                <label className={"error"}>{phoneInvalid ? "* Ingrese un teléfono válido" : ""}</label>
              </div>
              <div className={"expiration field"}>
                <label>Vencimiento</label>
                <CustomDatePicker onDateChange={this.props.onDateChange} date={date} width={"full"}/>
                <label className={"error"}>{""}</label>
              </div>
            </div>
          </div>
          <div className={`new-case-form-btns ${xhr && "xhr"}`}>
            <button className={"next"} onClick={this.props.onClick} disabled={xhr}>
              AGREGAR
            </button>
            <button className={"cancel"} onClick={this.props.onClose} disabled={xhr}>
              Cancelar
            </button>
          </div>
        </div>
      </form>
    );
  }
}

export default NewCaseForm;