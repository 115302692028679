import React from 'react';
import { FormattedMessage } from 'react-intl';

const columns = [
  {
    dataField: 'Id',
    text: 'ID',
  },  
  {
    dataField: 'Status',
    text: <FormattedMessage id="export.Status"/>,
  },
  {
    dataField: 'Filters',
    text: <FormattedMessage id="export.Filters"/>,
  },  
  {
    dataField: 'Created at',
    text: <FormattedMessage id="export.Created_at"/>,
  },
  {
    dataField: 'File',
    text: <FormattedMessage id="export.File"/>,
    style: {
      // cursor:'pointer', color: '#0066CA'
    },    
  },
];

export default columns;
