import React from 'react';
import './Call.scss'
import Phone from '../../assets/phone-santander.png'
import PinkPhone from '../../assets/phone-pink.png'
import Status from '../Status'
import Duration from '../../assets/duration-santander.png'
import PinkDuration from '../../assets/duration-santander.png'
import Anchor from '../../assets/anchor-santander.png'
import AnchorPink from '../../assets/anchor-santander.png'
import Download from '../../assets/download.png'
import {
  Card,
  CardActions,
  CardContent,
  IconButton,
  Collapse,
  Divider,
  List,
  ListItem,
  Grid,
  Typography,
} from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";
import { FormattedMessage } from 'react-intl'
import { apiToken } from "../../lib/headers";
import { instance as axios } from "../../lib/axios";
import moment from 'moment-timezone';

const css = {
  root: {
    marginBottom: 10,
    cursor: "pointer",
  },
  active: {
    borderColor: red[600],
  },
  content: {
    color: red[600],
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0px 15px",
    "&:last-child": {
      paddingBottom: 5
    },
  },
  call: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    '& img': {
      marginLeft: 8,
      height: 20,
    },
    '& span': {
      fontSize: 15,
      lineHeight: "18px",
      letterSpacing: 0.4,
      fontWeight: 500,
    },
  },
  phone: {
    margin: "auto 18px",
  },
  info: {
    width: "100%",
  },
  download: {
    marginRight: 18,
    '& img': {
      height: 20,
    },
    '& button': {
      textDecoration: "none",
      display: "inline-block",
      background: "transparent",
      border: "none",
      cursor: "pointer",
      userSelect: "none",
      outline: 0,
    }
  },
  duration: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    '& img': {
      height: 20,
      marginRight: 8,
    },
    '& span': {
      fontSize: 14,
      lineHeight: "15px",
    },
  },
  cardaction: {
    marginRight: -15,
    marginLeft: 5,
  },
  extraItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}

const useStyles = makeStyles(theme => (css));

const downloadAudio = (call) => {
  const options = {
    headers: {
        Authorization: `JWT ${apiToken()}`,
    },
    responseType: "blob"
  }
  axios.get(call.recording, options)
      .then(response => response.data)
          .then(data => {
            const blob = new Blob([data], {type: "audio/wav; codecs=MS_PCM"});
            const aElement = document.createElement('a');
            
            // build recording filename from id and timestamp
            const date = moment(call.last_updated, "YYYY-MM-DDThh:mm:ss.SSSSSS");
            let month = date.month()+1;
            const callMonth = month.toString().padStart(2, "0");
            let day = date.day();
            const callDay = day.toString().padStart(2, "0");
            let hour = date.hour();
            const callHour = hour.toString().padStart(2, "0");
            let minute = date.minute();
            const callMinute = minute.toString().padStart(2, "0");
            const fileName = `recording_${call.id}-${date.year()}${callMonth}${callDay}_${callHour}${callMinute}.wav`;
            
            aElement.setAttribute('download', fileName);

            const href = URL.createObjectURL(blob);

            aElement.href = href;
            aElement.setAttribute('target', '_blank');
            aElement.click();
            URL.revokeObjectURL(href);
          });
};

export default function Call(props) {
  const classes = useStyles();

  let { call, activeCall, extra } = props;

  let color = 'success';
  let img = Anchor;
  if (!call.is_final) {
    color = 'pink';
    img = AnchorPink;
  }

  let extra_info = []

  let i = 1;
  let value;
  if (extra) {
    _.forEach(extra, (k, v) => {
      let newKey = v.replace(/_/g, ' ');
      if (k instanceof Array) {
        value = '[' + k.join(', ') + ']';
      } else {
        value = k;
      }
      // Splitting the 'value' variable into lines using the '\n' separator if string
      const lines = typeof value !== 'string'?[value]:value.split('\n');
      extra_info.push(
        <ListItem className={classes.extraItem}>
          <Grid container spacing={3}>
            <Grid item xs={2}>
              <span>{newKey[0].toUpperCase() + newKey.slice(1)}</span>
            </Grid>
            <Grid item xs={10}>
              {lines.map((line, index) => (
                <Typography key={index} variant="body1" component="div">
                  <span>{line !== null ? line.toString() : ""}</span>
                </Typography>
              ))}
            </Grid>
          </Grid>
        </ListItem>
      )
      if (!(i === Object.keys(extra).length))
        extra_info.push(<Divider />)
      i++;
    })
  }

  return (
    <Card className={`${classes.root} ${call.id === activeCall && classes.active}`} variant="outlined" onClick={props.onClick}>
      <CardContent className={classes.content}>
        <div className={classes.call}>
          <span>#{call.gateway_id}</span>
          <img src={call.is_final ? Phone : PinkPhone} alt={'Llamada'} />
          <span className={classes.phone}>{call.phone}</span>
        </div>
        <div className={classes.info}>
          <Status status={call.result} color={color} size={'small'} from={'call'} />
        </div>
        {call.duration && (
          <div className={classes.download}>
            <button><img src={Download} alt={'Descargar'} onClick={() => downloadAudio(call)}/></button>
          </div>
        )}
        <div className={classes.duration}>
          {call.duration && (
            <img src={call.is_final ? Duration : PinkDuration} alt={'Duración'} />
          )}
          {call.duration && <span>{call.duration}</span>}
        </div>
        <CardActions disableSpacing>
          <IconButton className={classes.cardaction}>
            {call.id === activeCall ? <ExpandMoreIcon /> : <ChevronRightIcon />}
          </IconButton>
        </CardActions>
      </CardContent>
      <Collapse in={call.id === activeCall} unmountOnExit>
        <Divider />
        <CardContent>
          {extra_info.length ? (
            <List>
              {_.forEach(extra_info, info => info)}
            </List>
          ) : 
              <FormattedMessage id="empty.info"/>  
          // "No hay información adicional"
            }
        </CardContent>
      </Collapse>
    </Card>
  )
}
