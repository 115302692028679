import React, { Component } from "react";
import _ from 'lodash';

import "./ReportingInbound_detail.scss";

import Filters from "../Filters";
import InboundTable from "../InboundTable";
import HintSankey from "./HintSankey";
import { Alert , AlertTitle} from '@material-ui/lab';

 

class ReportingInbound_detail extends Component {

    constructor(props) {
        super(props);

        this.state = {
            'gotPromise': null,
        }

        this.casesReceivedTag = 'RECIBIDOS'
        this.casesReceivedTarget = 0
        this.casesProcessedTag = 'GESTIONADOS'
        this.casesProcessedTarget = 1
        this.successfulCallTag = 'LLAMADA EXITOSA'
        this.ownerTag = 'TITULAR'
        this.casesDiscardedTag = 'DESCARTADOS'
        this.casesDiscardedTarget = 2
        this.unsuccessfulCallTag = 'LLAMADA FALLIDA'
        this.restingTag = 'DESCANSO'
        this.casesWithErrorsTag = 'DATOS ERRÓNEOS'
        this.commitmentsTag = 'COMPROMISOS'
        this.noCommitmentsTag = 'SIN COMPROMISO'
        this.excuseWithCommitmentsTag = 'CON EXCUSA'
        this.excuseTag = 'EXCUSAS'

        this.restResultMap = {
            23: {
                name: 'COMPROMISO',
                groups: [this.casesReceivedTag, this.casesDiscardedTag, this.restingTag]
            },
            25: {
                name: 'HIPOTECA',
                groups: [this.casesReceivedTag, this.casesDiscardedTag, this.restingTag]
            },
            26: {
                name: 'NO ACEPTA LLAMADA',
                groups: [this.casesReceivedTag, this.casesDiscardedTag, this.restingTag]
            },
            24: {
                name: 'SOBREGIRO',
                groups: [this.casesReceivedTag, this.casesDiscardedTag, this.restingTag]
            },
            5: {
                name: 'CESANTE',
                groups: [this.casesReceivedTag, this.casesDiscardedTag, this.restingTag]
            },
            18: {
                name: 'FALLECIDO',
                groups: [this.casesReceivedTag, this.casesDiscardedTag, this.restingTag]
            },
            15: {
                name: 'TRANSFER',
                groups: [this.casesReceivedTag, this.casesDiscardedTag, this.restingTag]
            },
            0: {
                name: 'OCUPADO',
                groups: [this.casesReceivedTag, this.casesDiscardedTag, this.restingTag]
            },
            1: {
                name: 'INDEFINIDO',
                groups: [this.casesReceivedTag, this.casesDiscardedTag, this.restingTag]
            },
            2: {
                name: 'LLAMANDO',
                groups: [this.casesReceivedTag, this.casesDiscardedTag, this.restingTag]
            },
            3: {
                name: 'YA PAGÓ',
                groups: [this.casesReceivedTag, this.casesDiscardedTag, this.restingTag]
            },
            4: {
                name: 'NO COBRÓ SUELDO',
                groups: [this.casesReceivedTag, this.casesDiscardedTag, this.restingTag]
            },
            6: {
                name: 'SE OLVIDÓ',
                groups: [this.casesReceivedTag, this.casesDiscardedTag, this.restingTag]
            },
            7: {
                name: 'NO PUEDE PAGAR',
                groups: [this.casesReceivedTag, this.casesDiscardedTag, this.restingTag]
            },
            8: {
                name: 'VACACIONES',
                groups: [this.casesReceivedTag, this.casesDiscardedTag, this.restingTag]
            },
            9: {
                name: 'NÚMERO EQUIVOCADO',
                groups: [this.casesReceivedTag, this.casesDiscardedTag, this.restingTag]
            },
            10: {
                name: 'CORTÓ LLAMADA',
                groups: [this.casesReceivedTag, this.casesDiscardedTag, this.restingTag]
            },
            11: {
                name: 'DESCONOCE DEUDA',
                groups: [this.casesReceivedTag, this.casesDiscardedTag, this.restingTag]
            },
            12: {
                name: 'PROBLEMA DEUDA',
                groups: [this.casesReceivedTag, this.casesDiscardedTag, this.restingTag]
            },
            13: {
                name: 'LLAMADA RECURRENTE',
                groups: [this.casesReceivedTag, this.casesDiscardedTag, this.restingTag]
            },
            14: {
                name: 'PROD SIN USO',
                groups: [this.casesReceivedTag, this.casesDiscardedTag, this.restingTag]
            },
            16: {
                name: 'NO ES TITULAR',
                groups: [this.casesReceivedTag, this.casesDiscardedTag, this.restingTag]
            },
            17: {
                name: 'CORREO DE VOZ',
                groups: [this.casesReceivedTag, this.casesDiscardedTag, this.restingTag]
            },
            18: {
                name: 'FALLECIÓ',
                groups: [this.casesReceivedTag, this.casesDiscardedTag, this.restingTag]
            },
            19: {
                name: 'TITULAR CORTÓ',
                groups: [this.casesReceivedTag, this.casesDiscardedTag, this.restingTag]
            },
            20: {
                name: 'PAGO AUTOMÁTICO',
                groups: [this.casesReceivedTag, this.casesDiscardedTag, this.restingTag]
            },
            21: {
                name: 'CAMBIO FECHA',
                groups: [this.casesReceivedTag, this.casesDiscardedTag, this.restingTag]
            },
            22: {
                name: 'ENFERMEDAD',
                groups: [this.casesReceivedTag, this.casesDiscardedTag, this.restingTag]
            }
        }

        this.resultMap = {
            23: {
                name: 'COMPROMISO',
                groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.noCommitmentsTag],
                groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag],
            },
            3: {
                name: 'YA PAGÓ',
                groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.noCommitmentsTag],
                groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag],
            },
            4: {
                name: 'NO COBRÓ SUELDO',
                groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.noCommitmentsTag],
                groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag],
            },
            5: {
                name: 'CESANTE',
                groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.noCommitmentsTag],
                groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag],
            },
            6: {
                name: 'SE OLVIDÓ',
                groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.noCommitmentsTag],
                groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag],
            },
            7: {
                name: 'NO PUEDE PAGAR',
                groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.noCommitmentsTag],
                groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag],
            },
            8: {
                name: 'VACACIONES',
                groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.noCommitmentsTag],
                groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag],
            },
            9: {
                name: 'NÚMERO EQUIVOCADO',
                groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag],
                groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag],
            },
            0: {
                name: 'OCUPADO',
                groups: [this.casesReceivedTag, this.casesProcessedTag, this.unsuccessfulCallTag],
                groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag],
            },
            10: {
                name: 'CORTÓ LLAMADO',
                groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag],
                groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag],
            },
            11: {
                name: 'DEUDA DESCONOCIDA',
                groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.noCommitmentsTag],
                groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag],
            },
            12: {
                name: 'PROBLEMA CON DEUDA',
                groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.noCommitmentsTag],
                groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag],
            },
            13: {
                name: 'LLAMADOS RECURRENTES',
                groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.noCommitmentsTag],
                groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag]
            },
            14: {
                name: 'PRODUCTO SIN USO',
                groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.noCommitmentsTag],
                groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag]
            },
            15: {
                name: 'TRANSFER',
                groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag],
                groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag]
            },
            16: {
                name: 'NO ES TITULAR',
                groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag],
                groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag]
            },
            17: {
                name: 'CORREO DE VOZ',
                groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag],
                groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag]
            },
            18: {
                name: 'FALLECIDO',
                groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.noCommitmentsTag],
                groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag]
            },
            19: {
                name: 'CORTÓ LLAMADO',
                groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.noCommitmentsTag],
                groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag]
            },
            1: {
                name: 'INDEFINIDO',
                groups: [this.casesReceivedTag, this.casesProcessedTag, this.unsuccessfulCallTag],
                groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag]
            },
            20: {
                name: 'PAGO AUTOMÁTICO',
                groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.noCommitmentsTag],
                groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag]
            },
            21: {
                name: 'CAMBIO DE FECHA',
                groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.noCommitmentsTag],
                groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag]
            },
            22: {
                name: 'ENFERMO',
                groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.noCommitmentsTag],
                groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag]
            },
            2: {
                name: 'INDEFINIDO',
                groups: [this.casesReceivedTag, this.casesProcessedTag, this.unsuccessfulCallTag],
                groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag]
            },
            201: {
                name: 'REFINANCIAR',
                groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.noCommitmentsTag],
                groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag]
            },
            26: {
                groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.noCommitmentsTag],
                groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag],
            }
        }
    }

    updateNodesAndLinks = (nodes, links, resultGroups, tag, resultName, resultValue, accumulatedValue) => {
        let lastNodeIndex = -1;
        let groupsLen = resultGroups.length;

        if (resultName === '') {
            return
        }

        _.forEach(resultGroups, (group, index) => {
            let groupNodeIndex = _.findIndex(nodes, (value) => {
                return value.name.includes(group);
            })

            if (groupNodeIndex === -1) {
                // group node does not exist, create it and link it with any previous node updating flow value
                nodes.push({ name: group, value: resultValue, last: false });
                groupNodeIndex = nodes.length - 1;

                if (lastNodeIndex !== -1) {
                    links.push({ source: lastNodeIndex, target: groupNodeIndex, value: resultValue });
                }
            } else if (lastNodeIndex !== -1) {
                // group node already exists, find link with previous node and update flow value
                let link = _.find(links, (link) => {
                    return link.source === lastNodeIndex && link.target === groupNodeIndex;
                });
                if (link !== undefined) {
                    link.value += resultValue;
                    let node = nodes[groupNodeIndex];
                    node.value += resultValue;
                }
            }

            if (index === (groupsLen - 1) && group !== this.commitmentsTag && group !== this.noCommitmentsTag) {
                // last group before final result, create link and set flow value

                nodes.push({ name: tag, value: resultValue, last: true, result: resultName });
                let resultNodeIndex = nodes.length - 1;
                links.push({ source: groupNodeIndex, target: resultNodeIndex, value: resultValue });
            }
            else if (index === (groupsLen - 1)) {
                let node = nodes[groupNodeIndex];

                if (!node.last) {
                    node.last = true;
                }
                if (!node.results) {
                    node.results = []
                }

                node.results.push(resultName);
                if (!node.got_promise && group === this.commitmentsTag) {
                    node.got_promise = true;
                }
            }
            lastNodeIndex = groupNodeIndex;
        })
    }

    reportingDataToNodes = (reportingData) => {

        let nodes = [
            /*{name: this.casesReceivedTag},
            {name: this.casesProcessedTag},
            {name: this.casesDiscardedTag}*/
        ];

        let links = [
            /*{source: 0, target: this.casesProcessedTarget, value: 0},
            {source: 0, target: this.casesDiscardedTarget, value: 0}*/
        ]

        let accumulatedValue = 0;

        _.forEach(reportingData, (value) => {
            let total_results = value.total_results;
            let total_rest_results = value.total_rest_results;

            if (total_rest_results > 0) {
                let { groups: resultGroups } = this.restResultMap[(value.rest_result_id)]
                let name = value.rest_result.toUpperCase();
                let resultName = value.rest_result;
                if (resultName !== '') {
                    accumulatedValue += total_rest_results;
                    this.updateNodesAndLinks(nodes, links, resultGroups, name, resultName, total_rest_results, accumulatedValue);
                }
            } else if (total_results > 0) {
                if (value.got_promise) {
                    var { groupsWithCommitment: resultGroups } = this.resultMap[value.result_id];
                } else {
                    var { groups: resultGroups } = this.resultMap[value.result_id];
                }
                let name = value.result.toUpperCase();
                let resultName = value.result;
                if (resultName !== '') {
                    accumulatedValue += total_results;
                    this.updateNodesAndLinks(nodes, links, resultGroups, name, resultName, total_results, accumulatedValue);

                }
            }
        })

        // we want first node to have total cases in absolute value not percentage
        this.calculateFlowPercentageValues(nodes, accumulatedValue);

        return { nodes: nodes, links: links }
    }

    onLinkClick = (linkData, event) => {
        let results = this.findResultsByLinkGroup(linkData.target);

        let gotPromise = null;

        if (linkData.target.name.includes(this.commitmentsTag)) {
            gotPromise = true;
        } else if (linkData.target.name.includes(this.noCommitmentsTag)) {
            gotPromise = false;
        }

        this.props.onReportingCasesResultFilter(results, gotPromise)
    }

    calculateFlowPercentageValues = (nodes, accumulatedValue) => {
        _.forEach(nodes, (node, index) => {
            let value = (index === 0) ? accumulatedValue : _.round(node.value / accumulatedValue * 100, 2);

            node.name = node.name + " " + (index === 0 ? `(${accumulatedValue})` : `(${node.value})`) + (index === 0 ? "" : `[${value}%]`);
        })
    }

    findResultsByLinkGroup(node) {
        if (node.last) {
            let results = []
            if (node.results) {
                results = node.results;
            } else {
                results = [node.result];
            }
            return results;
        }
        let results = [];
        _.forEach(node.sourceLinks, (source) => {
            results = results.concat(this.findResultsByLinkGroup(source.target));
        })
        return results;

    }

    onReportingCasesResultFilter = () => {
        this.props.onReportingCasesResultFilter();
    }

    render() {
        let {
            reportingDataByResult,
            cases,
            casesCount,
            filterDict,
            resultFilterList,
            resultFilter,
            activeFilter,
            fromDate,
            toDate,
            page,
            activeBot,
            search,
            xhr,
            columns_to_show,
            network_failed,
            isChattigo,
            isPelikanus,
            timezoneBot
        } = this.props;
        const { nodes, links } = this.reportingDataToNodes(reportingDataByResult);
        let emptyState = casesCount === 0;
        return (
            <div className={"bot-inbound-reporting-detail-body"}>
                {/*<div className={"bot-inbound-reporting-sankey-container"}>
                    <HintSankey
                        nodes={nodes}
                        links={links}
                        onLinkClick={this.onLinkClick}
                    />
                </div>*/}
                <div className={"bot-inbound-reporting-content-container"}>
                    <div className={"bot-inbound-reporting-content-filter"}>
                        <Filters cases={cases}
                            filter={resultFilter}
                            onClick={this.props.onReportingCasesResultFilter}
                            casesCount={casesCount}
                            statusList={resultFilterList}
                            xhr={xhr}
                            inbound
                        />
                    </div>
                    <div className={"bot-inbound-reporting-content"}>
                        <InboundTable cases={cases}
                            xhr={xhr}
                            casesCount={casesCount}
                            onTableChange={this.props.onTableChange}
                            page={page}
                            selectedCheckboxes={[]}
                            checkAll={false}
                            from={"home"}
                            reportingFromDate={fromDate}
                            reportingToDate={toDate}
                            activeReportingFilter={activeFilter}
                            activeSection={"inbound_reports"}
                            activeBot={activeBot}
                            resultFilter={resultFilter}
                            search={search}
                            onExport={this.props.onExport}
                            reporting={true}
                            columns_to_show={columns_to_show}
                            isChattigo={isChattigo}
                            isPelikanus={isPelikanus}
                            timezoneBot={timezoneBot}
                        />
                        {
                          (network_failed)
                          &&
                          <div style={{padding: ".5em 0"}}>
                            <Alert severity={"info"} onClose={this.props.handleCloseAlertNetworkError}>
                              <AlertTitle>Importante</AlertTitle>
                              La generación del reporte está demorando más de lo esperado. El mismo será enviado por mail una vez generado.
                            </Alert>
                          </div>
                        }

                    </div>
                </div>
                {/*<SimpleExpansionPanel
                          title={"Detalle de Campañas"}
                          component={
                            }
                        />*/}
            </div>
        );
    }
}

export default ReportingInbound_detail;
