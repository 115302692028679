import React, { Component, Fragment } from "react";
import { Sankey } from 'react-vis';
import _ from 'lodash';

import "./Reporting_detail.scss";

import Loader from "../Loader";
import EmptyState from "../EmptyState";
import Filters from "../Filters";
import Table from "../Table";
import CustomDatePicker from "../CustomDatePicker";
import SimpleExpansionPanel from "../SimpleExpansionPanel/SimpleExpansionPanel";
import HintSankey from "./HintSankey";
import { Alert, AlertTitle } from '@material-ui/lab';


class Reporting_detail extends Component {

    constructor(props) {
        super(props);

        this.state = {
            'gotPromise': null,
        }

        this.casesReceivedTag = 'RECIBIDOS'
        this.casesReceivedTarget = 0
        this.casesProcessedTag = 'GESTIONADOS'
        this.casesProcessedTarget = 1
        this.successfulCallTag = 'LLAMADA EXITOSA'
        this.ownerTag = 'TITULAR'
        this.casesDiscardedTag = 'DESCARTADOS'
        this.casesDiscardedTarget = 2
        this.unsuccessfulCallTag = 'LLAMADA FALLIDA'
        this.restingTag = 'DESCANSO'
        this.casesWithErrorsTag = 'DATOS ERRÓNEOS'
        this.commitmentsTag = 'COMPROMISOS'
        this.noCommitmentsTag = 'SIN COMPROMISO'
        this.excuseWithCommitmentsTag = 'CON EXCUSA'
        this.excuseTag = 'EXCUSAS'


        this.genericRestResultNode = {
          name:'',
          groups: [this.casesReceivedTag, this.casesDiscardedTag, this.restingTag]
        };

        this.genericNoContactedNode = {
          name:'',
          groups: [this.casesReceivedTag, this.casesProcessedTag, this.unsuccessfulCallTag],
          groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag],
        }

        this.genericContactedNode = {
          name:'',
          groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.noCommitmentsTag],
          groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag],
        }


        // this.resultMap = {
        //     "-202": {
        //         name: 'LINE ERROR6',
        //         groups: [this.casesReceivedTag, this.casesProcessedTag, this.unsuccessfulCallTag],
        //         groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag],
        //     },
        //     "-201": {
        //         name: 'LINE ERROR5',
        //         groups: [this.casesReceivedTag, this.casesProcessedTag, this.unsuccessfulCallTag],
        //         groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag],
        //     },
        //     "-106": {
        //         name: 'LINE ERROR4',
        //         groups: [this.casesReceivedTag, this.casesProcessedTag, this.unsuccessfulCallTag],
        //         groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag],
        //     },
        //     "-105": {
        //         name: 'LINE ERROR3',
        //         groups: [this.casesReceivedTag, this.casesProcessedTag, this.unsuccessfulCallTag],
        //         groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag],
        //     },
        //     "-104": {
        //         name: 'LINE ERROR2',
        //         groups: [this.casesReceivedTag, this.casesProcessedTag, this.unsuccessfulCallTag],
        //         groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag],
        //     },
        //     "-103": {
        //         name: 'LINE ERROR',
        //         groups: [this.casesReceivedTag, this.casesProcessedTag, this.unsuccessfulCallTag],
        //         groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag],
        //     },
        //     "-102": {
        //         name: 'PHONE UNAVAILABLE',
        //         groups: [this.casesReceivedTag, this.casesProcessedTag, this.unsuccessfulCallTag],
        //         groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag],
        //     },
        //     "-101": {
        //         name: 'LINE UNAVAILABLE',
        //         groups: [this.casesReceivedTag, this.casesProcessedTag, this.unsuccessfulCallTag],
        //         groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag],
        //     },
        //     "-100": {
        //         name: 'SERVICE UNAVAILABLE',
        //         groups: [this.casesReceivedTag, this.casesProcessedTag, this.unsuccessfulCallTag],
        //         groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag],
        //     },
        //     "-1": {
        //         name: 'ERROR',
        //         groups: [this.casesReceivedTag, this.casesProcessedTag, this.unsuccessfulCallTag],
        //         groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag],
        //     },
        //     0: {
        //         name: 'OCUPADO',
        //         groups: [this.casesReceivedTag, this.casesProcessedTag, this.unsuccessfulCallTag],
        //         groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag],
        //     },
        //     1: {
        //         name: 'INDEFINIDO',
        //         groups: [this.casesReceivedTag, this.casesProcessedTag, this.unsuccessfulCallTag],
        //         groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag]
        //     },
        //     2: {
        //         name: 'INDEFINIDO',
        //         groups: [this.casesReceivedTag, this.casesProcessedTag, this.unsuccessfulCallTag],
        //         groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag]
        //     },
        //     3: {
        //         name: 'YA PAGÓ',
        //         groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.noCommitmentsTag],
        //         groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag],
        //     },
        //     4: {
        //         name: 'NO COBRÓ SUELDO',
        //         groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.noCommitmentsTag],
        //         groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag],
        //     },
        //     5: {
        //         name: 'CESANTE',
        //         groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.noCommitmentsTag],
        //         groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag],
        //     },
        //     6: {
        //         name: 'SE OLVIDÓ',
        //         groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.noCommitmentsTag],
        //         groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag],
        //     },
        //     7: {
        //         name: 'NO PUEDE PAGAR',
        //         groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.noCommitmentsTag],
        //         groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag],
        //     },
        //     8: {
        //         name: 'VACACIONES',
        //         groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.noCommitmentsTag],
        //         groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag],
        //     },
        //     9: {
        //         name: 'NÚMERO EQUIVOCADO',
        //         groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag],
        //         groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag],
        //     },
        //     10: {
        //         name: 'CORTÓ LLAMADO',
        //         groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag],
        //         groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag],
        //     },
        //     11: {
        //         name: 'DEUDA DESCONOCIDA',
        //         groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.noCommitmentsTag],
        //         groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag],
        //     },
        //     12: {
        //         name: 'PROBLEMA CON DEUDA',
        //         groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.noCommitmentsTag],
        //         groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag],
        //     },
        //     13: {
        //         name: 'LLAMADOS RECURRENTES',
        //         groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.noCommitmentsTag],
        //         groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag]
        //     },
        //     14: {
        //         name: 'PRODUCTO SIN USO',
        //         groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.noCommitmentsTag],
        //         groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag]
        //     },
        //     15: {
        //         name: 'TRANSFER',
        //         groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag],
        //         groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag]
        //     },
        //     16: {
        //         name: 'NO ES TITULAR',
        //         groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag],
        //         groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag]
        //     },
        //     17: {
        //         name: 'CORREO DE VOZ',
        //         groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag],
        //         groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag]
        //     },
        //     18: {
        //         name: 'FALLECIDO',
        //         groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.noCommitmentsTag],
        //         groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag]
        //     },
        //     19: {
        //         name: 'CORTÓ LLAMADO',
        //         groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.noCommitmentsTag],
        //         groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag]
        //     },
        //     20: {
        //         name: 'PAGO AUTOMÁTICO',
        //         groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.noCommitmentsTag],
        //         groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag]
        //     },
        //     21: {
        //         name: 'CAMBIO DE FECHA',
        //         groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.noCommitmentsTag],
        //         groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag]
        //     },
        //     22: {
        //         name: 'ENFERMO',
        //         groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.noCommitmentsTag],
        //         groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag]
        //     },
        //     23: {
        //         name: 'COMPROMISO',
        //         groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.noCommitmentsTag],
        //         groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag],
        //     },
        //     24: {
        //         name: 'Robo_Telefono',
        //         groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.noCommitmentsTag],
        //         groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag],
        //     },
        //     25: {
        //         name: 'Falla_Telefono',
        //         groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.noCommitmentsTag],
        //         groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag],
        //     },
        //     26: {
        //         name: 'CESANTE',
        //         groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.noCommitmentsTag],
        //         groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag],
        //     },
        //     27: {
        //         name: 'SIN DIAGNOSTICO',
        //         groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.noCommitmentsTag],
        //         groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag]
        //     },
        //     28: {
        //         name: 'NO TITULAR - TEL',
        //         groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.noCommitmentsTag],
        //         groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag]
        //     },
        //     29: {
        //         name: 'COMPROMETE FECHA - SMS',
        //         groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.noCommitmentsTag],
        //         groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag]
        //     },
        //     30: {
        //         name: 'RESULT ID 30',
        //         groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.noCommitmentsTag],
        //         groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag]
        //     },
        //     31: {
        //         name: 'RESULT ID 31',
        //         groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.noCommitmentsTag],
        //         groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag]
        //     },
        //     32: {
        //         name: 'RESULT ID 32',
        //         groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.noCommitmentsTag],
        //         groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag]
        //     },
        //     33: {
        //         name: 'RESULT ID 33',
        //         groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.noCommitmentsTag],
        //         groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag]
        //     },
        //     40: {
        //         name: 'RESULT ID 40',
        //         groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.noCommitmentsTag],
        //         groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag]
        //     },
        //     41: {
        //         name: 'RESULT ID 41',
        //         groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.noCommitmentsTag],
        //         groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag]
        //     },
        //     42: {
        //         name: 'RESULT ID 42',
        //         groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.noCommitmentsTag],
        //         groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag]
        //     },
        //     43: {
        //         name: 'RESULT ID 43',
        //         groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.noCommitmentsTag],
        //         groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag]
        //     },
        //     50: {
        //         name: 'RESULT ID 50',
        //         groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.noCommitmentsTag],
        //         groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag]
        //     },
        //     100: {
        //         name: 'VOLVER A CONTACTAR',
        //         groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.noCommitmentsTag],
        //         groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag],
        //     },
        //     101: {
        //         name: 'RESULT ID 101',
        //         groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.noCommitmentsTag],
        //         groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag],
        //     },
        //     102: {
        //         name: 'RESULT ID 102',
        //         groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.noCommitmentsTag],
        //         groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag],
        //     },
        //     103: {
        //         name: 'RESULT ID 103',
        //         groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.noCommitmentsTag],
        //         groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag],
        //     },
        //     104: {
        //         name: 'RESULT ID 104',
        //         groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.noCommitmentsTag],
        //         groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag],
        //     },
        //     200: {
        //         name: 'CESANTE',
        //         groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.noCommitmentsTag],
        //         groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag],
        //     },
        //     201: {
        //         name: 'REFINANCIAR',
        //         groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.noCommitmentsTag],
        //         groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag]
        //     },
        //     202: {
        //         name: 'CODIGO PAGO',
        //         groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.noCommitmentsTag],
        //         groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag]
        //     },
        //     203: {
        //         name: 'RESUELVE TU DEUDA',
        //         groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.noCommitmentsTag],
        //         groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag]
        //     },
        //     204: {
        //         name: 'RESUELT ID 204',
        //         groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.noCommitmentsTag],
        //         groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag]
        //     },
        //     205: {
        //         name: 'RESUELT ID 205',
        //         groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.noCommitmentsTag],
        //         groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag]
        //     },
        //     206: {
        //         name: 'RESUELT ID 206',
        //         groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.noCommitmentsTag],
        //         groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag]
        //     },
        //     207: {
        //         name: 'RESUELT ID 207',
        //         groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.noCommitmentsTag],
        //         groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag]
        //     },
        //     208: {
        //         name: 'RESUELT ID 208',
        //         groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.noCommitmentsTag],
        //         groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag]
        //     },
        //     209: {
        //         name: 'RESUELT ID 209',
        //         groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.noCommitmentsTag],
        //         groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag]
        //     },
        //     210: {
        //         name: 'RESUELT ID 210',
        //         groups: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.noCommitmentsTag],
        //         groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag]
        //     },
        //     300: {
        //         name: 'MANUAL',
        //         groups: [this.casesReceivedTag, this.casesProcessedTag, this.unsuccessfulCallTag],
        //         groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag],
        //     },
        //     400: {
        //         name: 'MANUAL',
        //         groups: [this.casesReceivedTag, this.casesProcessedTag, this.unsuccessfulCallTag],
        //         groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag],
        //     },
        //     401: {
        //         name: 'PRODUCTO DIFERIDO 1',
        //         groups: [this.casesReceivedTag, this.casesProcessedTag, this.unsuccessfulCallTag],
        //         groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag],
        //     },
        //     402: {
        //         name: 'PRODUCTO DIFERIDO 2',
        //         groups: [this.casesReceivedTag, this.casesProcessedTag, this.unsuccessfulCallTag],
        //         groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag],
        //     },
        //     403: {
        //         name: 'PRODUCTO DIFERIDO 3',
        //         groups: [this.casesReceivedTag, this.casesProcessedTag, this.unsuccessfulCallTag],
        //         groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag],
        //     },
        //     404: {
        //         name: 'PRODUCTO DIFERIDO 4',
        //         groups: [this.casesReceivedTag, this.casesProcessedTag, this.unsuccessfulCallTag],
        //         groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag],
        //     },
        //     9999: {
        //         name: 'RESULT ID 9999',
        //         groups: [this.casesReceivedTag, this.casesProcessedTag, this.unsuccessfulCallTag],
        //         groupsWithCommitment: [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag, this.ownerTag, this.commitmentsTag],
        //     }
        // }
    }

    updateNodesAndLinks = (nodes, links, resultGroups, tag, resultName, resultValue, accumulatedValue) => {
        let lastNodeIndex = -1;
        let groupsLen = resultGroups.length;

        if (resultName === '') {
            return
        }

        _.forEach(resultGroups, (group, index) => {
            let groupNodeIndex = _.findIndex(nodes, (value) => {
                return value.name.includes(group);
            })

            if (groupNodeIndex === -1) {
                // group node does not exist, create it and link it with any previous node updating flow value
                nodes.push({ name: group, value: resultValue, last: false });
                groupNodeIndex = nodes.length - 1;

                if (lastNodeIndex !== -1) {
                    links.push({ source: lastNodeIndex, target: groupNodeIndex, value: resultValue });
                }
            } else if (lastNodeIndex !== -1) {
                // group node already exists, find link with previous node and update flow value
                let link = _.find(links, (link) => {
                    return link.source === lastNodeIndex && link.target === groupNodeIndex;
                });
                if (link !== undefined) {
                    link.value += resultValue;
                    let node = nodes[groupNodeIndex];
                    node.value += resultValue;
                }
            }

            if (index === (groupsLen - 1) && group !== this.commitmentsTag && group !== this.noCommitmentsTag) {
                // last group before final result, create link and set flow value

                nodes.push({ name: tag, value: resultValue, last: true, result: resultName });
                let resultNodeIndex = nodes.length - 1;
                links.push({ source: groupNodeIndex, target: resultNodeIndex, value: resultValue });
            }
            else if (index === (groupsLen - 1)) {
                let node = nodes[groupNodeIndex];

                if (!node.last) {
                    node.last = true;
                }
                if (!node.results) {
                    node.results = []
                }

                node.results.push(resultName);
                if (!node.got_promise && group === this.commitmentsTag) {
                    node.got_promise = true;
                }
            }
            lastNodeIndex = groupNodeIndex;
        })
    }

    checkResultIdIsNotOwner = (value, numeros) => {
      return numeros.includes(value);
    } 

    reportingDataToNodes = (reportingData) => {

        let nodes = [
            /*{name: this.casesReceivedTag},
            {name: this.casesProcessedTag},
            {name: this.casesDiscardedTag}*/
        ];

        let links = [
            /*{source: 0, target: this.casesProcessedTarget, value: 0},
            {source: 0, target: this.casesDiscardedTarget, value: 0}*/
        ]

        let accumulatedValue = 0;

        _.forEach(reportingData, (value) => {
            let total_results = value.total_results;
            let total_rest_results = value.total_rest_results;

            if (total_rest_results > 0) {
                //New generic Node
                let { groups: resultGroups } = this.genericRestResultNode;
                let name = value.rest_result.toUpperCase();
                let resultName = value.rest_result;
                if (resultName !== '') {
                    accumulatedValue += total_rest_results;
                    this.updateNodesAndLinks(nodes, links, resultGroups, name, resultName, total_rest_results, accumulatedValue);
                }
            } else if (total_results > 0) {
              
                let resultMap
                let resultGroups
                if (this.checkResultIdIsNotOwner(value.result_id, [9, 10, 15, 16, 17])) {
                  resultGroups = [this.casesReceivedTag, this.casesProcessedTag, this.successfulCallTag];
                }else{
                  if (value.contacted) {
                    resultMap = this.genericContactedNode;
                  } else {
                    resultMap = this.genericNoContactedNode;
                  }
                  if (value.got_promise) {
                    ({ groupsWithCommitment: resultGroups } = resultMap);
                  } else {
                    ({ groups: resultGroups } = resultMap);
                  }
                }

                let name = value.result.toUpperCase();
                let resultName = value.result;
                if (resultName !== '') {
                    accumulatedValue += total_results;
                    this.updateNodesAndLinks(nodes, links, resultGroups, name, resultName, total_results, accumulatedValue);

                }
            }
        })

        // we want first node to have total cases in absolute value not percentage
        this.calculateFlowPercentageValues(nodes, accumulatedValue);

        return { nodes: nodes, links: links }
    }

    onLinkClick = (linkData, event) => {
        let results = this.findResultsByLinkGroup(linkData.target);

        let gotPromise = null;
        if (linkData.target.name.includes(this.commitmentsTag)) {
            gotPromise = true;
        } else if (linkData.target.name.includes(this.noCommitmentsTag)) {
            gotPromise = false;
        }

        this.props.onReportingCasesResultFilter(results, gotPromise)
    }

    calculateFlowPercentageValues = (nodes, accumulatedValue) => {
        _.forEach(nodes, (node, index) => {
            let value = (index === 0) ? accumulatedValue : _.round(node.value / accumulatedValue * 100, 2);

            node.name = node.name + " " + (index === 0 ? `(${accumulatedValue})` : `(${node.value})`) + (index === 0 ? "" : `[${value}%]`);
        })
    }

    findResultsByLinkGroup(node) {
        if (node.last) {
            let results = []
            if (node.results) {
                results = node.results;
            } else {
                results = [node.result];
            }
            return results;
        }
        let results = [];
        _.forEach(node.sourceLinks, (source) => {
            results = results.concat(this.findResultsByLinkGroup(source.target));
        })
        return results;

    }

    onReportingCasesResultFilter = () => {
        this.props.onReportingCasesResultFilter();
    }

    render() {
        let { reportingDataByResult, cases, casesCount, filterDict, resultFilterList, resultFilter, activeFilter, fromDate, toDate, page, activeBot, search, xhr, network_failed, isChattigo,isPelikanus } = this.props;
        const { nodes, links } = this.reportingDataToNodes(reportingDataByResult);
        let emptyState = casesCount === 0;
        // console.log(cases)
        return (
            <div className={"bot-campaign-reporting-detail-body"}>
                {/*<SimpleExpansionPanel
                          title={"Flujo de gestión"}
                          component={
                              <HintSankey
                                  nodes={nodes}
                                  links={links}
                                  onLinkClick={this.onLinkClick}
                              />
                          }
                      />*/}
                {
                    nodes.length > 0
                    &&
                    <div className={"bot-campaign-reporting-sankey-container"}>
                        <HintSankey
                            nodes={nodes}
                            links={links}
                            onLinkClick={this.onLinkClick}
                        />
                    </div>
                }
                <div className={"bot-campaign-reporting-content-container"}>
                    <div className={"bot-campaign-reporting-content-filter"}>
                        <Filters cases={cases}
                            filter={resultFilter}
                            onClick={this.props.onReportingCasesResultFilter}
                            casesCount={casesCount}
                            statusList={resultFilterList}
                            xhr={xhr}
                        />
                    </div>
                    <div className={"bot-campaign-reporting-content"}>
                        <Table cases={cases}
                            xhr={xhr}
                            casesCount={casesCount}
                            onTableChange={this.props.onTableChange}
                            page={page}
                            selectedCheckboxes={[]}
                            checkAll={false}
                            from={"home"}
                            reportingFromDate={fromDate}
                            reportingToDate={toDate}
                            activeReportingFilter={activeFilter}
                            activeSection={"reports"}
                            activeBot={activeBot}
                            resultFilter={resultFilter}
                            search={search}
                            onExport={this.props.onExport}
                            reporting={true}
                            isChattigo={isChattigo}
                            isPelikanus={isPelikanus}
                        />
                        {
                            (network_failed)
                            &&
                            <div style={{ padding: ".5em 0" }}>
                                <Alert severity={"info"} onClose={this.props.handleCloseAlertNetworkError}>
                                    <AlertTitle>Importante</AlertTitle>
                                    La generación del reporte está demorando más de lo esperado. El mismo será enviado por mail una vez generado.
                                </Alert>
                            </div>
                        }
                    </div>
                </div>
                {/*<SimpleExpansionPanel
                          title={"Detalle de Campañas"}
                          component={
                            }
                        />*/}
            </div>

        );
    }
}

export default Reporting_detail;
