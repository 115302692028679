import React, { Component } from "react";
import Modal from "@material-ui/core/Modal";
import { withStyles } from "@material-ui/core/styles";
import NewCampaignForm from "../NewCampaignForm";
import EditForm from "../EditForm";
import Cross from "../../assets/red.png";
import Tic from "../../assets/tic.png";
import "./CustomModal.scss";
import { Link } from "react-router-dom";
import ImportCasesForm from "../ImportCasesForm";
import NewUserForm from "../../components/NewUserForm";
import NewBotForm from "../../components/NewBotForm";
import NewCaseForm from "../../components/NewCaseForm";
import { FormattedMessage } from "react-intl";

const styles = {
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  modal: {
    width: "40%",
    // height: "70%",
    borderRadius: "3px",
    border: "1px solid #CFCFCF",
    backgroundColor: "white",
    outline: "none"
  },
  processedWithErrorsModal: {
    width: "40%",
    height: "40%",
    borderRadius: "3px",
    border: "1px solid #CFCFCF",
    backgroundColor: "white",
    outline: "none"
  }
};

class CustomModal extends Component {
  render() {


    let {
      classes,
      modal,
      type,
      title,
      description,
      confirmed,
      sent,
      backdropDisabled,
      titleIsValid,
      descriptionIsValid,
      fileIsValid,
      file,
      exclusionsFile,
      exclusionsFileIsValid,
      cases,
      maxCalls,
      from,
      to,
      selected,
      showDatePicker,
      date,
      imported,
      casesFound,
      email,
      surname,
      userType,
      bots,
      name,
      nameIsValid,
      surnameIsValid,
      emailIsValid,
      input,
      created,
      edit,
      company,
      companyIsValid,
      timeInvalid,
      campaignId,
      campaignName,
      receivedWithErrors,
      processedWithErrors,
      network_failed,
      options,
      id,
      idIsValid,
      product,
      productIsValid,
      debt,
      debtIsValid,
      phone,
      phoneIsValid,
      caseAdded,
      status,
      resultOptions,
      filteredResults,
      resultInput,
      phonePriority,
      maxPhonePriority,
      dialmode,
      xhr,
      selectedCycles,
      availableCycles,
      contactability,
      contactabilityIsValid,
      closes_eod,
    } = this.props;
    return (
      <Modal
        open={modal}
        BackdropProps={{
          style: {
            backgroundColor: "rgba(255, 255, 255, 0.6)"
          }
        }}
        className={classes.root}
        onBackdropClick={
          backdropDisabled ? undefined : this.props.onBackdropClick
        }
      >
        <div className={processedWithErrors ? classes.processedWithErrorsModal : classes.modal}>
          {type === "add-new-case" && !caseAdded &&
            <NewCaseForm
              id={id}
              idInvalid={!idIsValid}
              name={name}
              nameInvalid={!nameIsValid}
              surname={surname}
              surnameInvalid={!surnameIsValid}
              product={product}
              productInvalid={!productIsValid}
              debt={debt}
              debtInvalid={!debtIsValid}
              phone={phone}
              phoneInvalid={!phoneIsValid}
              onDateChange={this.props.onDateChange}
              date={date}
              onChange={this.props.onChange}
              onClick={this.props.onClick}
              xhr={xhr}
              onClose={this.props.onClose}
            />
          }
          {type === "campaign" && !sent && !confirmed && (
            <NewCampaignForm
              imported={imported}
              title={title}
              description={description}
              onChange={this.props.onChange}
              onDrop={this.props.onDrop}
              onClick={this.props.onClick}
              titleInvalid={!titleIsValid}
              descriptionInvalid={!descriptionIsValid}
              fileInvalid={!fileIsValid}
              file={file}
              onDelete={this.props.onDeleteFile}
              receivedWithErrors={receivedWithErrors}
              processedWithErrors={processedWithErrors}
              network_failed={network_failed}
              onBack={this.props.onBack}
              xhr={xhr}
              onClose={this.props.onClose}
              options={resultOptions}
              filteredResults={filteredResults}
              input={resultInput}
              onAdd={this.props.onAddResultFilter}
              onDeleteFilterTag={this.props.onDeleteFilterTag}
              onSelectChange={this.props.onSelectChange}
              phonePriority={phonePriority}
              maxPhonePriority={maxPhonePriority}
              onIncrementPhonePriority={this.props.onIncrementPhonePriority}
              onDecrementPhonePriority={this.props.onDecrementPhonePriority}
              dialmode={dialmode}
              onDialModeChange={this.props.onDialModeChange}
              resultFilterTag
              clearInput={this.props.clearInput}
              contactability={contactability}
              contactabilityInvalid={!contactabilityIsValid}
            />
          )}
          {((type === "campaign" && sent && !confirmed) ||
            (type === "edit-campaign" && !confirmed)) && (
              <EditForm
                canSelectCycles={this.props.canSelectCycles}
                clearInput={this.props.clearInput}
                onCycleAdd={this.props.onCycleAdd}
                selectedCycles={selectedCycles}
                availableCycles={availableCycles}
                onSelectChange={this.props.onCycleSelectChange}
                onDeleteFilterTag={this.props.onDeleteCycle}
                input={resultInput}
                status={status}
                title={title}
                cases={cases}
                maxCalls={maxCalls}
                onAdd={this.props.onAdd}
                onRest={this.props.onRest}
                from={from}
                to={to}
                onError={this.props.onError}
                onTimeChange={this.props.onTimeChange}
                onConfirm={this.props.onConfirm}
                onSelect={this.props.onSelect}
                selected={selected}
                showDatePicker={showDatePicker}
                onDateChange={this.props.onDateChange}
                date={date}
                type={type}
                onCancel={this.props.onCancel}
                onDelete={this.props.onDelete}
                onClick={this.props.onClick}
                timeInvalid={timeInvalid}
                xhr={xhr}
                onClose={this.props.onClose}
                onDropExclusions={this.props.onDropExclusions}
                onDeleteExclusions={this.props.onDeleteExclusions}
                exclusionsFile={exclusionsFile}
                exclusionsFileInvalid={!exclusionsFileIsValid}
                shouldDownloadFile={this.props.shouldDownloadFile}
                downloadFileControl={this.props.downloadFileControl}
                closes_eod={closes_eod}
                onClosesEODChange={this.props.handleClosesEODChange}
              />
            )}
          {((type === "campaign" && sent && confirmed) ||
            (type === "import-cases" && imported && confirmed) ||
            (type === "edit-campaign" && confirmed) ||
            (type === "add-new-case" && caseAdded)) && (
              <div className={"campaign-success"}>
                
                <img
                  src={Cross}
                  alt={"Cerrar"}
                  className={"cross"}
                  onClick={this.props.onClose}
                />
                <div className={"campaign-success-content"}>
                  <div className={"success"}>
                    <img src={Tic} alt={"Tic"} />
                    <span>
                      {type === "campaign"
                        ? <FormattedMessage id="newcamp.created" />// ? "¡CAMPAÑA CREADA!"
                        : type === "import-cases"
                          ? <FormattedMessage id="newcamp.list.cases" />// ? "LISTADO DE CASOS ACTUALIZADO" 
                          : type === "add-new-case"
                            ? <FormattedMessage id="newcamp.case.added" />// ?"¡CASO AGREGADO!"
                            : <FormattedMessage id="config.modify" />// : "¡CAMPAÑA MODIFICADA!"
                      }
                    </span>
                  </div>
                  {type === "campaign" && (
                    <div className={"campaign-success-btns"}>
                      <Link to={{
                        pathname:`/campaigns/${campaignId}/${campaignName}`,
                        state:{timezone:this.props.timezoneBot}
                      }} className={"ok"}>
                        <FormattedMessage id="newcamp.watch" />{/* VER CAMPAÑA */}
                      </Link>
                      <button className={"cancel"} onClick={this.props.onCancel}>
                        <FormattedMessage id="newcamp.leave" />{/* Salir */}
                      </button>
                    </div>
                  )}
                  {(type === "import-cases" || type === "add-new-case" || type === "edit-campaign") && (
                    <div className={"campaign-success-btns"}>
                      <button className={"ok"} onClick={this.props.onCancel}>
                        <FormattedMessage id="config.ok" />{/* ACEPTAR */}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            )}
          {type === "import-cases" && !confirmed && (
            <ImportCasesForm
              processdWithErrors={processedWithErrors}
              receivedWithErrors={receivedWithErrors}
              onClick={this.props.onClick}
              file={file}
              onDrop={this.props.onDrop}
              onDelete={this.props.onDeleteFile}
              fileInvalid={!fileIsValid}
              onCancel={this.props.onCancel}
              imported={imported}
              casesFound={casesFound}
              onConfirm={this.props.onConfirm}
              onBack={this.props.onBack}
              xhr={xhr}
              onClose={this.props.onClose}
            />
          )}
          {type === "users" && (
            <NewUserForm
              name={name}
              surname={surname}
              email={email}
              userType={userType}
              bots={bots}
              input={input}
              nameInvalid={!nameIsValid}
              surnameInvalid={!surnameIsValid}
              emailInvalid={!emailIsValid}
              onSelect={this.props.onSelect}
              onChange={this.props.onChange}
              onCancel={this.props.onCancel}
              onAdd={this.props.onAdd}
              onDelete={this.props.onDelete}
              onClick={this.props.onCreate}
              created={created}
              onSave={this.props.onSave}
              edit={edit}
              options={options}
              onSelectChange={this.props.onSelectChange}
              xhr={xhr}
              onClose={this.props.onClose}
            />
          )}
          {type === "bots" && (
            <NewBotForm
              edit={edit}
              created={created}
              onSave={this.props.onSave}
              company={company}
              companyInvalid={!companyIsValid}
              onChange={this.props.onChange}
              onCancel={this.props.onCancel}
              onClick={this.props.onCreate}
              xhr={xhr}
              onClose={this.props.onClose}
            />
          )}
        </div>
      </Modal>
    );
  }
}

export default withStyles(styles)(CustomModal);
