import React, { Component } from 'react'
import './SettingsTag.scss'

class SettingsTag extends Component {
  render() {
    let { data, hidden, text} = this.props

    return (
      <div className={'tag-container'} title={hidden && text}>
        <span>{data}</span>
      </div>
    )
  }
}

export default SettingsTag
