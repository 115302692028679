import React, { Component } from "react";
import "./Breadcrumbs.scss";
import Arrow from "../../assets/anchor.svg";
import { Link, withRouter } from "react-router-dom";

class Breadcrumbs extends Component {
  render() {
    let {
      text,
      from,
      prevPath,
      botId,
      page,
      activeReportingFilter,
      resultFilter,
      activeSection,
      activeBot,
      activeFilter,
      reportingFromDate,
      reportingToDate,
      inbound,
      search,
    } = this.props;

    // console.log('breadcrumbs', text, from, prevPath, botId, page, activeReportingFilter, resultFilter, activeSection, activeBot, activeFilter, reportingFromDate, reportingToDate, inbound, search)
    if (from === "settings") {
      return (
        <div className={"breadcrumbs"}>
          <button onClick={() => this.props.history.goBack()}>
            <img src={Arrow} alt={"Atrás"} /><span>{text && text.toUpperCase()}</span>
          </button>
        </div>
      );
    }

    return (
      <div className={"breadcrumbs"}>
        <Link to={{
          pathname: `${prevPath ? prevPath : "/"}`,
          state: {
            prevPath: this.props.location.pathname,
            botId: botId ? botId : null,
            from: from,
            page: page ? page : 1,
            activeReportingFilter: !inbound && activeReportingFilter ? activeReportingFilter : "day",
            activeInboundReportingFilter: inbound && activeReportingFilter ? activeReportingFilter : "day",
            resultFilter: resultFilter ? resultFilter : (inbound ? "all" : "Compromete Fecha"),
            reportingFromDate: !inbound && reportingFromDate ? reportingFromDate : null,
            inboundReportingFromDate: inbound && reportingFromDate ? reportingFromDate : null,
            reportingToDate: !inbound && reportingToDate ? reportingToDate : null,
            inboundReportingToDate: inbound && reportingToDate ? reportingToDate : null,
            activeSection: activeSection ? activeSection : (inbound ? "inbounds" : "campaigns"),
            activeBot: activeBot ? activeBot : null,
            activeFilter: activeFilter ? activeFilter : "all",
            search: search ? search : "",
            timezone: this.props.location.state? this.props.location.state.timezone: null
          }
        }}
        >
          <img src={Arrow} alt={"Atrás"} /><span>{text && text}</span>
        </Link>
      </div>
    );
  }
}

export default withRouter(Breadcrumbs);