/* eslint-disable react/prop-types */
// eslint-disable-next-line no-unused-vars
import React, { Component } from 'react';
import TableExports from '../TableExports/TableExports';
import './Reporting_custom.scss';
import urls from "../../lib/urls";
import axios from 'axios';
import { tokenHeader } from '../../lib/headers';

class Reporting_custom extends Component {

  constructor(props) {
    super(props);
    this.state = {
          tasks: [],
          tasksCount:0,
          page:1,
          xhr:false,
          bot:"",
      } 
  }
  

  // getTaskStatus = async (desiredStatus,fileToExport,task_id) => {
  //   const taskUrl = `${urls.API_ROOT}/api/v1/tasks/${task_id}/`;
  //   try {
  //     const res = await axios.get(taskUrl, tokenHeader());
  //     const { data } = res;
  //     const { status } = data;
  //     let timer;
  //     if (status === "RECEIVED_WITH_ERRORS") {
  //       return this.handleTaskStatus(status,fileToExport,task_id);
  //     }
  //     if (status !== desiredStatus) {
  //       timer = setTimeout(() => this.getTaskStatus(desiredStatus,fileToExport,task_id), 3000);
  //       return timer;
  //     }
  //     clearTimeout(timer);
  //     return this.handleTaskStatus(status,fileToExport,task_id);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  handleTaskStatus = async (status,fileToExport,task_id) => {
    switch (status) {
      case "RECEIVED": {
        // await this.getTaskStatus("IN_PROCESS",fileToExport,task_id);
        break;
      }
      case "IN_PROCESS": {
        break;
      }
      case "RECEIVED_WITH_ERRORS": {
        break;
      }
      case "DONE": {        
        window.location = `${urls.API_ROOT}${fileToExport}`;
        break;
      }
    }
  };

  handleTaskExport = async (task_id) => {  
    const { tasks } = this.state;
    const task = tasks.find((_task)=> _task.id===task_id)
    const {file:fileToExport,status} = task;
    this.handleTaskStatus(status,fileToExport,task_id)    
  };

   handleTableChange = ( type, { page, sizePerPage }) => {
    this.getTasksForExportTable(page)
    this.setState({page:page})    
  };

   getTasksForExportTable = async (page) => {
    let { activeBot } = this.props;
    if (activeBot === null) return;
    this.setState({
         xhr: true,
       })

    let Url = `${urls.API_ROOT}/api/v1/tasks/exports/${activeBot}/`;
    if (page && page > 1) Url += `?page=${page}`;

    try {
      const res = await axios.get(Url, {
        ...tokenHeader(),
      });
      const { results, count } = res.data;
      let  tasksToExports = results
      this.setState({
        tasks: tasksToExports,
        tasksCount:count,

    });
    return results;
    } catch (error) {
      console.log(error);
    }finally{
       this.setState({
         xhr: false,
       })

    }
  };
  componentDidUpdate(){
    //if it is inbound, need to refresh the content when changing bot and keep the view
    if (this.state.bot !==this.props.activeBot) {
      this.setState({ bot: this.props.activeBot,page:1 });
      this.getTasksForExportTable(1) 
    }
    
  }

  async componentDidMount() {
    this.getTasksForExportTable(1)
    this.setState({bot:this.props.activeBot})
  }


  render() {
    let { isChattigo,isPelikanus } = this.props;
    let {tasks,tasksCount,page,xhr} = this.state;
    return (
      <div id="bi-dashboard-container">
        <TableExports  
          tasks={tasks}
          xhr={xhr}
          tasksCount={tasksCount}
          page={page}
          onExport={this.handleTaskExport}
          onTableChange={this.handleTableChange}
          isChattigo={isChattigo}
          isPelikanus={isPelikanus}
        />

      </div>
    );
  }
}
export default Reporting_custom;