import React from 'react';
import './EmptyState.scss';

const EmptyState = ({text}) => {
  return (
    <div className={"empty-state-container"}>
      <p>{text}</p>
    </div>
  );
};

export default EmptyState