import React, { Component } from "react";
import moment from "moment";
/* eslint-disable */
/*import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";*/
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { createMuiTheme } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider, DatePicker } from "material-ui-pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import "./CustomDatePicker.scss";
import Calendar from '../../assets/calendar.png';

class CustomDatePicker extends Component {
  render() {
    let { date, views, label, width, disablePast } = this.props;

    const materialTheme = createMuiTheme({
      palette: {
        primary: {
          main: "#0066CA"
        }
      },
      typography: {
        useNextVariants: true,
        suppressDeprecationWarnings: true
      }
    });

    let newDate = `${date}T00:00:00`;

    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
        <MuiThemeProvider theme={materialTheme}>
          <div className={`date-picker-container ${width}`}>
            <DatePicker
              clearable
              value={!views ? newDate : date}
              views={views}
              label={label}
              disablePast={disablePast}
              format={!views || views.length===3 ? "dd/MM/yyyy" : ""}
              placeholder={!label ? "dd/mm/aaaa": ""}
              onChange={this.props.onDateChange}
              InputProps={{
                disableUnderline: true,
                style: {
                  fontSize: 15
                }
              }}
            />
            <img src={Calendar} alt={'Fecha'}/>
          </div>
        </MuiThemeProvider>
      </MuiPickersUtilsProvider>
    );
  }
}

CustomDatePicker.defaultProps = {
  disablePast: false
}

export default CustomDatePicker;