import urls from "../../lib/urls";
import axios from "axios";

export const MS_AUTH_URL_SUCCESS = "MS_AUTH_URL_SUCCESS";
export const MS_AUTH_URL_FAILED = "MS_AUTH_URL_FAILED";

export const MS_LOGIN_SUCCESS = "MS_LOGIN_SUCCESS";
export const MS_LOGIN_FAILED = "MS_LOGIN_FAILED";
export const MS_LOGOUT = "MS_LOGOUT";


export function getMSAuthUrl() {
    return (dispatch) => {
        const ms_auth_url = `${urls.API_ROOT}/msmarket/oauth`;
        axios.get(ms_auth_url, { withCredentials: true })
            .then((response) => {
                dispatch(msAuthUrl(response.data));
            })
            .catch((error) => {
                if (error) {
                    console.log(error);
                    dispatch(msAuthUrlFailed());
                }
            })
    }
}

export function loginMsUser() {
    return (dispatch) => {
        const login_url = `${urls.API_ROOT}/msmarket/auth`;
        const data = localStorage.getItem("msAuth");
        let _token;
        if (data) {
            const { token } = JSON.parse(data);
            _token = token;
        }
        axios.post(login_url, {
            token: _token,
        }, { withCredentials: true })
            .then((response) => {
                dispatch(loginMsUserSuccess(response.data));
            })
            .catch((error) => {
                if (error) {
                    console.log(error);
                    dispatch(loginMsUserFailed());
                }
            })
    }
}

export function msAuthUrl(payload) {
    return {
        type: MS_AUTH_URL_SUCCESS,
        payload: payload
    }
}

export function msAuthUrlFailed() {
    return {
        type: MS_AUTH_URL_FAILED,
    }
}

export function loginMsUserSuccess(payload) {
    return {
        type: MS_LOGIN_SUCCESS,
        payload: payload
    }
}

export function loginMsUserFailed() {
    return {
        type: MS_LOGIN_FAILED
    }
}

export function logoutMsUser() {
    return {
        type: MS_LOGOUT
    }
}