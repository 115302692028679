import React, { Component, Fragment } from "react";
import "./SettingsPage.scss";
import Breadcrumbs from "../../components/Breadcrumbs";
import SettingsSidebar from "../../components/SettingsSidebar";
import Add from "../../assets/add.png";
import SettingsUserRow from "../../components/SettingsUserRow";
import SettingsBotRow from "../../components/SettingsBotRow";
import CustomModal from "../../components/CustomModal";
import validators from "../../lib/validators";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import Loader from "../../components/Loader";
import helpers, { groups } from "../../lib/helpers";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { instance as axios } from "../../lib/axios";
import _ from "lodash";
import urls from "../../lib/urls";
import { tokenHeader } from "../../lib/headers";
import { FormattedMessage } from "react-intl";

const state = {
  input: "",
  nameInput: {
    name: "nameInput",
    value: "",
    isValid: true,
    validator: "general",
  },
  surnameInput: {
    name: "surnameInput",
    value: "",
    isValid: true,
    validator: "general",
  },
  emailInput: {
    name: "emailInput",
    value: "",
    isValid: true,
    validator: "email",
  },
  companyInput: {
    name: "companyInput",
    value: "",
    isValid: true,
    validator: "general",
  },
  userType: groups[3],
  modal: false,
  created: false,
  editModal: false,
  idToEdit: null,
  deleteModal: false,
  idToDelete: null,
  saved: false,
  collapse: false,
  groupId: 3,
};

class SettingsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      type: "users",
      botsArray: [],
      usersArray: [],
      relations: [],
      collapsedId: null,
      xhr: false,
      modalXhr: false,
      userData: null,
      botData: null,
      ...state,
    };
  }

  handleCollapse = async (id, data) => {
    if (this.state.collapsedId === id) {
      this.setState({
        collapse: false,
        collapsedId: null,
      });
    } else {
      await this.createNewUsersArray();
      let users = [...this.state.usersArray];
      data.users.forEach((botUser) => {
        let foundUser = _.find(users, (user) => user.id === botUser.id);
        if (foundUser) {
          foundUser.included = true;
          foundUser.id_relation = true;
        }
      });
      this.setState({
        type: this.state.type,
        collapse: true,
        collapsedId: id,
        idToEdit: id,
        usersArray: users,
        companyInput: { ...this.state.companyInput, value: data.name },
      });
    }
  };

  handleValidation = (name) => {
    let input = this.state[name];
    const validator = validators[input.validator];
    const isValid = !validator(input.value);

    if (isValid)
      this.setState((prevState) => ({
        [name]: { ...prevState[name], isValid },
      }));
  };

  handleValidations = () => {
    const { nameInput, surnameInput, emailInput, companyInput, type } =
      this.state;
    const inputs = [nameInput, surnameInput, emailInput];

    if (type === "bots") {
      const isValid = !validators[companyInput.validator](companyInput.value);
      this.setState((prevState) => ({
        companyInput: { ...prevState.companyInput, isValid },
      }));
      return isValid;
    }

    let areAllInputsValid = inputs.every((input) => {
      const validator = validators[input.validator];
      const isValid = !validator(input.value);

      this.setState((prevState) => ({
        [input.name]: { ...prevState[input.name], isValid },
      }));
      return isValid;
    });

    return areAllInputsValid;
  };

  handleDeleteRow = async () => {
    let { idToDelete, type } = this.state;
    this.handleClose();
    this.setState({ xhr: true });
    if (type === "users") {
      try {
        const deleteUserUrl = `${urls.API_ROOT}/api/v1/users/${idToDelete}/`;
        await axios.delete(deleteUserUrl, tokenHeader());
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const deleteBotUrl = `${urls.API_ROOT}/api/v1/bots/${idToDelete}/`;
        await axios.delete(deleteBotUrl, tokenHeader());
      } catch (error) {
        console.log(error);
      }
    }
    await Promise.all([this.fetchUserData(), this.fetchBotData()]);
    await this.handleCloseModal();
    this.setState({ xhr: false });
  };

  handleConfirmation = (id) => {
    this.setState({
      idToDelete: id,
      deleteModal: true,
    });
  };

  handleEdit = (data) => {
    if (this.state.type === "bots") {
      this.setState({
        editModal: true,
        companyInput: { ...this.state.companyInput, value: data.name },
        idToEdit: data.id,
      });
    } else {
      if (data.groups.length > 0) {
        this.setState({
          userType: data.groups[0]["name"],
          groupId: data.groups[0]["id"],
        });
      }
      let bots = [...this.state.botsArray];
      data.bots.forEach((userBot) => {
        let foundBot = _.find(
          bots,
          (bot) => bot.id === userBot.id
        );
        if (foundBot) {
          foundBot.included = true;
          foundBot.id_relation = true;
        }
      });

      this.setState({
        editModal: true,
        nameInput: { ...this.state.nameInput, value: data.first_name },
        surnameInput: { ...this.state.surnameInput, value: data.last_name },
        emailInput: { ...this.state.emailInput, value: data.email },
        idToEdit: data.id,
        botsArray: bots,
      });
    }
  };

  updateBotUserRelations = async (botId) => {
    this.setState({ xhr: true });
    for await (const user of this.state.usersArray) {
      let shouldDelete = !user.included && user.id_relation;
      let shouldPost = user.included && !user.id_relation;
      if (shouldDelete) {
        await this.deleteRelation(user.id, botId);
      } else if (shouldPost) {
        await this.postNewRelation(user.id, botId);
      }
    }
    await this.getUserBotRelations();
    this.setState({ xhr: false });
  };

  updateUserBotRelations = async (userId) => {
    this.setState({ modalXhr: true });
    for await (const bot of this.state.botsArray) {
      let shouldDelete = !bot.included && bot.id_relation;
      let shouldPost = bot.included && !bot.id_relation;
      if (shouldDelete) {
        await this.deleteRelation(userId, bot.id);
      } else if (shouldPost) {
        await this.postNewRelation(userId, bot.id);
      }
    }
    await this.getUserBotRelations();
    this.setState({ modalXhr: false });
  };

  postNewRelation = async (user, bot) => {
    let data = { user, bot };
    try {
      const user_bot_relation = `${urls.API_ROOT}/api/v1/user-bot-relation/`;
      await axios.post(user_bot_relation, data, tokenHeader());
    } catch (error) {
      console.log(error);
    }
  };

  deleteRelation = async (user, bot) => {
    const { relations } = this.state;
    let relationToDelete = relations.find(
      (relation) => relation.bot === bot && relation.user === user
    );
    if (relationToDelete) {
      try {
        const user_bot_relation = `${urls.API_ROOT}/api/v1/user-bot-relation/${relationToDelete.id}`;
        await axios.delete(user_bot_relation, tokenHeader());
      } catch (error) {
        console.log(error);
      }
    }
  };

  getUserBotRelations = async () => {
    if (this.state.editModal) {
      this.setState({ modalXhr: true });
    } else {
      this.setState({ xhr: true });
    }
    try {
      const userBotRelation = `${urls.API_ROOT}/api/v1/user-bot-relation/`;
      const res = await axios.get(userBotRelation, tokenHeader());
      const relations = res.data;
      this.setState({ relations });
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ modalXhr: false, xhr: false });
    }
  };

  handleSave = async (e) => {
    e.preventDefault();
    if (this.handleValidations()) {
      let { nameInput, surnameInput, idToEdit, groupId, type, companyInput } =
        this.state;
      this.setState({ modalXhr: false, xhr: true, editModal: false });
      if (type === "users") {
        try {
          const editUserUrl = `${urls.API_ROOT}/api/v1/users/${idToEdit}/`;
          await axios.put(
            editUserUrl,
            {
              first_name: nameInput.value,
              last_name: surnameInput.value,
              group_id: groupId,
            },
            tokenHeader()
          );
          await this.updateUserBotRelations(idToEdit);
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          const editBotUrl = `${urls.API_ROOT}/api/v1/bots/${idToEdit}/`;
          await axios.put(
            editBotUrl,
            {
              name: companyInput.value,
            },
            tokenHeader()
          );
        } catch (error) {
          console.log(error);
        }
      }
      await Promise.all([this.fetchUserData(), this.fetchBotData()]);
      await this.handleCloseModal();
      this.setState({ xhr: false });
    }
  };

  handleCreate = async (e) => {
    e.preventDefault();
    let { emailInput, nameInput, surnameInput, groupId, companyInput, type } =
      this.state;
    if (this.handleValidations()) {
      this.setState({ modalXhr: true });
      if (type === "users") {
        try {
          const newUserUrl = `${urls.API_ROOT}/api/v1/users/`;
          const res = await axios.post(
            newUserUrl,
            {
              email: emailInput.value,
              first_name: nameInput.value,
              last_name: surnameInput.value,
              group_id: groupId,
            },
            tokenHeader()
          );
          const { id: userId } = res.data;
          await this.createUserBotRelations(userId);
          this.setState({ created: true });
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          const newBotUrl = `${urls.API_ROOT}/api/v1/bots/`;
          const res = await axios.post(
            newBotUrl,
            {
              name: companyInput.value,
            },
            tokenHeader()
          );
          const { id: botId } = res.data;
          await this.createUserBotRelations(botId);
          this.setState({ created: true });
        } catch (error) {
          console.log(error);
        }
      }
      this.setState({ modalXhr: false });
    }
  };

  createUserBotRelations = async (id) => {
    const userBotRelation = `${urls.API_ROOT}/api/v1/user-bot-relation/`;
    if (this.state.type === "users") {
      const selectedBots = this.getIncludedBots();
      for await (const bot of selectedBots) {
        let data = { user: id, bot: bot.id };
        try {
          await axios.post(userBotRelation, data, tokenHeader());
        } catch (error) {
          console.log(error);
        }
      }
    } else {
      const selectedUsers = this.getIncludedUsers();
      for await (const user of selectedUsers) {
        let data = { bot: id, user: user.id };
        try {
          await axios.post(userBotRelation, data, tokenHeader());
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  handleDelete = async (e, value) => {
    let { botsArray, usersArray, collapsedId } = this.state;
    if (this.state.type === "users") {
      let bot = _.find(botsArray, (bot) => {
        return bot.id === value.id;
      });
      bot.included = false;
      this.setState({ botsArray: botsArray });
    } else {
      let user = _.find(usersArray, (user) => {
        return user.id === value.id;
      });
      user.included = false;
      this.setState({ usersArray });
      await this.updateBotUserRelations(collapsedId);
      await this.fetchBotData();
    }
  };

  handleAdd = async () => {
    let { input, botsArray, usersArray, collapsedId } = this.state;
    if (input === undefined || input === "") return;
    if (this.state.type === "users") {
      if (!validators.general(input.label)) {
        let bot = _.find(botsArray, (bot) => {
          return bot.id === input.value;
        });
        bot.included = true;
        this.setState({ botsArray: botsArray, input: "" });
      }
    } else {
      if (!validators.general(input.label)) {
        let user = _.find(usersArray, (user) => {
          return user.id === input.value;
        });
        user.included = true;
        this.setState({ usersArray, input: "" });
        await this.updateBotUserRelations(collapsedId);
        await this.fetchBotData();
      }
    }
  };

  handleClick = async (value) => {
    if (value === "bots") {
      this.setState({ collapsedId: null, type: value });
      await this.fetchUserData();
    } else {
      await this.fetchUserData();
      this.setState({ type: value });
    }
  };

  handleOpenModal = () => {
    this.setState({
      modal: true,
      usersArray: [],
      companyInput: {
        name: "companyInput",
        value: "",
        isValid: true,
        validator: "general",
      },
    });
  };

  handleCloseModal = async () => {
    this.setState({ modal: false, editModal: false, deleteModal: false });
    if (this.state.created) {
      this.setState({
        ...state,
        type: this.state.type,
      });
      await Promise.all([this.fetchUserData(), this.fetchBotData()]);
    } else {
      await Promise.all([this.fetchBotData(), this.fetchUserData()]);
      this.createNewBotsArray();
      this.createNewUsersArray();
      this.setState({
        ...state,
        userData: this.state.userData,
        botData: this.state.botData,
        type: this.state.type,
      });
    }
  };

  handleClose = () => {
    this.setState({
      ...state,
      modal: false,
      editModal: false,
      deleteModal: false,
      type: this.state.type,
    });
  };

  handleChange = (target) => {
    const { name, value } = target;
    this.setState(
      (prevState) => ({
        [name]: { ...prevState[name], value },
      }),
      () => this.handleValidation(name)
    );
  };

  handleSelect = (value) => {
    let id = Object.keys(groups).find(
      (groupId) => helpers.groups[groups[groupId]] === value
    );
    this.setState({ userType: groups[id], groupId: id });
  };

  fetchUserData = async () => {
    const usersUrl = `${urls.API_ROOT}/api/v1/users/`;
    this.setState({ xhr: true });

    try {
      const res = await axios.get(usersUrl, tokenHeader());
      const userData = res.data;
      this.setState({ userData }, this.createNewUsersArray);
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ xhr: false });
    }
  };

  fetchBotData = async () => {
    const botsUrl = `${urls.API_ROOT}/api/v1/bots/`;
    this.setState({ xhr: true });

    try {
      const res = await axios.get(botsUrl, tokenHeader());
      const botData = res.data;
      this.setState({ botData: botData }, this.createNewBotsArray);
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ xhr: false });
    }
  };

  createNewBotsArray = () => {
    const { botData } = this.state;
    const botsArray = botData.map((bot) => ({
      ...bot,
      included: false,
      id_relation: false,
    }));
    this.setState({ botsArray: botsArray });
  };

  createNewUsersArray = () => {
    const { userData } = this.state;
    const usersArray = userData.map((user) => ({
      ...user,
      included: false,
      id_relation: false,
    }));
    this.setState({ usersArray });
  };

  handleSelectChange = (value) => {
    if (this.state.input !== value) {
      this.setState({
        input: value,
      });
    }
  };

  getIncludedUsers = () => {
    return this.state.usersArray.filter((user) => user.included);
  };

  getIncludedBots = () => {
    return this.state.botsArray.filter((bot) => bot.included);
  };

  getOptions = () => {
    if (this.state.type === "users") {
      return this.state.botsArray.filter((bot) => !bot.included);
    } else {
      return this.state.usersArray.filter((user) => !user.included);
    }
  };

  async componentDidMount() {
    const { user, history } = this.props;
    if (_.get(user, "groups[0].name", "") !== helpers.groups.ADMINISTRATOR)
      return history.push("/");

    await Promise.all([
      this.fetchUserData(),
      this.fetchBotData(),
      this.getUserBotRelations(),
    ]);
  }

  //For compatibility reasons
  clearInput = () => {
    return;
  };

  render() {
    let {
      type,
      modal,
      nameInput,
      surnameInput,
      emailInput,
      companyInput,
      userType,
      input,
      created,
      editModal,
      deleteModal,
      userData,
      botData,
      collapse,
      collapsedId,
      xhr,
      modalXhr,
    } = this.state;

    const { isLoading } = this.props.ui;

    return (
      <div className={"settings-page-container"}>
        {isLoading ? (
          <Loader />
        ) : (
          <div className={"settings-page"}>
            <CustomModal
              modal={modal || editModal}
              type={type}
              name={nameInput.value}
              surname={surnameInput.value}
              email={emailInput.value}
              userType={userType}
              bots={this.getIncludedBots()}
              options={this.getOptions()}
              nameIsValid={nameInput.isValid}
              surnameIsValid={surnameInput.isValid}
              emailIsValid={emailInput.isValid}
              onSelect={this.handleSelect}
              onChange={this.handleChange}
              onCancel={this.handleCloseModal}
              input={input}
              onAdd={this.handleAdd}
              onDelete={this.handleDelete}
              onCreate={this.handleCreate}
              onSave={this.handleSave}
              created={created}
              edit={editModal}
              company={companyInput.value}
              companyIsValid={companyInput.isValid}
              onSelectChange={this.handleSelectChange}
              xhr={modalXhr}
              onClose={this.handleClose}
              clearInput={this.clearInput}
            />
            {deleteModal && (
              <ConfirmationModal
                modal={deleteModal}
                type={type}
                toDelete
                onClose={this.handleClose}
                onConfirm={this.handleDeleteRow}
                xhr={modalXhr}
              />
            )}
            <Breadcrumbs text={"volver"} from={"settings"} />
            <div className={"title"}>
              <h4>
                <FormattedMessage id="setting.config" />
                {/* Configuración */}
              </h4>
            </div>
            <div className={"settings-page-content"}>
              <div className={"btn"}>
                <button onClick={this.handleOpenModal} disabled={xhr}>
                  <img src={Add} alt="Agregar" />
                  <span>
                    {type === "users" ? "Nuevo Usuario" : "Nuevo Bot"}
                  </span>
                </button>
              </div>
              <div className={"settings-page-main"}>
                {type === "users" ? (
                  <Fragment>
                    <SettingsSidebar type={type} onClick={this.handleClick} />
                    <div className={"settings-page-list users"}>
                      {xhr ? (
                        <Loader />
                      ) : (
                        userData &&
                        userData.map((user, i) => {
                          return (
                            <SettingsUserRow
                              key={i}
                              data={user}
                              onDelete={() => this.handleConfirmation(user.id)}
                              onEdit={() => this.handleEdit(user)}
                            />
                          );
                        })
                      )}
                    </div>
                  </Fragment>
                ) : (
                  <Fragment>
                    <SettingsSidebar type={type} onClick={this.handleClick} />
                    <div className={"settings-page-list bots"}>
                      {xhr ? (
                        <Loader />
                      ) : (
                        botData &&
                        botData.map((bot, i) => {
                          return (
                            <SettingsBotRow
                              key={i}
                              options={this.getOptions()}
                              data={bot}
                              onCollapse={() =>
                                this.handleCollapse(bot.id, bot)
                              }
                              collapse={collapse}
                              collapsedId={collapsedId}
                              input={input}
                              onChange={this.handleChange}
                              onAdd={this.handleAdd}
                              users={this.getIncludedUsers()}
                              onDeleteTag={this.handleDelete}
                              onEdit={() => this.handleEdit(bot)}
                              onDelete={() =>
                                this.handleConfirmation(bot.id)
                              }
                              onSelect={this.handleSelect}
                              onSelectChange={this.handleSelectChange}
                              xhr={xhr}
                            />
                          );
                        })
                      )}
                    </div>
                  </Fragment>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    ui: state.ui,
  };
};

export default withRouter(connect(mapStateToProps)(SettingsPage));
