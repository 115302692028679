import React from 'react';
import { FormattedMessage } from 'react-intl';

const columns = [{
  dataField: 'day',
  text: <FormattedMessage id="report.Day"/>,
  // text: 'Día',
  isCustom: true,
  headerStyle: () => {
    return {width: '12%'};
  },
  style: {
    width: '12%'
  }
}, {
  dataField: 'received',
  text: <FormattedMessage id="report.ID-client"/>,
  // text: 'ID Cliente Recibidos',
  isCustom: true,
  headerStyle: () => {
    return {width: '12%'};
  },
  style: {
    width: '12%',
  }
}, {
  dataField: 'resting',
  text: <FormattedMessage id="report.Descanso"/>,
  // text: 'Descanso',
  isCustom: true,
  headerStyle: () => {
    return {width: '12%'};
  },
  style: {
    width: '12%',
  }
}, {
  dataField: 'managed',
  text: <FormattedMessage id="report.ID-client-gest"/>,
  // text: 'ID Cliente Gestionados',
  isCustom: false,
  headerStyle: () => {
    return {width: '12%'};
  },
  style: {
    width: '12%',
  },
}, {
  dataField: 'contacted',
  text: <FormattedMessage id="report.Contactados"/>,
  // text: 'Contactado',
  isCustom: false,
  headerStyle: () => {
    return {width: '12%'};
  },
  style: {
    width: '12%',
  },
}, {
  dataField: 'contacted_p',
  text: <FormattedMessage id="report.Contactabilidad%"/>,
  // text: 'Contactabilidad(%)',
  isCustom: false,
  headerStyle: () => {
    return {width: '12%'};
  },
  style: {
    width: '12%',
  },
}, {
  dataField: 'promise',
  text: <FormattedMessage id="report.Compromisos"/>,
  // text: 'Compromisos',
  isCustom: false,
  headerStyle: () => {
    return {width: '12%'};
  },
  style: {
    width: '12%',
  },
}, {
  dataField: 'promise_p',
  text: <FormattedMessage id="report.Compromisos%"/>,
  // text: 'Compromisos(%)',
  isCustom: false,
  headerStyle: () => {
    return {width: '12%'};
  },
  style: {
    width: '12%',
  },
}
];

export default columns;
