/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import LoginPage from './pages/LoginPage/';
import LoginVerifyPage from './pages/LoginVerifyPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage/';
import ResetPasswordPage from './pages/ResetPasswordPage/';
import HomePage from './pages/HomePage';
import CampaignPage from './pages/CampaignPage';
import Navbar from './components/Navbar';
import CasePage from './pages/CasePage';
import InboundCasePage from './pages/InboundCasePage';
import SettingsPage from './pages/SettingsPage';
import MsftLandingPage from './pages/MsftLandingPage';
import { connect } from 'react-redux';
import PageNotFound from './pages/PageNotFound';
import _ from 'lodash';
import { IntlProvider } from 'react-intl';
import {messages as allMessages} from './messages';

class Routes extends React.Component {

  componentDidUpdate(prevProps) {
    const { user, history, msAuth, location } = this.props;
    const hasUserChange = !_.isEqual(prevProps.user, user) || !_.isEqual(prevProps.msAuth, msAuth);
    if (hasUserChange) {
      
      if (user!==null && user.hasOwnProperty('userLoginExpired')) {
        history.push(user.url)
      }
      if (
        (user !== null && !user.hasOwnProperty('userLoginFailed')) ||
        (msAuth !== null && msAuth.hasOwnProperty('userLoginSuccess'))
      ) {
        history.push('/');
      } else if (user !== null && user.hasOwnProperty('userLogin2fa')) {
        history.push('/login-verify');
      }
    }

  }

  renderRoutes() {
    const { user, msAuth } = this.props;

    if (user && user.language) {
      if (user.language==='en') {
        user.language='es'; ///fin
      }
    }else{
      if (msAuth && msAuth.language){
        if (msAuth.language==='en') {
          msAuth.language='es'; //fin         
        }}
      else{
        localStorage.removeItem('activeUser');
      }      

    }

    if (
      (user && user !== null && !user.hasOwnProperty('userLoginFailed')) ||
      (msAuth && msAuth !== null && msAuth.hasOwnProperty('userLoginSuccess'))
    ) {
      
      return (
        <div>          
          <IntlProvider locale={(msAuth.language || user.language)} messages={allMessages[(msAuth.language || user.language)]}>
            <Navbar />
            <Switch>
              <Route exact path={'/'} component={HomePage} />
              <Route
                exact
                path={'/campaigns/:id/:slug'}
                component={CampaignPage}
              />
              <Route exact path={'/campaigns/:campaignId/cases/:id'} component={CasePage} />
              <Route exact
                path={'/inbound-case/:id/:slug'}
                component={InboundCasePage}
              />
              <Route path={'/settings'} component={SettingsPage} />
              <Route
                exact
                path={'/signup'}
                component={MsftLandingPage}
              />
              <Route path='*' component={PageNotFound} />
            </Switch>
          </IntlProvider>  
        </div>
      );
    }

    return (
      <>
        <IntlProvider locale={'en'} messages={allMessages['en']}>
          <Switch>
            <Route
              exact
              path={'/'}
              component={LoginPage}
            />
            <Route
              exact
              path={'/login-verify'}
              component={LoginVerifyPage}
            />
            <Route
              path={'/forgot-password'}
              component={ForgotPasswordPage}
            />
            <Route exact path={'/password-reset/:id/:token'} component={ResetPasswordPage} />
            <Route
              exact
              path={'/signup'}
              component={MsftLandingPage}
            />
            <Route
              exact
              path={'/msftlogin'}
              component={LoginPage}
            />
            <Route path='*' component={LoginPage} />
          </Switch>
        </IntlProvider>
      </>
    );
  }

  render() {
    return <Fragment>{this.renderRoutes()}</Fragment>;
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    msAuth: state.msal,
  };
};

export default withRouter(connect(mapStateToProps)(Routes));
