import React, { Component } from "react";
import Modal from "@material-ui/core/Modal";
import { withStyles } from "@material-ui/core/styles";
import "./ConfirmationModal.scss";
import Cross from "../../assets/red.png";
import { includesWhatsappString } from "../../lib/whatsappChecker";

const styles = {
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  modal: {
    width: "40%",
    height: "35%",
    borderRadius: "3px",
    border: "1px solid #CFCFCF",
    backgroundColor: "white",
    outline: "none",
  },
  modalComingSoon: {
    width: "35%",
    height: "30%",
    borderRadius: "3px",
    border: "1px solid #CFCFCF",
    backgroundColor: "white",
    outline: "none",
  },
  modalProcessedWithErrors: {
    width: "40%",
    height: "40%",
    borderRadius: "3px",
    border: "1px solid #CFCFCF",
    backgroundColor: "white",
    outline: "none",
  },
};

class ConfirmationModal extends Component {
  render() {
    let { modal, type, toDelete, backdropDisabled, classes,conversation_type } = this.props;
    let expression;
    if (type === "campaign") {
      expression = "ESTA CAMPAÑA";
    } else if (type === "case") {
      expression = "ESTOS CASOS";
    } else if (type === "users") {
      expression = "ESTE USUARIO";
    } else if (type === "bots") {
      expression = "ESTE BOT";
    } else {
      expression = "PRÓXIMAMENTE";
    }


    if (type === "processedWithErrors") {
      return (
        <Modal
          open={modal}
          BackdropProps={{
            style: {
              backgroundColor: "rgba(255, 255, 255, 0.6)",
            },
          }}
          className={classes.root}
          onBackdropClick={
            backdropDisabled ? undefined : this.props.onBackdropClick
          }
        >
          <div className={classes.modalProcessedWithErrors}>
            <div className={"error-campaign"}>
              <div className={"error-campaign-title"}>
                <span>{"Ha ocurrido un error".toUpperCase()}</span>
              </div>
              <div
                className={
                  "error-campaign-content error-campaign processed-with-errors"
                }
              >
                <div
                  className={
                    "error-campaign-subtitle error-campaign processed-with-errors"
                  }
                >
                  <p>{`Por favor, inténtelo de nuevo más tarde.`}</p>
                </div>
              </div>
              <div className={"error-campaign-btns"}>
                <button className={"ok"} onClick={this.props.onClose}>
                  ACEPTAR
                </button>
              </div>
            </div>
          </div>
        </Modal>
      );
    }

    if (type === "coming-soon") {
      return (
        <Modal
          open={modal}
          BackdropProps={{
            style: {
              backgroundColor: "rgba(255, 255, 255, 0.6)",
            },
          }}
          className={classes.root}
          onBackdropClick={
            backdropDisabled ? undefined : this.props.onBackdropClick
          }
        >
          <div className={classes.modalComingSoon}>
            <div className="coming-soon">
              <img
                src={Cross}
                alt={"Cerrar"}
                className={"cross"}
                onClick={this.props.onClose}
              />
              <div className={"coming-soon-content"}>
                <div className={"coming-soon-text"}>
                  <h5>{expression}</h5>
                </div>
                <div className={"coming-soon-btn"}>
                  <button className={"cancel"} onClick={this.props.onClose}>
                    OK
                  </button>
                </div>
              </div>
              -
            </div>
          </div>
        </Modal>
      );
    }

    if (type === "confirmCampaign") {
      return (
        <Modal
          open={modal}
          BackdropProps={{
            style: {
              backgroundColor: "rgba(255, 255, 255, 0.6)",
            },
          }}
          className={classes.root}
        >
          <div className={classes.modal}>
            <div className="settings-delete-confirmation">
              <img
                src={Cross}
                alt={"Cerrar"}
                className={"cross"}
                onClick={this.props.onClose}
              />
              <div className="settings-delete-confirmation-content">
                <div className="settings-delete-confirmation-text">
                  <h5>
                    {includesWhatsappString(conversation_type)
                    ?"¿DESEA DETENER LA CAMPAÑA?":
                    "¿DESEA FINALIZAR LA CAMPAÑA?"}
                  </h5>
                  <span>
                    <br /> 
                    {includesWhatsappString(conversation_type)
                    ?"Esta acción no se puede deshacer. No se iniciarán nuevas conversaciones. Las conversaciones abiertas continuarán.":
                    "Esta acción no se puede deshacer"}
                    
                  </span>
                </div>
                <div
                  className={`settings-delete-confirmation-btns ${toDelete && "toDelete"
                    }`}
                >
                  <button className={"cancel"} onClick={this.props.onClose}>
                    CANCELAR
                  </button>
                  <button className={"delete"} onClick={this.props.onConfirm}>
                    {includesWhatsappString(conversation_type)?'DETENER':'FINALIZAR'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      );
    }

    return (
      <Modal
        open={modal}
        BackdropProps={{
          style: {
            backgroundColor: "rgba(255, 255, 255, 0.6)",
          },
        }}
        className={classes.root}
        onBackdropClick={
          backdropDisabled ? undefined : this.props.onBackdropClick
        }
      >
        <div className={classes.modal}>
          <div className="settings-delete-confirmation">
            <img
              src={Cross}
              alt={"Cerrar"}
              className={"cross"}
              onClick={this.props.onClose}
            />
            <div className="settings-delete-confirmation-content">
              <div className="settings-delete-confirmation-text">
                <h5>{`¿DESEA ELIMINAR ${expression}?`}</h5>
                <span>
                  Se perderán todos los datos.
                  <br /> Esta acción no se puede deshacer
                </span>
              </div>
              <div
                className={`settings-delete-confirmation-btns ${toDelete && "toDelete"
                  }`}
              >
                <button className={"cancel"} onClick={this.props.onClose}>
                  {`${toDelete &&
                    (type === "campaign"
                      ? "MANTENER CAMPAÑA"
                      : type === "case"
                        ? "MANTENER"
                        : "CANCELAR")
                    }`}
                </button>
                <button
                  className={"delete"}
                  onClick={
                    type === "case"
                      ? this.props.onDeleteCases
                      : type === "campaign"
                        ? this.props.onDeleteCampaign
                        : this.props.onConfirm
                  }
                >
                  {`${toDelete &&
                    (type === "campaign" ? "ELIMINAR CAMPAÑA" : "ELIMINAR")
                    }`}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default withStyles(styles)(ConfirmationModal);
