import React, { Component } from "react";
import "./ImportCasesForm.scss";
import Cross from "../../assets/red.png";
import CustomDropzone from "../CustomDropzone";
import Loader from "../Loader";

class ImportCasesForm extends Component {
  render() {
    let { imported, file, fileInvalid, casesFound, receivedWithErrors, processedWithErrors,
      xhr
    } = this.props;

    return (
      <form className={"import-cases-container"}>
        <img src={Cross} alt={"Cerrar"} className={"cross"} onClick={this.props.onClose}/>
        {imported ?
          (processedWithErrors ? (
              <div className={"error-campaign processed-with-errors"}>
                <div className={"error-campaign-title"}>
                  <span>{"Ha ocurrido un error".toUpperCase()}</span>
                </div>
                <div className={"error-campaign-content error-campaign processed-with-errors"}>
                  <div className={"error-campaign-subtitle error-campaign processed-with-errors"}>
                    <p>{`Por favor, inténtelo de nuevo más tarde.`}</p>
                  </div>
                </div>
                <div className={"error-campaign-btns"}>
                  <button className={"ok"} onClick={this.props.onCancel}>
                    ACEPTAR
                  </button>
                </div>
              </div>
            ) : (
              <div className={"import-cases"}>
                {xhr && <Loader/>}
                <div className={"import-cases-title"}>
                  <span>Listado de casos</span>
                </div>
                <div className={`import-cases-content ${xhr && 'xhr'}`}>
                  <span className={"subtitle title"}>SE ENCONTRARON:</span>
                  <div className={'results'}>
                    <div className={"amount"}>
                      <span>{casesFound.toUpdate}</span>
                    </div>
                    <span className={"subtitle"}>CASOS ACTUALIZADOS</span>
                  </div>
                  <div className={'results'}>
                    <div className={"amount"}>
                      <span>{casesFound.toCreate}</span>
                    </div>
                    <span className={"subtitle"}>NUEVOS CASOS</span>
                  </div>
                </div>
                <div className={`import-cases-btns ${xhr && 'xhr'}`}>
                  <button className={"next"} onClick={this.props.onConfirm} disabled={xhr}>
                    AGREGAR
                  </button>
                  <button className={"cancel"} onClick={this.props.onClose} disabled={xhr}>
                    Cancelar
                  </button>
                </div>
              </div>)
          ) : (receivedWithErrors ? (
            <div className={"error-campaign"}>
              <div className={"error-campaign-title"}>
                <span>{"El archivo subido contiene errores".toUpperCase()}</span>
              </div>
              <div className={"error-campaign-content"}>
                <div className={"error-campaign-subtitle"}>
                  <p>{`Por favor, revise debajo los registros de auditoría generados para el archivo subido:`}</p>
                </div>
                <div className={"log-details"}>
                  {receivedWithErrors.result.error_list.map((error, i) =>
                    <div className="log-entry" key={i}>
                      <ul>{error}</ul>
                    </div>
                  )}
                </div>
              </div>
              <div className={"error-campaign-btns"}>
                <button className={"ok"} onClick={this.props.onBack}>
                  VOLVER
                </button>
              </div>
            </div>
          ) : (
            <div className={"import-cases"}>
              {xhr && <Loader/>}
              <div className={"import-cases-title"}>
                <span>Listado de casos</span>
              </div>
              <div className={`import-cases-dropzone ${xhr && 'xhr'}`}>
                <CustomDropzone from={"import"} onDrop={this.props.onDrop} file={file} fileInvalid={fileInvalid}
                                onDelete={this.props.onDelete} type={"new-campaign"}/>
              </div>
              <div className={`import-cases-btns ${xhr && 'xhr'}`}>
                <button className={"next"} onClick={this.props.onClick} disabled={xhr}>
                  SIGUIENTE
                </button>
                <button className={"cancel"} onClick={this.props.onClose} disabled={xhr}>
                  Cancelar
                </button>
              </div>
            </div>
          ))
        }
      </form>
    );
  }

}

export default ImportCasesForm;