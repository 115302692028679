/* eslint-disable react/prop-types */
import React, {Component} from 'react';
import './CustomButton.scss';

class CustomButton extends Component {
  render() {
    let {text, className, isChattigo, isPelikanus} = this.props;
    return (
      <div className={isChattigo?'custom-button-container-chattigo':isPelikanus?'custom-button-container-pelikanus':'custom-button-container'}>
        <button className={`custom-button ${className}`} onClick={this.props.onClick}>{text}</button>
      </div>
    )
  }
}

export default CustomButton