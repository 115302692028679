import {
    MS_RESOLVE_SUCCESS,
    MS_RESOLVE_NEW_PURCHASE,
    MS_RESOLVE_FAILED,
    MS_RESOLVE_SUBSCRIPTION_CREATED,
} from "./actions";

export default function (state = {}, action) {
    switch (action.type) {
        case MS_RESOLVE_SUCCESS:
            return {
                ...state.msmarket,
                tokenSuccess: true,
            };
        case MS_RESOLVE_NEW_PURCHASE:
            let subscription_data = action.payload;
            localStorage.setItem("msAuth", JSON.stringify(subscription_data.oauth));
            return {
                ...state.msmarket,
                subscription: subscription_data
            }
        case MS_RESOLVE_SUBSCRIPTION_CREATED:
            let creation_data = action.payload;
            return {
                creationEmail: creation_data.email,
                subscriptionCreated: !creation_data.can_login,
                canLogin: creation_data.can_login,
            }
        case MS_RESOLVE_FAILED:
            let error = action.error.data;
            return {
                error: error
            }

        default:
            return state;
    }
}