import React, { Component } from 'react'
import './LoginVerifyPage.scss'
import { withRouter } from 'react-router-dom'
import InitialNavbar from '../../components/InitialNavbar'
import CustomInput from '../../components/CustomInput'
import CustomBottom from '../../components/CustomButton'
import Key from '../../assets/key.png'
import { sendOTP } from '../../modules/user/actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Loader from '../../components/Loader';
import { Alert, AlertTitle } from '@material-ui/lab';
import validators from '../../lib/validators';


class LoginVerifyPage extends Component {
  constructor() {
    super()

    this.state = {
      otp: '',
      otpExpired: false,
      otpFailed: false,
      otpInvalid: false,
      error: false,
      xhr: false,
      isChattigo:false,
      isPelikanus:false,
    }
  }

  validateField = () => {
    let invalid = false;
    if (validators.general(this.state.otp)) {
      this.setState({
        otpInvalid: true
      })
      invalid = true;
    }
    return invalid;
  }

  handleClick = e => {
    e.preventDefault()
    if (!this.validateField()) {
      this.props.sendOTP(this.state.otp);
      this.setState({ xhr: true })
    }
  }

  handleChange = ({ name, value }) => {
    this.setState({
      [name]: value
    })
  }

  componentDidUpdate(prevProps) {



    if (prevProps.user !== this.props.user && this.props.user !== null && this.props.user.hasOwnProperty('userLoginOTPFailed')) {
      this.setState({
        error: true,
        otpFailed: true,
        xhr: false
      })
    } else if (prevProps.user !== this.props.user && this.props.user !== null && this.props.user.hasOwnProperty('userLoginOTPExpired')) {
      this.setState({
        otpExpired: true,
        error: true,
        xhr: false
      })
    }
  }

  async componentDidMount() {
    if (this.props.user === null) {
      this.props.history.push("/")
    }
    const Url = window.location.hostname;
    if (Url==='chattigo.inceptia.ai') {
     this.setState({isChattigo:true})  
    }  
    if (Url==='pelikanus.inceptia.ai') {
      this.setState({isPelikanus:true})
    } 
  }

  render() {
    const { error, otpExpired, otpFailed,isChattigo,isPelikanus } = this.state;
    return (
      <div className={isChattigo?'login-verify-page-container background-chattigo':isPelikanus?'login-verify-page-container background-pelikanus':'login-verify-page-container background-inceptia'}>
        <InitialNavbar isChattigo={isChattigo} isPelikanus={isPelikanus}/>
        {otpExpired &&
          <Alert severity={"error"}>
            <AlertTitle>Código expirado</AlertTitle>
            {"Hemos enviado un código nuevo a su correo electrónico."}
          </Alert>
        }
        {otpFailed &&
          <Alert severity={"error"}>
            <AlertTitle>Código erróneo</AlertTitle>
            {"El código ingresado es erróneo, ingréselo nuevamente por favor."}
          </Alert>
        }
        {!error &&
          <Alert severity={"success"}>
            <AlertTitle>Credenciales correctas</AlertTitle>
            {"Su cuenta tiene configurado doble factor de autenticación. Por favor, ingrese el código enviado a su correo electrónico para finalizar el inicio de sesión."}
          </Alert>
        }
        <div className={'login-verify-page'}>
          {this.state.xhr ? (
            <Loader />
          ) : (
            <form className={'login-verify'}>
              <span>{"DOBLE FACTOR DE AUTENTICACIÓN"}</span>
              <div className={'inputs'}>
                <CustomInput
                  type={'password'}
                  name={'otp'}
                  placeholder={'Token de verificación'}
                  value={this.state.otp}
                  onChange={this.handleChange}
                  invalid={this.otpInvalid}
                  error={this.state.error}
                  onFocus={this.handleFocus}
                  img={Key}
                />
              </div>
              <CustomBottom
                isChattigo={isChattigo}
                isPelikanus={isPelikanus}
                className={'login-btn'}
                text={'Validar'}
                onClick={this.handleClick}
                onChange={this.handleChange}
              />
            </form>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ sendOTP }, dispatch);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginVerifyPage));
