import React from 'react';
import { FormattedMessage } from 'react-intl';

const columns = [{
  dataField: 'callEndDate',
  text: <FormattedMessage id="report.Gestionado"/>,
  // text: 'Gestionado',
  isCustom: true,
  headerStyle: () => {
    return {width: '10%'};
  },
  style: {
    width: '10%',
    borderLeft: 'none'
  }
}, {
  dataField: 'code',
  text: <FormattedMessage id="report.ID"/>,
  // text: 'ID',
  isCustom: false,
  path: 'code',
  headerStyle: () => {
    return {width: '6%'};
  },
  style: {
    width: '6%',
    color: '#00124B',
  }
}, {
  dataField: 'first_name',
  text: <FormattedMessage id="report.Nombre"/>,
  // text: 'Nombre',
  isCustom: false,
  path: 'first_name',
  headerStyle: () => {
    return {width: '12%'};
  },
  style: {
    width: '12%',
    color: '#00124B',
    fontWeight: '500'
  },
  title: true
}, {
  dataField: 'last_name',
  text: <FormattedMessage id="report.Apellido"/>,
  // text: 'Apellido',
  isCustom: false,
  path: 'last_name',
  headerStyle: () => {
    return {width: '12%'};
  },
  style: {
    width: '12%',
    color: '#00124B',
    fontWeight: '500'
  },
  title: true
}, {
  dataField: 'product',
  text: <FormattedMessage id="report.Producto"/>,
  // text: 'Producto',
  isCustom: false,
  path: 'product',
  headerStyle: () => {
    return {width: '10%'};
  },
  style: {
    width: '10%'
  }
}, {
  dataField: 'debt',
  text: <FormattedMessage id="report.Deuda"/>,
  // text: 'Deuda',
  isCustom: true,
  path: 'debt',
  headerStyle: () => {
    return {width: '8%'};
  },
  style: {
    width: '8%'
  }
}, {
  dataField: 'expiration',
  text: <FormattedMessage id="report.Vencimiento"/>,
  // text: 'Vencimiento',
  isCustom: true,
  path: 'expiration',
  headerStyle: () => {
    return {width: '7%'};
  },
  style: {
    width: '7%'
  }
}, {
  dataField: 'calls',
  text: <FormattedMessage id="report.Llamadas"/>,
  // text: 'Llamadas',
  isCustom: true,
  headerStyle: () => {
    return {width: '15%'};
  },
  style: {
    width: '15%'
  }
}, {
  dataField: 'commitment',
  text: <FormattedMessage id="report.Compromisos"/>,
  // text: 'Compromiso',
  isCustom: true,
  headerStyle: () => {
    return {width: '9%'};
  },
  style: {
    width: '9%'
  }
}, {
  dataField: 'result',
  path: 'result',
  text: <FormattedMessage id="report.Estado"/>,
  // text: 'Estado',
  isCustom: true,
  headerStyle: () => {
    return {width: '14%'};
  },
  style: {
    width: '14%'
  }
}];

export default columns;