import React, { Component } from "react";
import "./NewUserForm.scss";
import Cross from "../../assets/red.png";
import AddTags from "../../components/AddTags";
import Tic from "../../assets/tic.png";
import helpers from "../../lib/helpers";
import Loader from "../Loader/Loader";

class NewUserForm extends Component {
  render() {
    let {
      name,
      surname,
      nameInvalid,
      surnameInvalid,
      email,
      emailInvalid,
      userType,
      bots,
      input,
      created,
      edit,
      options,
      xhr
    } = this.props;

    if (created) {
      return (
        <div className="new-user-form-container">
          <img src={Cross} alt={"Cerrar"} className={"cross"} onClick={this.props.onClose} />
          <div className="new-user-form-content-success">
            <img src={Tic} alt={"Tic"} />
            <span>¡USUARIO CREADO!</span>
          </div>
          <div className={"new-user-form-btns success"}>
            <button className={"ok"} onClick={this.props.onCancel}>
              ACEPTAR
            </button>
          </div>
        </div>
      );
    }

    return (
      <div className="new-user-form-container">
        <img src={Cross} alt={"Cerrar"} className={"cross"} onClick={this.props.onClose} />
        <div className="new-user-form">
          {xhr && <Loader />}
          <div className={"new-user-form-title"}>
            <span>{edit ? "" : "NUEVO USUARIO"} </span>
          </div>
          <div className={`new-user-form-content ${xhr && 'xhr'}`}>
            <div className={"new-user-form-inputs"}>
              <div className="new-user-form-input">
                <input
                  value={name}
                  placeholder={"Nombre"}
                  name={"nameInput"}
                  onChange={e => this.props.onChange(e.target)}
                />
                <label className={"error"}>{nameInvalid ? "* Ingrese un nombre" : ""}</label>
              </div>
              <div className="new-user-form-input">
                <input
                  value={surname}
                  placeholder={"Apellido"}
                  name={"surnameInput"}
                  onChange={e => this.props.onChange(e.target)}
                />
                <label className={"error"}>{surnameInvalid ? "* Ingrese un apellido" : ""}</label>
              </div>
            </div>

            <div className="new-user-form-email">
              <label>E-mail</label>
              <input
                value={email}
                placeholder={"ejemplo@mail.com"}
                name={"emailInput"}
                onChange={e => this.props.onChange(e.target)}
                disabled={edit}
                style={{ opacity: edit && 0.57, color: edit && '#8b8b8b' }}
              />
              <label className={"error"}>{emailInvalid ? "* Ingrese un email válido" : ""}</label>
            </div>

            <div className="new-user-form-type">
              <label>Perfil de usuario</label>
              <div className="type">
                {Object.keys(helpers.groupsDisplay).reverse().map((group, i) =>
                  <div
                    key={i}
                    onClick={() => {
                      this.props.onSelect(`${group}`);
                    }}
                    className={`select ${userType === group && "selected"}`}
                  >
                    <span>{helpers.groupsDisplay[group]}</span>
                  </div>
                )}
              </div>
            </div>

            <div className="new-user-form-bots">
              <label>Bots Asignados</label>
              <AddTags
                options={options}
                data={bots}
                input={input}
                onAdd={this.props.onAdd}
                onDelete={this.props.onDelete}
                onSelectChange={this.props.onSelectChange}
              />
            </div>
          </div>
          <div className={`new-user-form-btns ${xhr && 'xhr'}`}>
            <button className={"next"} onClick={edit ? this.props.onSave : this.props.onClick} disabled={xhr}>
              {edit ? "GUARDAR" : "CREAR"}
            </button>
            <button className={"cancel"} onClick={this.props.onClose} disabled={xhr}>
              Cancelar
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default NewUserForm;
