import React, { Component } from 'react'
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Progress from '../Progress'
import Status from '../Status'
import { Link } from 'react-router-dom'
import voca from 'voca'
import { FormattedMessage } from "react-intl";

class Card extends Component {
    render() {
        let {
            title,
            date,
            percentage_completed,
            percentage_called,
            status,
            classes,
            id,
            statusList,
            activeFilter,
            activeSection,
            search,
            from,
            isChattigo,
            isPelikanus,
            timezoneBot
        } = this.props
        let slug = voca.slugify(title)

        return (
            <Grid item xs={4}>
                <Paper className={classes.paper}>
                    <Link to={
                        {
                            pathname: `/campaigns/${id}/${slug}`,
                            state: {
                                from: from,
                                activeFilter: activeFilter,
                                activeSection: activeSection,
                                search: search,
                                timezone:timezoneBot
                            } 
                        }
                    }>
                        <div className={isChattigo?'card-container-chattigo':isPelikanus?'card-container-pelikanus':'card-container'}>
                            <div className={'card-title'}>
                                <h4>{title}</h4>
                                <span>{date}</span>
                            </div>
                            <div className={'card_data_custom'}>
                              <div className='section-progress'>
                                <span><FormattedMessage id="percentage.COMPLETED"/></span>
                                <Progress percentage={percentage_completed} size={'card_custom'} />
                              </div>
                              <div className='section-progress'>
                                <span><FormattedMessage id="percentage.CALLED"/></span>
                                <Progress percentage={percentage_called}  size={'card_custom'}/>
                              </div>
                              <div className='progress_bar_box'>
                                {statusList && status && <Status statusList={statusList} status={status} from={'card'} />}
                              </div>
                            </div>
                        </div>
                    </Link>
                </Paper>
            </Grid>
        )
    }
}

export default Card