import React, { Component } from "react";
import "./BotCampaign.scss";
import CustomInput from "../CustomInput";
import Add from "../../assets/new-santander.png";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Card from "./Card";
import Sort from "../../assets/sort.png";
import Search from "../../assets/search.png";
import Loader from "../Loader";
import EmptyState from "../EmptyState";
import { FormattedMessage } from "react-intl";

const styles = {
  paper: {
    textAlign: "center",
    height: "140px"
  },
  gridContainer: {
    width: "100%",
    margin: 0
  }
};

class BotCampaign extends Component {

  handleScroll = (event) => {
    if ((event.target.scrollHeight - event.target.scrollTop) === event.target.clientHeight) {
      this.props.onScrollCampaigns();
    }
  }

  render() {
    const {
      classes,
      activeFilter,
      activeSection,
      search,
      selectedBotCampaigns: campaigns,
      statusList,
      allBotCampaigns,
      xhr,
      isChattigo,
      isPelikanus,
      timezoneBot
    } = this.props;
    let allCampaignsCount = 0, statusDic;
    const statusKeys = statusList && Object.keys(statusList);

    if (allBotCampaigns && statusList) {
      allCampaignsCount = allBotCampaigns.length;
      statusDic = statusKeys.reduce((acc, status) => {
        const { value: statusValue } = statusList[status];
        let val = allBotCampaigns.filter(campaign => campaign.status === statusValue).length;
        if (val === undefined) {
          val = 0;
        }
        return { ...acc, [statusValue]: val };
      }, {});
    }

    const emptyState = allCampaignsCount === 0

    return (
      <div className={"bot-campaign-container"}>
        <div className={"bot-campaign"}>
          {/*<div className={"bot-campaign-header"}>
            <div className={"bot-campaign-title"}>
              <span>CAMPAÑAS</span>
            </div>

            <div className={"bot-campaign-input"}>
              <CustomInput
                type={"text"}
                placeholder={"Buscar"}
                value={search}
                name={"search"}
                onChange={this.props.onChange}
                width={"240px"}
                height
                img={Search}
              />
            </div>

            <div className={"bot-campaign-add"}>
              <button onClick={this.props.openModal} disabled={xhr}>
                <img src={Add} alt={"Agregar"}/>
              </button>
            </div>
          </div>*/}

          <div className={"bot-campaign-body"}>
            {xhr && <Loader />}
            <div className={"bot-campaign-filters"}>
              <div className={"bot-campaign-quick-filters"}>
                {statusList && statusKeys && statusKeys.map((statusKey, i) => {
                  const { status } = statusList[statusKey];
                  const { value } = statusList[statusKey];
                  return <button key={i} className={`${status} ${activeFilter === value && "active"}`}
                    onClick={() => this.props.onClick(value)}>
                    <FormattedMessage id={`campaign.${status}`} />
                    {/* {status.toUpperCase()} */}
                  </button>;
                })}
                {/* TODO: CHECK DISABLED CONDITION FOR BUTTONS */}
                <button className={`all ${activeFilter === "all" && !emptyState && "active"}`}
                  onClick={() => this.props.onClick("all")}>
                  <FormattedMessage id="campaign.all" />
                  {/* TODAS */}
                </button>
              </div>
              <div className={"bot-campaign-sort"}>
                <span>
                  <FormattedMessage id="campaign.completed" />
                  {/* Completitud */}
                </span>
                <button onClick={this.props.onSort}>
                  <img src={Sort} alt={"Sortear"} />
                </button>
              </div>
            </div>
            <div className={"bot-campaign-content-container"}>
              {((campaigns && campaigns.length === 0 || emptyState) && !xhr) ?
                <EmptyState
                  text={<FormattedMessage id="empty.campaign" />}
                // text={"No hay campañas disponibles"}
                /> :
                <div className={"bot-campaign-content"} onScroll={this.handleScroll}>
                  <Grid container spacing={2} className={classes.gridContainer}>
                    {campaigns && campaigns.length > 0 && campaigns.map(campaign => {
                      return (
                        <Card
                          classes={classes}
                          key={campaign.id}
                          title={campaign.name}
                          date={campaign.date}
                          status={campaign.status}
                          percentage_completed={campaign.percentage_completed}
                          percentage_called={campaign.percentage_called}
                          id={campaign.id}
                          statusList={statusList}
                          activeFilter={activeFilter}
                          activeSection={activeSection}
                          search={search}
                          from={"home"}
                          isChattigo={isChattigo}
                          isPelikanus={isPelikanus}
                          timezoneBot={timezoneBot}
                        />
                      );
                    })}
                  </Grid>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(BotCampaign);
