import React from 'react'
import NumberFormat from 'react-number-format'
import moment from 'moment'

const formatters = {
    amountFormatter: (value) => {
        let newValue = value
        if (value >= 1000000) {
            newValue = Math.trunc(value)
        }
        return <NumberFormat value={newValue} displayType={'text'} thousandSeparator={'.'}
                             decimalScale={2}
                             fixedDecimalScale
                             decimalSeparator={','}/>
    },
    dateTimeFormatter: (date) => {
        return date ? moment(date).format('L') : '----'
    }
}

export default formatters