import {
    MS_AUTH_URL_SUCCESS,
    MS_AUTH_URL_FAILED,
    MS_LOGIN_SUCCESS,
    MS_LOGIN_FAILED,
} from "./actions";

export default function (state = {}, action) {
    switch (action.type) {
        case MS_AUTH_URL_SUCCESS:
            let ms_auth = action.payload;
            localStorage.setItem("msAuth", JSON.stringify(ms_auth));
            return {
                ...state.msal,
                ...ms_auth,
            };
        case MS_AUTH_URL_FAILED:
            return {
                ...state.msal,
                msAuthFailed: true,
            };
        case MS_LOGIN_SUCCESS:
            let ms_login_user = action.payload;
            ms_login_user["token_date"] = new Date().getTime();
            localStorage.setItem("activeUser", JSON.stringify(ms_login_user))
            return {
                ...ms_login_user,
                userLoginSuccess: true
            };
        case MS_LOGIN_FAILED:
            return { userLoginFailed: true };

        default:
            const data = localStorage.getItem("msAuth");
            if (data) {
                return {
                    ...state.msal,
                    ...JSON.parse(data),
                };
            }
            return state;
    }
}