/* eslint-disable react/prop-types */
import React, { Component } from 'react'
import './InitialNavbar.scss'
import Logo from '../../assets/logo-inceptia.png'
import CHATTIGO from '../../assets/chattigo/logo.png';
import PELIKANUS from '../../assets/pelikanus/logo.png'

class InitialNavbar extends Component {
  render() {
    const {isChattigo,isPelikanus}= this.props;
    return (
      <div className={'initial-navbar-container'}>
        <div className={'initial-navbar'}>
          <div className={'content'}>
            {
              (isChattigo)?<img src={CHATTIGO} alt={'Chattigo'} />
                :
                (isPelikanus)?
                <div className={"pelikanus"}>
                  <img src={PELIKANUS} alt="logo" />
                  <span>powered by INCEPTIA</span>    
                </div > 
                  :
                  <>
                    <img src={Logo} alt={'Inceptia'} />
                    <span>COBRADOR INTELIGENTE</span>
                  </>
            }        
          </div>
        </div>
      </div>
    )
  }
}

export default InitialNavbar;
