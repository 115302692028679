import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import './PageNotFound.scss'
import InitialNavbar from "../../components/InitialNavbar";

class PageNotFound extends Component {
  render() {
    let user = this.props
    return (
      <div className="page-not-found-container">
        {user.user === null &&
        <InitialNavbar />
        }
        <div className="page-not-found">
            <span>ERROR 404</span>
            <p className="page-not-found-text">
              No podemos encontrar lo que estás buscando.<br/>
              Puede que el enlace esté dañado o haya expirado. ¡Lo sentimos!
            </p>
            <Link to={'/'}>Inicio</Link>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps)(PageNotFound)
