/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import './NewCampaignForm.scss';
import Cross from '../../assets/red.png';
import Phone from '../../assets/phone.png';
import Arrow from '../../assets/chevron.png';
import Info from '../../assets/info.png';
import CustomDropzone from '../CustomDropzone';
import Loader from '../Loader';
import AddTags from '../AddTags';
import helpers from '../../lib/helpers';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { FormattedMessage } from 'react-intl';

class NewCampaignForm extends Component {
  render() {
    let { title, description, file, titleInvalid, descriptionInvalid, fileInvalid, receivedWithErrors,network_failed, options,
      processedWithErrors, filteredResults, input, phonePriority, maxPhonePriority, dialmode, xhr, contactability, contactabilityInvalid } = this.props;
    return (
      <div className='container-new'>

        <form className={'new-campaign-container'}>
          <img src={Cross} alt={'Cerrar'} className={`cross ${processedWithErrors && 'processed-with-errors'}`} onClick={this.props.onClose} />    
          
          {(receivedWithErrors || processedWithErrors) 
            ? (
              receivedWithErrors ? (
                <div className={'error-campaign'}>
                  <div className={'error-campaign-title'}>
                    <span>{<FormattedMessage id="newcamp.error.upload"/>}</span>
                    {/* <span>{'El archivo subido contiene errores'.toUpperCase()}</span> */}
                  </div>
                  <div className={'error-campaign-content'}>
                    <div className={'error-campaign-subtitle'}>
                      <p>{<FormattedMessage id="newcamp.error.registros"/>}
                      </p>
                      {/* <p>{'Por favor, revise debajo los registros de auditoría generados para el archivo subido:'}
                    </p> */}
                    </div>
                    <div className={'log-details'}>
                      {receivedWithErrors.result.error_list.map((error, i) =>
                        <div className="log-entry" key={i}>
                          <ul>{error}</ul>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={'error-campaign-btns'}>
                    <button className={'ok'} onClick={this.props.onBack}>
                                      VOLVER
                    </button>
                  </div>
                </div>
              ) : (
                <div className={'error-campaign processed-with-errors'}>
                  <div className={'error-campaign-title'}>
                    <span>{<FormattedMessage id="newcamp.error.error"/>}</span>
                    {/* <span>{'Ha ocurrido un error'.toUpperCase()}</span> */}
                  </div>
                  <div className={'error-campaign-content error-campaign processed-with-errors'}>
                    <div className={'error-campaign-subtitle error-campaign processed-with-errors'}>
                      <p>{<FormattedMessage id="newcamp.error.later"/>}</p>
                      {/* <p>{'Por favor, inténtelo de nuevo más tarde.'}</p> */}
                    </div>
                  </div>
                  <div className={'error-campaign-btns'}>
                    <button className={'ok'} onClick={this.props.onClose}>
                      <FormattedMessage id="config.ok"/>
                      {/* ACEPTAR */}
                    </button>
                  </div>
                </div>
              )
            ) : ( (network_failed) ?(
              
              <div className={'error-campaign'}>
                <div className={'error-campaign-title'}>
                  <span>{<FormattedMessage id="newcamp.error.network.title"/>}</span>
                </div>
                <div className={'error-campaign-content'}>
                  <div className={'error-campaign-subtitle '}>
                    <p>{<FormattedMessage id="newcamp.error.network.subtitle"/>}</p>
                  </div>                
                </div>
                <div className={'error-campaign-btns'}>
                  <button className={'ok'} onClick={this.props.onClose}>
                    VOLVER
                  </button>
                </div>
              </div>
              


              
            ): (
              <div className={'new-campaign'}>
                {xhr && <Loader />}
            
                <div className={'new-campaign-title'}>
                  <span>
                    <FormattedMessage id="newcamp.new"/>
                    {/* NUEVA CAMPAÑA */}
                  </span>
                </div>
              
                <div className={`new-campaign-content ${xhr && 'xhr'}`}>
                  <div className={'new-campaign-input'}>
                    <FormattedMessage id="newcamp.name" defaultMessage="Nombre Campaña">
                      {placeholder=>  
                        <input 
                          value={title}
                          name="titleInput"
                          onChange={(e) => this.props.onChange(e.target)}
                          placeholder={placeholder}/>
                      }
                    </FormattedMessage>                                
                    <label className={'error'}>{titleInvalid 
                      ? <FormattedMessage id="newcamp.error.title"/>
                    // ? '* Ingrese un título' 
                      : ''}
                    </label>
                  </div>
              
                  <div className={'new-campaign-description'}>
                    <label className={'label'}>
                      <FormattedMessage id="newcamp.description"/>
                      {/* Descripción  */}
                    </label>
                    <FormattedMessage id="newcamp.description" defaultMessage="Descripción">
                      {placeholder=>  
                        <textarea
                          placeholder={placeholder}
                          value={description}
                          name="descriptionTextarea"
                          onChange={(e) => this.props.onChange(e.target)}
                        />
                      }
                    </FormattedMessage>   
                    <label className={'error'}>{descriptionInvalid 
                      ? <FormattedMessage id="newcamp.error.desc"/>
                    // ? '* Ingrese una descripción' 
                      : ''}
                    </label>
                  </div>
                
                  <div className={'new-campaign-result-filter'}>
                    <label className={'label'}>
                      <FormattedMessage id="newcamp.filter-result"/>
                      {/* Filtro de resultados */}
                    </label>
                    {xhr ? <Loader /> :
                      <AddTags
                        options={options}
                        data={filteredResults}
                        input={input}
                        onAdd={this.props.onAdd}
                        onDelete={this.props.onDeleteFilterTag}
                        onSelectChange={this.props.onSelectChange}
                        resultFilterTag
                      />
                    }
                  </div>
                
                  <DialMode
                    dialmode={dialmode}
                    handleDialModeChange={this.props.onDialModeChange}
                  />
              
                  <div className={'new-campaign-phone-priority'}>
                    <label>{dialmode === helpers.dialmodes.vertical 
                      ? <FormattedMessage id="newcamp.prioridad"/>
                    // ? 'Orden de prioridad telefónico' 
                      : <FormattedMessage id="newcamp.prioridad.max"/>
                    // : 'Máximo orden de prioridad telefónico'
                    }
                    </label>
                    <div className={'phone-priority'}>
                      <img src={Phone} alt={'Llamadas'} />
                      <span>{dialmode === helpers.dialmodes.vertical ? phonePriority : maxPhonePriority}</span>
                      <div className={'phone-priority-btns'}>
                        <button className={'up'} onClick={this.props.onIncrementPhonePriority}>
                          <img src={Arrow} alt={'Mas'} />
                        </button>
                        <button className={'down'} onClick={this.props.onDecrementPhonePriority}>
                          <img src={Arrow} alt={'Menos'} />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className={'new-campaign-contactability'}>
                    <div className={'label-contactability'}>
                      <label className={'label'}>
                        <FormattedMessage id="newcamp.porcentaje"/>
                        {/* Porcentaje contactabilidad */}
                      </label>
                      <div className={'tooltip'}>
                        <img className={'img'} src={Info} alt="Info" />
                        <span className={'tooltiptext'}>
                          <FormattedMessage id="newcamp.info.10"/>
                          {/* Ej: indicando 10, llamará a los números que atendieron 10 veces en 100 llamados */}
                        </span>
                      </div>
                    </div>
                    <input
                      placeholder={'%'}
                      value={contactability}
                      name="contactability"
                      onChange={(e) => this.props.onChange(e.target)}
                    />
                    <label className={'error'}>{contactabilityInvalid 
                      ? <FormattedMessage id="newcamp.error.int"/>
                    // ? 'Ingrese un número entero entre 1 y 100' 
                      : ''}
                    </label>
                  </div>
                  <div className={'new-campaign-dropzone'}>
                    <CustomDropzone type={'new-campaign'} onDrop={this.props.onDrop} file={file} fileInvalid={fileInvalid} onDelete={this.props.onDelete} />
                  </div>
                </div>

                <div className={`new-campaign-btns ${xhr && 'xhr'}`}>
                  <button className={'next'} onClick={this.props.onClick} disabled={xhr}>
                    <FormattedMessage id="newcamp.next"/>
                    {/* SIGUIENTE */}
                  </button>
                  <button className={'cancel'} onClick={this.props.onClose} disabled={xhr}>
                    <FormattedMessage id="newcamp.cancel"/>
                    {/* Cancelar */}
                  </button>
                </div>
              </div>
              

            )
              
            )}
        </form>
      </div>
    );
  }
  componentDidMount() {
    this.props.clearInput();
  }
}

function DialMode(props) {
  const [mode, setMode] = React.useState(props.dialmode);

  const handleChange = (event) => {
    let value = event.target.value;
    setMode(value);
    props.handleDialModeChange(value);
  };

  const style = {
    color: '#00124B',
    fontSize: '15px',
    fontFamily: '\'Roboto\', sans-serif',
    lineHeight: '23px',
    opacity: '0.57',
    marginBottom: '1px'
  };

  return (
    <FormControl component="fieldset">
      <FormLabel
        component="legend"
        sx={style}
      >
        <FormattedMessage id="newcamp.mode"/>
        {/* Modo de discado */}
      </FormLabel>
      <RadioGroup
        row
        aria-label="dial-mode"
        name="dial-mode-radio-group"
        value={mode}
        onChange={handleChange}
      >
        <FormControlLabel value={helpers.dialmodes.vertical} control={<Radio size="small" />} label="Vertical" sx={style} />
        <FormControlLabel value={helpers.dialmodes.horizontal_vertical} control={<Radio size="small" />} label="Horizontal" sx={style} />
      </RadioGroup>
    </FormControl>
  );
}



export default NewCampaignForm;
