import React, { Component } from 'react'
//import './MsftSSO.scss'

class MsftSSO extends Component {
    render() {
        let { text, className, url } = this.props
        return (
            <div className={'microsoft-sso-container'}>
                <a href={url}>{text}</a>
            </div>
        )
    }
}

export default MsftSSO;