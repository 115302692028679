import React, { Component } from 'react'
import './SettingsBotRow.scss'
import Collapse from '@material-ui/core/Collapse'
import Chevron from '../../assets/chevron.png'
import Edit from "../../assets/edit.png";
import Delete from "../../assets/delete.png";
import AddTags from '../AddTags'
import Loader from "../Loader";

class SettingsBotRow extends Component {
  render() {
    let { data, collapse, collapsedId, input, users, options, xhr } = this.props;
    let arrLength = data.users.filter(bot => bot.is_active).length;

    return (
      <div className={'settings-bot-row-container'} style={{ minHeight: collapse && collapsedId === data.id && '159px' }}>
        <div className={'settings-bot'}>
          <div className={'settings-bot-collapse-btn'}>
            <button onClick={this.props.onCollapse}><img src={Chevron} alt={'Desplegar'} /><span className={'settings-bot-title'}>{data.name}</span></button>
          </div>
          <span className={'settings-bot-count'}>{`${arrLength} usuarios`}</span>
          <div className={'settings-bot-actions'}>
            <button onClick={this.props.onEdit}>
              <img src={Edit} alt="Editar" />
            </button>
            <button onClick={this.props.onDelete}>
              <img src={Delete} alt="Eliminar" />
            </button>
          </div>
        </div>
        <Collapse in={collapse && collapsedId === data.id} unmountOnExit>
          <div className={'settings-bot-collapse'}>
            {xhr ? <Loader /> :
              <AddTags
                bots
                options={options}
                botTag
                data={users}
                input={input}
                onChange={this.props.onChange}
                onAdd={this.props.onAdd}
                onDelete={this.props.onDeleteTag}
                onSelectChange={this.props.onSelectChange}
              />
            }
          </div>
        </Collapse>
      </div>
    )
  }
}

export default SettingsBotRow
