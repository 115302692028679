import urls from "../../lib/urls";
import axios from "axios";

export const USER_LOGIN = 'USER_LOGIN'
export const USER_LOGIN_FAILED = 'USER_LOGIN_FAILED'
export const USER_LOGOUT = 'USER_LOGOUT'
export const USER_LOGIN_THROTTLED = 'USER_LOGIN_THROTTLED'
export const USER_LOGIN_OTP_VERIFY = "USER_LOGIN_OTP_VERIFY"
export const USER_LOGIN_OTP_EXPIRED = "USER_LOGIN_OTP_EXPIRED"
export const USER_LOGIN_EXPIRED = "USER_LOGIN_EXPIRED"

export const USER_LOGIN_OTP_FAILED = "USER_LOGIN_OTP_FAILED"
export const USER_RESETED = "USER_RESETED"

export const LOGIN_STATUS_INIT = "INIT"
export const LOGIN_STATUS_SUCCESS = "SUCCESS"
export const LOGIN_STATUS_VERIFY = "VERIFICATE"
export const LOGIN_STATUS_VERIFY_EXPIRED = "CLEANED"
export const LOGIN_STATUS_VERIFY_FAILED = "FAILED"
export const LOGIN_STATUS_EXPIRED = "EXPIRED"

export function loginUser(username, password) {
  return (dispatch) => {
    const login_url = `${urls.API_ROOT}/api/v1/login/`
    axios.post(login_url, { email: username, password, login_status: LOGIN_STATUS_INIT }, { withCredentials: true })
      .then((response) => {
        const { login_status } = response.data;
        if (login_status === LOGIN_STATUS_SUCCESS)
          dispatch(userLogin(response.data));
        else if (login_status === LOGIN_STATUS_VERIFY) {
          dispatch(userLoginOTPVerify());
        } else {
          console.log("LOGIN_STATUS:", login_status)
          dispatch(userLoginFailed());
        }
      })
      .catch((error) => {
        console.log(error.response.data)
        if (error && error.response && error.response.status === 401 && error.response.data.login_status === LOGIN_STATUS_EXPIRED) {
          const { link } = error.response.data;
          dispatch(userLoginExpired({ url:link }));
        }        
        if (error && error.response && error.response.status === 400) {            
          dispatch(userLoginFailed());
        } else if (error && error.response && error.response.status === 429) {
          dispatch(userLoginThrottled());
        } else {
          console.log(error)
        }
        
      })
  };
}
export function resetUser() {
  return (dispatch) => {
    dispatch(userReseted());
  };

}

export function sendOTP(otp) {
  return (dispatch) => {
    const verify_otp_url = `${urls.API_ROOT}/api/v1/login/`
    axios.post(verify_otp_url, { otp: otp, login_status: LOGIN_STATUS_VERIFY }, { withCredentials: true })
      .then((response) => {
        const { login_status } = response.data;
        if (login_status === LOGIN_STATUS_SUCCESS) {
          dispatch(userLogin(response.data));
        } else {
          console.log("OTP LOGIN STATUS UNKNOWN:", login_status);
          dispatch(userLoginOTPFailed());
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.status === 417) {
          const { login_status } = error.response.data;
          if (login_status === LOGIN_STATUS_VERIFY_EXPIRED) {
            dispatch(userLoginOTPExpired());
          } else if (login_status === LOGIN_STATUS_VERIFY_FAILED) {
            dispatch(userLoginOTPFailed());
          } else {
            console.log("OTP VERIFY ERROR:", error)
          }
        }
      })
  }
}

export function userReseted() {
  return {
    type: USER_RESETED
  }

}

export function userLogin(payload) {
  return {
    type: USER_LOGIN,
    payload: payload
  };
}

export function userLoginFailed() {
  return {
    type: USER_LOGIN_FAILED
  }
}

export function userLogout() {
  return {
    type: USER_LOGOUT
  }
}

export function userLoginThrottled() {
  return {
    type: USER_LOGIN_THROTTLED
  }
}

export function userLoginOTPVerify() {
  return {
    type: USER_LOGIN_OTP_VERIFY
  }
}

export function userLoginExpired(payload) {
  return {
    type: USER_LOGIN_EXPIRED,
    payload: payload
  }
}

export function userLoginOTPExpired() {
  return {
    type: USER_LOGIN_OTP_EXPIRED
  }
}

export function userLoginOTPFailed() {
  return {
    type: USER_LOGIN_OTP_FAILED
  }
}