const callColumns = [{
    dataField: 'callEndDate',
    text: 'Gestionado',
    isCustom: true,
    headerStyle: () => {
        return {width: '10%'}
    },
    style: {
        width: '10%',
        borderLeft: 'none'
    }
}, {
    dataField: 'uuid',
    text: 'ID Caso',
    isCustom: false,
    path: 'case_uuid',
    headerStyle: () => {
        return {width: '7%'};
    },
    style: {
        width: '7%',
        color: '#00124B',
    }
},{
    dataField: 'phone',
    text: 'Teléfono',
    isCustom: false,
    path: 'phone',
    headerStyle: () => {
        return { width: '12%' };
    },
    style: {
        width: '12%',
        fontWeight: '500',
        color: '#00124B'
    }
}, {
    dataField: 'calls',
    text: 'Llamada',
    isCustom: true,
    headerStyle: () => {
        return {width: '7%'};
    },
    style: {
        width: '7%'
    }
}, {
    dataField: 'result',
    path: 'result',
    text: 'Estado',
    isCustom: true,
    headerStyle: () => {
        return {width: '10%'};
    },
    style: {
        width: '10%'
    }
}];

export default callColumns;