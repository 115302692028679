import React, {Component} from 'react'
import Download from '../../assets/download.png'
import Cross from '../../assets/red.png'
import './CustomDropzone.scss'
import Dropzone from 'react-dropzone'
import urls from "../../lib/urls";
import { FormattedMessage } from 'react-intl'
import Info from '../../assets/info.png';

class CustomDropzone extends Component {

    constructor(props){
        super(props);
        const { type } = this.props;
        //Determine weather we are uploading exlusions or cases
        if(type === 'new-campaign'){
            this.state = {dZoneText: 'casos'};
        } else {
            this.state = {dZoneText: 'exclusiones'};
        }
         this.state = { sizeFileNameInvalid: false };

    }

    
    render() {
        let {file, fileInvalid, from, type,onDrop} = this.props

        const handleDrop = (files) => {
            files.forEach(file => {
                if (file.name.length > 100) {
                  this.setState({ sizeFileNameInvalid: true });
                  setTimeout(() => {
                      this.setState({ sizeFileNameInvalid: false });
                  }, 5000);
                  return;
                }else{
                  this.setState({ sizeFileNameInvalid: false });
                  onDrop(files);
                }
            });
        }; 
        return (
            <div className={`dropzone-container ${from}`}>
                <div className={'dropzone-top'}>
                  <div className={'dropzone-flex-start'}>
                    <label>
                      <FormattedMessage id="newcamp.upload"/>
                      {/* Subir archivo */}
                    </label>
                    <div className={'tooltip'}>
                      <img className={'img'} src={Info} alt="Info" />
                      <span className={'tooltiptext'}>
                        <FormattedMessage id="newcamp.error.file.100"/>
                      </span>
                    </div>
                  </div>
                  <a href={`${urls.API_ROOT}/static/${type === 'new-campaign' ? 'import_campaigns.xlsx' : 'exclusion_codes.xlsx'}`} target={'_blank'}>
                    <FormattedMessage id="newcamp.planilla"/>
                    {/* Planilla modelo  */}
                    (.xlsx) 
                    <img src={Download} alt={'Descargar'}/>
                  </a>
                </div>
                {file.length !== 0 ? (
                    <div className={'file-container'}>
                        <img className={'file-cross'} src={Cross} alt={'Borrar'} onClick={this.props.onDelete}/>
                        <span>{file[0][0].name}</span>
                    </div>
                ): (
                    <Dropzone
                        onDrop={handleDrop}
                        maxSize={110000000}
                        /*accept={'application/octet-stream'}application/vnd.openxmlformats-officedocument.spreadsheetml.sheet*/
                    >
                        {({getRootProps, getInputProps, isDragActive}) => {
                            return (
                                <div
                                    {...getRootProps()}
                                    className={`dropzone ${from}`}
                                >
                                    <input {...getInputProps()}/>
                                    {
                                        isDragActive 
                                          ?
                                          <span>
                                            <FormattedMessage id="newcamp.soltar"/>
                                            {/* Drop files here... */}
                                          </span> :
                                          <span>
                                            <FormattedMessage id="newcamp.arrastrar"/>
                                            {/* Arrastre aquí el archivo de */}
                                            <br/>{type === 'new-campaign' ? 'casos' : 'exclusiones'}
                                          </span>
                                    }
                                </div>
                            )
                        }}
                    </Dropzone>
                )}
                <label className={'error'}>
                  { fileInvalid && !this.state.sizeFileNameInvalid && <FormattedMessage id="newcamp.error.drop"/>}
                  { this.state.sizeFileNameInvalid && <FormattedMessage id="newcamp.error.file.longname"/>} 
                </label>
            </div>
        )
    }
}

export default CustomDropzone