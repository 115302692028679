import React, { Component } from 'react'
import './MsftLandingPage.scss'
import { Link, withRouter } from 'react-router-dom'
import InitialNavbar from '../../components/InitialNavbar'
import CustomInput from '../../components/CustomInput'
import CustomBottom from '../../components/CustomButton'
import validators from '../../lib/validators'
import Profile from '../../assets/profile.png'
import Email from '../../assets/email.png'
import { loginMsUser, getMSAuthUrl } from '../../modules/msal/actions';
import { resolvePurchaseToken, updateMsAccount } from '../../modules/msmarket/actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Loader from '../../components/Loader';
import { parse } from "qs";
import { Alert } from '@material-ui/lab';

class MsftLandingPage extends Component {
  constructor() {
    super()

    this.state = {
      errorDescription: '',
      confirmationStep: false,
      firstName: '',
      lastName: '',
      email: '',
      error: false,
      xhr: false,
      isChattigo:false,
      isPelikanus:false
    }

    this.VALID_TOKEN = "Subscripción validada con éxito! A continuación puede verificar los detalles de la misma.";
    this.VALID_ACCOUNT = "Hemos registrado su cuenta con éxito, por favor corrobore sus datos y haga click en Finalizar";
  }

  validateField = () => {
    let invalid = false
    if (validators.general(this.state.firstName)) {
      this.setState({
        usernameInvalid: true
      })
      invalid = true
    }
    if (validators.general(this.state.lastName)) {
      this.setState({
        usernameInvalid: true
      })
      invalid = true
    }
    if (validators.email(this.state.email)) {
      this.setState({
        emailInvalid: true
      })
      invalid = true
    }
    return invalid
  }

  handleClick = e => {
    e.preventDefault()
    if (!this.validateField()) {
      this.props.updateMsAccount(this.state.firstName, this.state.lastName, this.state.email);
      this.setState({ xhr: true })
    }
  }

  handleChange = (target) => {
    const { name, value } = target
    this.setState({
      [name]: value
    })
  }

  handleLoginMs = e => {
    e.preventDefault();
    const { subscription } = this.props.msMarket;
    if (subscription && subscription.oauth) {
      window.location.replace(`${subscription.oauth.ms_login_url}`);
    }
  }

  handleFocus = () => {
    this.setState({
      usernameInvalid: false,
      emailInvalid: false,
      error: false
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.msAuth !== this.props.msAuth) {
      this.setState({ xhr: false })
    }
    if (prevProps.msMarket !== this.props.msMarket) {
      if (this.props.msMarket.hasOwnProperty("error") || this.props.msMarket.hasOwnProperty("tokenSuccess") || this.props.msMarket.hasOwnProperty("subscriptionCreated") || this.props.msMarket.hasOwnProperty("canLogin")) {
        if (this.props.msMarket.hasOwnProperty("canLogin") && !this.props.msMarket.canLogin) {
          this.props.history.push("/")
        } else {
          this.props.loginMsUser();
          this.props.history.push("/");
        }
      } else {
        this.setState({ xhr: false })
      }
    }
    if (prevProps.user !== this.props.user && this.props.user !== null && this.props.user.hasOwnProperty('userLoginFailed')) {
      this.setState({
        usernameInvalid: true,
        emailInvalid: true,
        error: true,
        xhr: false
      })
    }
  }

  componentDidMount() {
    const { token, msRedirect, first_name, last_name, email } = parse(this.props.location.search, { ignoreQueryPrefix: true });
    const Url = window.location.hostname;
     if (Url==='chattigo.inceptia.ai') {
     this.setState({isChattigo:true}) 
    }  
    if (Url==='pelikanus.inceptia.ai') {
     this.setState({isPelikanus:true})
    }
    let xhr = false;
    if (token) {
      xhr = true;
      this.props.resolvePurchaseToken(token)
    } else if (msRedirect) {
      this.setState({
        firstName: first_name,
        lastName: last_name,
        email: email,
        confirmationStep: true
      })
    }
    if (xhr) {
      this.setState({ xhr: true })
    }
  }

  render() {
    const { subscription } = this.props.msMarket;
    const { confirmationStep, firstName, lastName, email, isChattigo, isPelikanus } = this.state;
    return (
      <div className={'login-page-container'}>
        <InitialNavbar />
        <div className={'login-page'}>
          {this.state.xhr ? (
            <Loader />
          ) : (
            <div className={'login'}>
              <span>{"ACTIVAR SUBSCRIPCIÓN"}</span>
              <Alert severity={"success"}>
                {confirmationStep ? this.VALID_ACCOUNT : this.VALID_TOKEN}
              </Alert>
              {!confirmationStep ? (
                <>
                  <div className={'inputs'}>
                    <div className={'subscription-labels-container'}>
                      <span className={"subscription-label"}>{"Subscripción SaaS:"}</span>
                      <span>{subscription ? `${subscription.offer}` : ""}</span>
                    </div>
                    <div className={'subscription-labels-container'}>
                      <span className={"subscription-label"}>{"Plan adquirido:"}</span>
                      <span>{subscription ? `${subscription.plan}` : ""}</span>
                    </div>
                    <Alert severity={"info"}>
                      {"Para continuar con la activación, por favor ingrese con su cuenta de Microsoft"}
                    </Alert>
                  </div>
                  <CustomBottom
                    isChattigo={isChattigo}
                    isPelikanus={isPelikanus}
                    className={'login-btn'}
                    text={'Ingresar'}
                    onClick={this.handleLoginMs}
                    onChange={this.handleChange}
                  />
                </>) : (
                <>
                  <div className={'inputs'}>
                    <CustomInput
                      type={'text'}
                      name={'firstName'}
                      placeholder={'Nombre'}
                      value={firstName}
                      onChange={this.handleChange}
                      invalid={this.state.usernameInvalid}
                      onFocus={this.handleFocus}
                      img={Profile}
                    />
                    <CustomInput
                      type={'text'}
                      name={'lastName'}
                      placeholder={'Apellido'}
                      value={lastName}
                      onChange={this.handleChange}
                      invalid={this.state.usernameInvalid}
                      onFocus={this.handleFocus}
                      img={Profile}
                    />
                    <CustomInput
                      type={'text'}
                      name={'email'}
                      placeholder={'Correo Electrónico'}
                      value={email}
                      onChange={this.handleChange}
                      invalid={this.state.emailInvalid}
                      onFocus={this.handleFocus}
                      img={Email}
                    />
                  </div>
                  <CustomBottom
                    isChattigo={isChattigo}
                    isPelikanus={isPelikanus}
                    className={'login-btn'}
                    text={'Finalizar'}
                    onClick={this.handleClick}
                    onChange={this.handleChange}
                  />
                </>
              )}
            </div>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user,
    msAuth: state.msal,
    msMarket: state.msmarket,
    cookies: ownProps.cookies,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ loginMsUser, getMSAuthUrl, resolvePurchaseToken, updateMsAccount }, dispatch);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MsftLandingPage))
