import React, {Component} from 'react'
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core";

const styles = {
  root: {
    position: "absolute",
    top: "calc(50% - 20px)",
    left: "calc(50% - 20px)",
    color: "#0066CA",
    transform: "scale(0.6)",
    animation: 'none',
    zIndex: 1400
  }
};

class Loader extends Component {
  render() {
    let {classes} = this.props
    return (
      <CircularProgress className={classes.root}/>
    );
  }
}

export default withStyles(styles)(Loader)