import axios from 'axios';
import helpers from './helpers';

const instance = axios.create();
instance.CancelToken = axios.CancelToken;
instance.isCancel = axios.isCancel;

const getItem = () => {
  const item = JSON.parse(localStorage.getItem('activeUser'));
  return (item)? item.language : 'es';
};

instance.interceptors.request.use(
  config => {
    config.headers['Accept-Language'] = getItem(); 
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use((response) => {
  return response;
}, function (error) {
  console.log('axios', error);

  if(error.toJSON().message === 'Network Error'){
    return Promise.reject(error);
  }
  const { status } = error.response;
  if (status === 401) helpers.handleTokenExpiration();
  return Promise.reject(error);

});

export { instance };