import React, { Component, Fragment } from "react";
import Status from "../Status";
import SingleStat from "../../components/SingleStat";
import DoubleStat from "../../components/DoubleStat";
import helpers from "../../lib/helpers";

import Stop from "../../assets/stop.svg";
import Play from "../../assets/play.svg";
import Pause from "../../assets/pause.svg";
import Phone from "../../assets/phone-santander.png";
import Edit from "../../assets/small-config-santander.png";
import Calendar from "../../assets/calendar-santander.png";
import PhonePink from "../../assets/phone-pink.png";
import Duration from "../../assets/duration-santander.png";
import PlayHover from "../../assets/play-hover-santander.png";
import StopHover from "../../assets/stop-hover-santander.png";
import PauseHover from "../../assets/pause-hover-santander.png";
import PinkCalendar from "../../assets/calendar-santander.png";

import "./Stats.scss";
import formatters from "../../lib/formatters";
import Skeleton from "react-loading-skeleton";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import { FormattedMessage } from "react-intl";
import { includesWhatsappString } from "../../lib/whatsappChecker";

class Stats extends Component {
  state = {
    hover: false,
    hoverBtn: "",
    modal: false,
    deleteModal: false,
  };

  handleHover = (btn) => {
    this.setState({ hover: true, hoverBtn: btn });
  };

  handleLeave = () => {
    if (this.state.hover) this.setState({ hover: false });
  };

  handleConfirm = () => {
    this.setState({
      deleteModal: true,
    });
  };

  handleClose = () => {
    this.setState({
      modal: false,
      deleteModal: false,
    });
  };

  handleAccept = () => {
    this.props.onSelect("end");
    this.handleClose();
  };

  canStart = (status) =>
    status === helpers.campaignStatus.paused ||
    status === helpers.campaignStatus.scheduled;

  canBePaused = (status) => status === helpers.campaignStatus.onGoing;

  canBeClosed = (status) =>
    status === helpers.campaignStatus.onGoing ||
    status === helpers.campaignStatus.paused;

  render() {
    let {
      type,
      status,
      commitment,
      contacted,
      notContacted,
      duration,
      failed,
      statusList,
      xhr,
      conversation_type,
    } = this.props;
    let { hover, hoverBtn, deleteModal } = this.state;
    let calendar = Calendar;
    let color;

    if (type !== "campaign") {
      if (commitment) {
        try {
          commitment = formatters.dateTimeFormatter(commitment);
        } catch (e) {}
      } else {
        calendar = PinkCalendar;
        color = "pink";
      }
    }

    return (
      <div className={"stats-container"}>
        {type === "campaign" && (
          <div className={"campaign-stats"}>
            <div className={"campaign-stats-editable"}>
              <div className={"campaign-stats-status"}>
                {xhr ? (
                  <Skeleton />
                ) : (
                  status &&
                  statusList && (
                    <Status
                      status={status}
                      size={"big"}
                      statusList={statusList}
                      from={"campaign"}
                      xhr={xhr}
                      conversation_type={conversation_type}
                    />
                  )
                )}
              </div>
              <div
                className={"status-control"}
                style={{
                  lineHeight: "30px",
                  fontSize: "30px",
                  display: xhr ? "block" : "flex",
                }}
              >
                {xhr ? (
                  <Skeleton />
                ) : (
                  <Fragment>
                    <button
                      disabled={xhr}
                      onMouseLeave={this.handleLeave}
                      onMouseEnter={() => this.handleHover("start")}
                      onClick={() => this.props.onSelect("start")}
                      className={` start ${
                        !this.canStart(status) && "disabled"
                      }`}
                    >
                      <img
                        src={hover && hoverBtn === "start" ? PlayHover : Play}
                      />
                      <span>
                        <FormattedMessage id="status.start"/>
                        {/* Iniciar */}
                      </span>
                    </button>

                    <button
                      disabled={xhr}
                      onMouseLeave={this.handleLeave}
                      onMouseEnter={() => this.handleHover("pause")}
                      onClick={() => this.props.onSelect("stop")}
                      className={`pause ${
                        !this.canBePaused(status) && "disabled"
                      }`}
                    >
                      <img
                        src={hover && hoverBtn === "pause" ? PauseHover : Pause}
                      />
                      <span>
                        <FormattedMessage id="status.pause"/>
                        {/* Pausar */}
                      </span>
                    </button>
                    <button
                      disabled={xhr}
                      onMouseLeave={this.handleLeave}
                      onMouseEnter={() => this.handleHover("finish")}
                      onClick={() => this.handleConfirm()}
                      className={`finish ${
                        !this.canBeClosed(status) && "disabled"
                      }`}
                    >
                      <img
                        src={hover && hoverBtn === "finish" ? StopHover : Stop}
                      />
                      <span>
                        <FormattedMessage id={includesWhatsappString(conversation_type)?"status.detain" :"status.end"}/>
                        {/* Finalizar/Detener */}
                      </span>
                    </button>
                  </Fragment>
                )}
                {deleteModal && (
                  <ConfirmationModal
                    modal={deleteModal}
                    onClose={this.handleClose}
                    onConfirm={this.handleAccept}
                    type={"confirmCampaign"}
                    conversation_type={conversation_type}
                  />
                )}
              </div>

              <div
                className={`campaign-stats-edit ${
                  status === helpers.campaignStatus.closed ? "disabled" : ""
                }`}
              >
                <button
                  onClick={
                    status !== helpers.campaignStatus.closed
                      ? this.props.onOpenModal
                      : () => {}
                  }
                  disabled={xhr}
                >
                  <FormattedMessage id="status.edit"/>
                  {/* Editar  */}
                  
                  <img src={Edit} alt={"Editar"} />
                </button>
              </div>
            </div>
            <div className={"campaign-stats-show"}>
              <Fragment>
                <SingleStat
                  data={commitment}
                  img={Calendar}
                  name={"Compromiso"}
                  xhr={xhr}
                />
                <DoubleStat
                  contacted={contacted}
                  notContacted={notContacted}
                  firstImg={Phone}
                  secondImg={PhonePink}
                  firstName={"Contactado"}
                  secondName={"No contactado"}
                  xhr={xhr}
                />
                <SingleStat
                  xhr={xhr}
                  data={duration}
                  img={Duration}
                  name={"Gestión promedio"}
                  type={"duration"}
                />
              </Fragment>
            </div>
          </div>
        )}
        {type === "case" && (
          <div className={"case-stats"}>
            <div className={"case-stats-show"}>
              <SingleStat
                data={commitment}
                img={calendar}
                name={"Compromiso"}
                from={"case"}
                color={color}
                xhr={xhr}
              />
              <DoubleStat
                contacted={contacted}
                notContacted={failed}
                firstImg={Phone}
                secondImg={PhonePink}
                firstName={"Contactado"}
                secondName={"Fallido"}
                from={"case"}
                xhr={xhr}
              />
              <SingleStat
                data={duration}
                img={Duration}
                name={"Gestión promedio"}
                type={"duration"}
                from={"case"}
                xhr={xhr}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Stats;
