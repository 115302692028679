import React, { Component, Fragment } from "react";
import _ from 'lodash';

import "./Reporting.scss";

import BotCampaignReportingNavbar from "../BotCampaignReportingNavbar";
import Reporting_general from "./Reporting_general";
import Reporting_detail from "./Reporting_detail";
import CustomDatePicker from "../CustomDatePicker";
import Reporting_custom from "../ReportingCustom";
import { FormattedMessage } from "react-intl";


class Reporting extends Component { 

    constructor(props) {
        super(props);

        this.state = {
            activeTab: "detail"
        }
    }

    handleReportingTab = section => {
        let { activeTab } = this.state;
        //console.log(section, this.state.activeTab)
        if (section === activeTab) {
            return;
        }
        this.setState({ activeTab: section }, () => this.props.onChangeReportingTab(section));
    }

    render() {
        let { reportingData,
            totalreportingData,
            cases,
            casesCount,
            filterDict,
            resultFilterList,
            resultFilter,
            activeFilter,
            fromDate,
            toDate,
            page,
            activeBot,
            search,
            xhr,
            onClick,
            onDateChange,
            onTableChange,
            onReportingCasesResultFilter,
            onExport,
            network_failed,
            handleCloseAlertNetworkError,
            isChattigo,
            isPelikanus,

        } = this.props;
        let { activeTab } = this.state
        // console.log('render', activeTab);
        let filterList = Object.keys(filterDict);
        let dateViews = activeFilter === 'year' ? ["year"] : (activeFilter === 'month' ? ["year", "month"] : ["year", "month", "day"])
        let dateLabel = activeFilter === 'year' ? "Año" : (activeFilter === 'month' ? "Mes y Año" : "Fecha")
        return (
            <div className={"bot-campaign-reporting-container"}>
                <div className={"bot-campaign-reporting"}>
                    <div className={"bot-campaign-reporting-body"}>
                        <div className={"bot-campaign-reporting-filters"}>
                            <BotCampaignReportingNavbar activeTab={activeTab}
                                onClick={this.handleReportingTab} />
                            {activeTab !== "dashboards" &&
                                <Fragment>
                                    <div className={"bot-campaign-reporting-quick-filters"}>
                                        {filterList && filterList.map((filter, i) => (
                                            <Fragment key={i}>
                                                <button className={`${filter} ${activeFilter === filter && "active"}`}
                                                    onClick={() => this.props.onClick(filter, activeTab)}>
                                                    <label>
                                                        <FormattedMessage id={`report.${filterDict[filter]}`} />
                                                    </label>
                                                    {/* {filterDict[filter].toUpperCase()} */}
                                                    {activeFilter === filter ? `(${casesCount})` : ""}
                                                </button>
                                            </Fragment>
                                        ))}
                                    </div>
                                    <div className={"bot-campaign-reporting-filter-helpers"}>
                                        {activeFilter && filterList.map((filter, i) => {
                                            return (filter === 'all' || filter !== activeFilter ? <></> :
                                                <Fragment key={i}>
                                                    <CustomDatePicker
                                                        date={fromDate}
                                                        onDateChange={(date) => this.props.onFromDateChange(date, activeTab)}
                                                        views={dateViews}
                                                        label={<FormattedMessage id="report.from" />}
                                                    />
                                                    <CustomDatePicker
                                                        date={toDate}
                                                        onDateChange={(date) => this.props.onToDateChange(date, activeTab)}
                                                        views={dateViews}
                                                        label={<FormattedMessage id="report.to" />}
                                                    />
                                                </Fragment>
                                            )
                                        })}
                                    </div>
                                </Fragment>
                            }
                        </div>
                        {activeTab === 'general' ?
                            <Reporting_general onClick={onClick}
                                onDateChange={onDateChange}
                                onTableChange={onTableChange}
                                onReportingCasesResultFilter={onReportingCasesResultFilter}
                                onExport={onExport}
                                cases={cases}
                                reportingData={reportingData}
                                totalreportingData={totalreportingData}
                                casesCount={casesCount}
                                filterDict={filterDict}
                                resultFilterList={resultFilterList}
                                activeFilter={activeFilter}
                                resultFilter={resultFilter}
                                activeBot={activeBot}
                                fromDate={fromDate}
                                toDate={toDate}
                                page={page}
                                xhr={xhr}
                                isChattigo={isChattigo}
                                isPelikanus={isPelikanus}
                            /> :
                            activeTab === 'detail' ?
                                <Reporting_detail onClick={onClick}
                                    onDateChange={onDateChange}
                                    onTableChange={onTableChange}
                                    onReportingCasesResultFilter={onReportingCasesResultFilter}
                                    onExport={onExport}
                                    cases={cases}
                                    reportingDataByResult={totalreportingData}
                                    casesCount={casesCount}
                                    filterDict={filterDict}
                                    resultFilterList={resultFilterList}
                                    activeFilter={activeFilter}
                                    resultFilter={resultFilter}
                                    activeBot={activeBot}
                                    fromDate={fromDate}
                                    toDate={toDate}
                                    page={page}
                                    search={search}
                                    xhr={xhr}
                                    network_failed={network_failed}
                                    handleCloseAlertNetworkError={handleCloseAlertNetworkError}
                                    isChattigo={isChattigo}
                                    isPelikanus={isPelikanus}
                                /> :
                            activeTab === 'dashboards' ?
                                <Reporting_custom 
                                  page={page}
                                  xhr={xhr}
                                  activeBot={activeBot}
                                  isChattigo={isChattigo}
                                  isPelikanus={isPelikanus}
                                /> 
                                  :
                                  <></>}
                    </div>
                </div>
            </div>
        );
    }
}

export default Reporting;
