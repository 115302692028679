import urls from "../../lib/urls";
import axios from "axios";
import { tokenHeader } from "../../lib/headers";

export const CAMPAIGN_STATUS_REQUEST = "CAMPAIGN_STATUS_REQUEST";
export const CAMPAIGN_STATUS_SUCCESS = "CAMPAIGN_STATUS_SUCCESS";
export const CAMPAIGN_STATUS_FAILURE = "CAMPAIGN_STATUS_FAILURE";

// INBOUND
export const INBOUND_CASE_STATUS_REQUEST = "INBOUND_CASE_STATUS_REQUEST";
export const INBOUND_CASE_STATUS_SUCCESS = "INBOUND_CASE_STATUS_SUCCESS";
export const INBOUND_CASE_STATUS_FAILURE = "INBOUND_CASE_STATUS_FAILURE";

export function fetchCampaignStatuses() {
  return async (dispatch) => {
    const campaignStatusesUrl = `${urls.API_ROOT}/api/v1/status/?campaigns`;
    try {
      dispatch(campaignStatusesRequest());
      const response = await axios.get(campaignStatusesUrl, tokenHeader());
      const { data: statusList } = response;
      let ui = {
        campaignStatus: statusList.reduce((acc, currentStatus) => {
          return {
            ...acc,
            [currentStatus.value]: {
              status: currentStatus.status,
              value: currentStatus.value,
            },
          };
        }, {}),
      };
      dispatch(campaignStatusesSuccess(ui));
    } catch (error) {
      dispatch(campaignStatusesFailure());
    }
  };
}

export function campaignStatusesRequest() {
  return {
    type: CAMPAIGN_STATUS_REQUEST,
  };
}

export function campaignStatusesSuccess(payload) {
  return {
    type: CAMPAIGN_STATUS_SUCCESS,
    payload: payload,
  };
}

export function campaignStatusesFailure() {
  return {
    type: CAMPAIGN_STATUS_FAILURE,
  };
}

export function fetchInboundCaseStatuses() {
  return async (dispatch) => {
    const inboundCasesStatusesUrl = `${urls.API_ROOT}/api/v1/inbound-case/status/`;
    try {
      dispatch(inboundCaseStatusesRequest())
      const response = await axios.get(inboundCasesStatusesUrl, tokenHeader());
      const { data: statusList } = response;
      let ui = {
        inboundCasesStatus: statusList.reduce((acc, currentStatus) => {
          return { ...acc, [currentStatus.value]: { status: currentStatus.status, value: currentStatus.value } };
        }, {})
      };
      dispatch(inboundCaseStatusesSuccess(ui));
    } catch (error) {
      dispatch(inboundCaseStatusesFailure());
    }
  };
}

export function inboundCaseStatusesRequest() {
  return {
    type: INBOUND_CASE_STATUS_REQUEST
  }
}

export function inboundCaseStatusesSuccess(payload) {
  return {
    type: INBOUND_CASE_STATUS_SUCCESS,
    payload: payload
  };
}

export function inboundCaseStatusesFailure() {
  return {
    type: INBOUND_CASE_STATUS_FAILURE
  };
}