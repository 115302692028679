import React, { Component } from "react";
import { withRouter } from "react-router";

import { storeCases } from "../../modules/cases/actions";
import { storeDetails } from "../../modules/details/actions";

import CustomModal from "../../components/CustomModal";
import Breadcrumbs from "../../components/Breadcrumbs";
import "./CampaignPage.scss";
import CampaignInfo from "../../components/CampaignInfo";
import Stats from "../../components/Stats";
import Table from "../../components/Table";
import { instance as axios } from "../../lib/axios";
import Filters from "../../components/Filters";
import Loader from "../../components/Loader";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import validators from "../../lib/validators";
import { tokenHeader } from "../../lib/headers";
import urls from "../../lib/urls";
import _, { isEmpty } from "lodash";
import moment from "moment";
import { connect } from "react-redux";
import { isIE } from "react-device-detect";
import { bindActionCreators } from "redux";
import { Alert, AlertTitle } from '@material-ui/lab';


const state = {
  modal: false,
  saved: false,
  selectedCheckboxes: [],
  checkAll: false,
  importCases: false,
  imported: false,
  file: [],
  fileIsValid: true,
  casesFound: {},
  deleteCampaignModal: false,
  timeInvalid: false,
  deleteCaseModal: false,
  comingSoon: false,
  taskId: null,
  processedWithErrors: false,
  receivedWithErrors: false,
  fileToExport: null,
  addNewCase: false,
  nameInput: {
    name: "nameInput",
    value: "",
    isValid: true,
    validator: "general",
  },
  surnameInput: {
    name: "surnameInput",
    value: "",
    isValid: true,
    validator: "general",
  },
  idInput: { name: "idInput", value: "", isValid: true, validator: "general" },
  productInput: {
    name: "productInput",
    value: "",
    isValid: true,
    validator: "general",
  },
  debtInput: {
    name: "debtInput",
    value: "",
    isValid: true,
    validator: "general",
    rawValue: "",
  },
  phoneInput: {
    name: "phoneInput",
    value: "",
    isValid: true,
    validator: "phone",
    displayValue: "",
  },
  caseAdded: false,
  closes_eod: false,
  network_failed: false,
  isChattigo: false,
  isPelikanus:false,
};

class CampaignPage extends Component {
  constructor() {
    super();

    this.intervalId = 0;
    this.state = {
      ...state,
      id: null,
      xhr: false,
      cases: null,
      casesCount: 0,
      bot: null,

      title: null,
      description: null,
      botId: null,
      status: "",
      percentage_completed: null,
      conversation_type: '',
      percentage_called: null,
      from: "09:00",
      to: "21:00",
      date: null,
      maxCalls: 2,
      selected: "now",
      showDatePicker: false,
      axiosSource: null,
      page: 1,
      filter: "Compromete Fecha",
      statusList: null,

      modalXhr: false,
      loadingCase: false,
      loadingDetails: false,
      loadingCampaign: false,
      isLoadingPage: true,
    };
  }



  handleConfirmDeleteCases = async (e) => {
    e.preventDefault();
    const { id, selectedCheckboxes } = this.state;
    this.setState({ xhr: true, deleteCaseModal: false });
    this.startRefreshPolling();
    const deleteCasesUrl = `${urls.API_ROOT}/api/v1/campaigns/${id}/cases/`;
    try {
      await axios.delete(deleteCasesUrl, {
        ...tokenHeader(),
        data: { ids: selectedCheckboxes },
      });
      await this.fetchCases();
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ xhr: false });
      await this.handleCancel();
    }
  };

  handleDeleteCampaign = async (e) => {
    e.preventDefault();
    const { id } = this.state;
    this.setState({ xhr: true, deleteCampaignModal: false });
    this.startRefreshPolling();
    const deleteCampaignUrl = `${urls.API_ROOT}/api/v1/campaigns/${id}/`;
    try {
      await axios.delete(deleteCampaignUrl, { ...tokenHeader() });
      this.props.history.push({
        pathname: "/",
        state: {
          prevPath: this.props.location.pathname,
          botId: this.state.botId,
        },
      });
    } catch (error) {
      console.log(error);
      this.setState({ xhr: false });
      await this.handleClose();
    }
  };

  handleCampaignUpdate = async () => {
    const {
      id,
      botId: bot_id,
      title: name,
      maxCalls: max_calls_per_case,
      from: call_from,
      to: call_to,
      date,
      closes_eod,
    } = this.state;
    const campaignUpdateUrl = `${urls.API_ROOT}/api/v1/campaigns/${id}/`;
    const start_date = moment(date).format("YYYY-MM-DD");
    let data = {
      max_calls_per_case,
      call_from,
      call_to,
      start_date,
      bot_id: bot_id,
      name,
      closes_eod,
    };
    if (date === null) {
      data = { max_calls_per_case, call_from, call_to, bot_id: bot_id, name };
    }
    this.setState({ modalXhr: true });
    try {
      const res = await axios.put(campaignUpdateUrl, data, tokenHeader());
      this.setState({ saved: true });
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ modalXhr: false });
    }
  };

  handleSave = async (e) => {
    e.preventDefault();
    const { timeInvalid } = this.state;

    if (!timeInvalid) {
      this.setState({ xhr: true });
      await this.handleCampaignUpdate();
    }
  };

  handleTimeChange = (val) => {
    const from = val.startTime.label;
    const to = val.endTime.label;
    this.setState({ from, to, timeInvalid: from >= to || to <= from });
  };

  handleTimeError = (error) => {
    this.setState({ timeInvalid: error });
  };

  handleAdd = (e) => {
    e.preventDefault();
    if (this.state.maxCalls === 9) {
      return;
    }
    let newMax = this.state.maxCalls + 1;
    this.setState({
      maxCalls: newMax,
    });
  };

  handleRest = (e) => {
    e.preventDefault();
    if (this.state.maxCalls === 1) {
      return;
    }
    let newMax = this.state.maxCalls - 1;
    this.setState({
      maxCalls: newMax,
    });
  };

  handleChange = (target) => {
    const { name, value } = target;
    if (name === "debtInput") {
      const debt = value.trim().split(".").join("");
      const debtWithoutComma = debt.replace(",", ".");
      this.setState(
        (prevState) => ({
          [name]: {
            ...prevState[name],
            rawValue: debtWithoutComma,
            value: debt,
          },
        }),
        () => this.handleValidation(name)
      );
    } else if (name === "phoneInput") {
      let phoneNumber = value.trim();
      const symbols = [" ", "-", "(", ")", "_"];
      const reducedNumber = symbols.reduce(
        (accumulator, currentValue) => accumulator.split(currentValue).join(""),
        phoneNumber
      );
      const flattenedPhoneNumber = reducedNumber.substring(1);
      this.setState(
        (prevState) => ({
          [name]: {
            ...prevState[name],
            displayValue: value,
            value: flattenedPhoneNumber,
          },
        }),
        () => this.handleValidation(name)
      );
    } else {
      this.setState(
        (prevState) => ({
          [name]: { ...prevState[name], value },
        }),
        () => this.handleValidation(name)
      );
    }
  };



  handleValidation = (name) => {
    let input = this.state[name];
    const validator = validators[input.validator];
    const isValid = !validator(input.value);

    if (isValid)
      this.setState((prevState) => ({
        [name]: { ...prevState[name], isValid },
      }));
  };

  handleValidations = () => {
    const {
      nameInput,
      surnameInput,
      debtInput,
      idInput,
      productInput,
      phoneInput,
    } = this.state;
    const inputs = [
      nameInput,
      surnameInput,
      idInput,
      productInput,
      debtInput,
      phoneInput,
    ];

    const areAllInputsValid = inputs.every((input) => {
      const validator = validators[input.validator];
      const isValid = !validator(input.value);

      this.setState((prevState) => ({
        [input.name]: { ...prevState[input.name], isValid },
      }));

      return isValid;
    });

    return areAllInputsValid;
  };

  handleAddCase = async (e) => {
    e.preventDefault();
    const {
      id,
      nameInput,
      surnameInput,
      debtInput,
      productInput,
      idInput,
      phoneInput,
      date,
    } = this.state;
    const newCaseUrl = `${urls.API_ROOT}/api/v1/campaigns/${id}/cases/`;
    if (this.handleValidations()) {
      this.setState({ modalXhr: true });
      try {
        const data = {
          campaign_id: Number(id),
          code: idInput.value,
          first_name: nameInput.value,
          last_name: surnameInput.value,
          debt: debtInput.rawValue,
          product: productInput.value,
          phone: phoneInput.value,
          expiration: date,
        };
        const res = await axios.post(newCaseUrl, data, tokenHeader());
        this.setState({ caseAdded: true });
      } catch (error) {
        console.log(error);
      } finally {
        this.setState({ modalXhr: false });
      }
    }
  };

  handleModal = (type) => {
    this.setState({
      modal: true,
      importCases: type === "import",
    });
    this.stopRefreshPolling();
  };

  handleClose = () => {
    this.setState({ ...state });
    this.startRefreshPolling();
  };

  handleCancel = async () => {
    this.setState({ ...state });
    await Promise.all([
      this.fetchCampaign(),
      this.fetchCases(),
      this.fetchCampaignDetails(),
    ]);
    this.startRefreshPolling();
  };

  handleBack = (e) => {
    e.preventDefault();
    this.setState({
      ...this.state,
      receivedWithErrors: null,
      file: state.file,
    });
  };

  handleSelect = (val) => {
    let newState = {
      selected: val,
      showDatePicker: val !== "now",
    };
    if (this.state.selected !== "now" && val === "now") {
      newState = {
        date: moment(new Date()).format("YYYY-MM-DD"),
        selected: val,
        showDatePicker: val !== "now",
      };
    }
    this.setState(newState);
  };

  handleTableChange = (type, { page, sizePerPage }) => {
    if (type === "pagination") {
      this.setState({ page }, this.fetchCases);
    }
  };

  handleCheckbox = (id) => {
    let newCheckboxes = [...this.state.selectedCheckboxes];
    if (this.state.checkAll) {
      if (
        newCheckboxes.find((checkboxId) => {
          return checkboxId === id;
        }) !== undefined
      ) {
        newCheckboxes = newCheckboxes.filter((checkboxId) => checkboxId !== id);
      }
      this.setState({
        selectedCheckboxes: newCheckboxes,
        checkAll: false,
      });
    } else {
      if (
        newCheckboxes.find((checkboxId) => {
          return checkboxId === id;
        }) !== undefined
      ) {
        newCheckboxes = newCheckboxes.filter((checkboxId) => checkboxId !== id);
      } else {
        newCheckboxes.push(id);
      }
    }
    this.setState({
      selectedCheckboxes: newCheckboxes,
    });
  };

  handleAllCheckboxes = (allIds) => {
    if (this.state.selectedCheckboxes.length === 0 && !this.state.checkAll) {
      this.setState({
        checkAll: true,
        selectedCheckboxes: allIds,
      });
    } else {
      this.setState({
        checkAll: false,
        selectedCheckboxes: [],
      });
    }
  };

  handleDeleteCase = (e) => {
    e.preventDefault();
    this.setState({
      deleteCaseModal: true,
    });
    this.stopRefreshPolling();
  };

  handleConfirmDeleteCampaign = (e) => {
    e.preventDefault();
    this.setState({
      modal: false,
      deleteCampaignModal: true,
    });
    this.stopRefreshPolling();
  };

  handleTaskStatus = async (status) => {
    switch (status) {
      case "RECEIVED": {
        await this.getTaskStatus("IN_PROCESS");
        break;
      }
      case "IN_PROCESS": {
        await this.getTaskStatus("DONE");
        break;
      }
      case "PROCESSED_WITH_ERRORS": {
        this.setState({ modalXhr: false, processedWithErrors: true });
        break;
      }
      case "DONE": {
        this.setState({ modalXhr: false });
        const { fileToExport } = this.state;
        window.location = `${urls.API_ROOT}${fileToExport}`;
        break;
      }
    }
  };

  getTaskStatus = async (desiredStatus) => {
    const { taskId } = this.state;
    const taskUrl = `${urls.API_ROOT}/api/v1/tasks/${taskId}/`; 
    try {
      const res = await axios.get(taskUrl, tokenHeader());
      const { data } = res;
      const { status } = data;
      let timer;
      if (status === "PROCESSED_WITH_ERRORS") {
        return this.handleTaskStatus(status);
      }
      if (status !== desiredStatus) {
        timer = setTimeout(() => this.getTaskStatus(desiredStatus), 3000);
        return timer;
      }
      clearTimeout(timer);
      return this.handleTaskStatus(status);
    } catch (error) {
      console.log(error);
    }
  };

  handleCloseAlertNetworkError = () => {
    this.setState({ ...state, network_failed: false });
  }

  handleExport = async (e) => {
    e.preventDefault();
    this.setState({ loadingCase: true });
    const tasksUrl = `${urls.API_ROOT}/api/v1/tasks/`;
    const { id, filter, statusList, botId } = this.state;
    let data = { campaign_id: id, type: "EXPORT_CAMPAIGN", bot_key: botId };
    if (filter !== "all") {
      let statuses = _.map(
        statusList.filter((value) => filter.includes(value.value)),
        (status) => {
          return status.value;
        }
      );
      data = {
        ...data,
        filter: {
          case_result__name__in: statuses,
          campaign: id,
          campaign__bot: botId,
        },
      };
    } else {
      data = {
        ...data,
        filter: { campaign: id, campaign__bot: botId },
      };
    }
    try {
      const res = await axios.post(tasksUrl, data, tokenHeader());
      const { status, file } = res.data;
      this.setState(
        { fileToExport: file },
        async () => await this.handleTaskStatus(status)
      );
    } catch (error) {
      if (error.toJSON().message === 'Network Error') {
        this.setState({ ...state, network_failed: true });
      }
      this.setState({ xhr: false });
    } finally {
      this.setState({ loadingCase: false });
    }
  };

  handleDrop = (file) => {
    let stateFile = [...this.state.file];
    stateFile.push(file);

    if (!validators.file(stateFile)) {
      this.setState({
        file: stateFile,
        fileIsValid: true,
      });
    }
  };

  handleDeleteFile = (e) => {
    e.preventDefault();
    this.setState({
      file: [],
    });
  };

  handleImportNewCases = async (e) => {
    e.preventDefault();
    const { taskId } = this.state;
    const campaignCasesUrl = `${urls.API_ROOT}/api/v1/tasks/${taskId}/`;
    this.setState({ modalXhr: true });
    try {
      const res = await axios.put(campaignCasesUrl, {}, tokenHeader());
      const { status } = res.data;
      return this.handleImportStatus(status);
    } catch (error) {
      console.log(error);
    }
  };

  handleImportStatus = async (status, data) => {
    switch (status) {
      case "RECEIVED": {
        await this.getImportStatus("VALIDATED");
        break;
      }
      case "RECEIVED_WITH_ERRORS": {
        this.setState({ modalXhr: false, receivedWithErrors: data });
        break;
      }
      case "VALIDATED": {
        const toUpdate = data.result.to_update.length;
        const toCreate = data.result.to_create.length;
        const casesFound = { toUpdate, toCreate };
        this.setState({ modalXhr: false, casesFound, imported: true });
        break;
      }
      case "IN_PROCESS": {
        await this.getImportStatus("DONE");
        break;
      }
      case "PROCESSED_WITH_ERRORS": {
        this.setState({ modalXhr: false, processedWithErrors: data });
        break;
      }
      case "DONE": {
        this.setState({ saved: true, modalXhr: false });
        break;
      }
    }
  };

  getImportStatus = async (desiredStatus) => {
    const { taskId: id } = this.state;
    const taskUrl = `${urls.API_ROOT}/api/v1/tasks/${id}/`;
    try {
      const res = await axios.get(taskUrl, tokenHeader());
      const { data } = res;
      const { status } = data;
      let mostRecentLog = _.orderBy(data.logs, "created_at", "desc")[0];
      let timer;
      if (
        status === "RECEIVED_WITH_ERRORS" ||
        status === "PROCESSED_WITH_ERRORS"
      ) {
        return this.handleImportStatus(status, mostRecentLog);
      }
      if (status !== desiredStatus) {
        timer = setTimeout(() => this.getImportStatus(desiredStatus), 3000);
        return timer;
      }
      clearTimeout(timer);
      return this.handleImportStatus(status, mostRecentLog);
    } catch (error) {
      console.log(error);
    }
  };

  handleImport = async (e) => {
    e.preventDefault();
    if (validators.file(this.state.file)) {
      this.setState({
        fileIsValid: false,
      });
      return;
    }

    const tasksUrl = `${urls.API_ROOT}/api/v1/tasks/`; // test v2
    this.setState({ modalXhr: true });
    let file = this.state.file[0][0];
    const { id } = this.state;
    const formData = new FormData();
    formData.append("file", file);
    formData.append("type", "IMPORT_CAMPAIGN");
    formData.append("campaign_id", id);
    try {
      const res = await axios.post(tasksUrl, formData, {
        ...tokenHeader(),
        ...{ "Content-Type": "multipart/form-data" },
      });
      const { id, status } = res.data;
      const { data } = res;
      const mostRecentLog = _.orderBy(data.logs, "created_at", "desc")[0];
      this.setState({ taskId: id });
      await this.handleImportStatus(status, mostRecentLog);
    } catch (error) {
      console.log(error);
    }
  };

  handleDateChange = (date) => {
    this.setState({ date: moment(date).format("YYYY-MM-DD") });
  };

  handleNewCase = () => {
    this.setState({
      addNewCase: true,
      modal: true,
    });
    this.stopRefreshPolling();
  };

  handleStatus = async (status) => {
    const { id } = this.state;
    const statusChangeUrl = `${urls.API_ROOT}/api/v1/campaigns/${id}/${status}/`;
    try {
      await axios.post(statusChangeUrl, {}, tokenHeader());
      await this.fetchCampaign();
    } catch (error) {
      console.log(error);
    }
  };

  fetchBot = async (id) => {
    const botUrl = `${urls.API_ROOT}/api/v1/bot-campaign/${id}/`;
    try {
      const res = await axios.get(botUrl, tokenHeader());
      const { data } = res;
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  fetchCampaign = async () => {
    const { id } = this.state;
    const campaignUrl = `${urls.API_ROOT}/api/v1/campaigns/${id}/`;
    const notCachedUrl = `${campaignUrl}?cb=${new Date().getTime()}`;
    const url = isIE ? notCachedUrl : campaignUrl;
    this.setState({ loadingCampaign: true });
    try {
      const res = await axios.get(url, tokenHeader());
      const { data } = res;
      const {
        name,
        description,
        bot,
        status,
        percentage_completed,
        percentage_called,
        conversation_type,
        call_from: from,
        call_to: to,
        start_date: date,
        max_calls_per_case: maxCalls,
        closes_eod
      } = data;
      const today = new Date();
      let selected = "program";
      let showDatePicker = true;
      if (Date.parse(moment(today).format("YYYY-MM-DD")) === Date.parse(date)) {
        selected = "now";
        showDatePicker = false;
      }

      this.setState({
        title: name,
        description,
        botId: bot,
        status,
        percentage_completed,
        percentage_called,
        from,
        to,
        date,
        maxCalls,
        selected,
        showDatePicker,
        closes_eod,
        conversation_type
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ loadingCampaign: false });
    }
  };

  handleClosesEODChange = (closes_eod) => {
    this.setState({ closes_eod: closes_eod })
  }

  fetchCases = async (silent = false) => {
    if (this.state.xhr)
      this.state.axiosSource && this.state.axiosSource.cancel("abort");
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    this.setState({ axiosSource: source, xhr: !silent, loadingCase: true });
    const casesUrl = this.generateNewQuery();
    const notCachedUrl = `${casesUrl}?cb=${new Date().getTime()}`;
    const url = isIE ? notCachedUrl : casesUrl;
    try {
      const res = await axios.get(url, {
        ...tokenHeader(),
        cancelToken: source.token,
      });
      const { results, count } = res.data;
      this.props.storeCases(results);
      this.setState({
        cases: results,
        casesCount: count,
        axiosSource: null,
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ xhr: false, loadingCase: false });
    }
  };

  generateNewQuery = () => {
    const { id, filter, page, statusList } = this.state;
    let url = `${urls.API_ROOT}/api/v1/campaigns/${id}/cases/?`;
    if (page && page > 1) url += `page=${page}&`;

    let statuses = [];
    let gotPromise = false;
    if (filter && filter === "commitments") {
      gotPromise = true;
    } else if (filter && filter !== "all") {
      statuses = _.map(
        statusList.filter((value) => filter.includes(value.value)),
        (status) => {
          return status.value;
        }
      );

      //status = statusList.find(status => status.value === filter).value;
    }

    if (_.isEmpty(statuses)) {
      statuses = ["all"];
    }

    if (gotPromise) {
      url += "got_promise=True&";
    } else if (statuses !== []) {
      url += `result=${_.join(statuses, ",")}&`;
    }

    return url;
  };

  handleFilter = (filter) => {
    this.setState(
      {
        filter: filter,
        page: 1,
      },
      async () => await this.fetchCases()
    );
  };

  fetchStatus = async () => {
    let { id, botId } = this.state;
    const statusUrl = `${urls.API_ROOT
      }/api/v1/status/?calls&cid=${id}&bot=${botId}&cb=${new Date().getTime()}`;
    try {
      const res = await axios.get(statusUrl, tokenHeader());
      const { data: statusList } = res;
      this.setState({ statusList });
    } catch (error) {
      console.log(error);
    }
  };

  fetchCampaignDetails = async () => {
    const { id } = this.state;
    const campaignDetailsUrl = `${urls.API_ROOT}/api/v1/campaigns/${id}/details/`;
    const notCachedUrl = `${campaignDetailsUrl}?cb=${new Date().getTime()}`;
    const url = isIE ? notCachedUrl : campaignDetailsUrl;
    this.setState({ loadingDetails: true });
    try {
      const res = await axios.get(url, tokenHeader());
      const {
        average_management: duration,
        commitment,
        contacted,
        not_contacted: notContacted,
      } = res.data;

      this.props.storeDetails({
        duration,
        commitment,
        contacted,
        notContacted,
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ loadingDetails: false });
    }
  };

  handleRefresh = () => {
    const { status } = this.state;
    if (!this.state.xhr && status !== "CLOSED") {
      this.fetchCampaign();
      this.fetchStatus();
      this.fetchCases(true);
      this.fetchCampaignDetails();
    }
  };

  setPrevFilter = () => {
    if (this.props.location.state) {
      const { prevPath, from, resultFilter } = this.props.location.state;
      if (from === "campaign" && prevPath.includes("/cases/")) {
        this.setState({ filter: resultFilter });
      }
    }
  };

  setPrevPage = () => {
    if (this.props.location.state) {
      const { prevPath, from, page } = this.props.location.state;
      if (from === "campaign" && prevPath.includes("/cases/")) {
        this.setState({ page: page });
      }
    }
  };

  stopRefreshPolling = () => {
    const { intervalId } = this.state;
    clearInterval(this.intervalId);
  };

  startRefreshPolling = () => {
    // interval is in ms, it is equivalent to 
    const TWO_MINUTES = 1000 * 60 * 2
    this.intervalId = setInterval(this.handleRefresh, TWO_MINUTES);
    console.log("the interval", this.intervalId);
    //this.setState({ intervalId: intervalId });
  };



  async componentDidMount() {
    const { id } = this.props.match.params;
    const { id: bot_id, name } = await this.fetchBot(id);
    const Url = window.location.hostname;
    if (Url === 'chattigo.inceptia.ai') {
      this.setState({ isChattigo: true })
    }
    if (Url==='pelikanus.inceptia.ai') {
      this.setState({isPelikanus:true})
    }
    this.setState(
      {
        id: id,
        xhr: true,
        loadingDetails: true,
        botId: bot_id,
        bot: name,
        casesCount: this.props.cases.length,
      },
      async () => {
        this.startRefreshPolling();
        await Promise.all([
          this.setPrevFilter(),
          this.setPrevPage(),
          this.fetchStatus(),
        ]);

        await Promise.all([this.fetchCampaign()]);

        if (isEmpty(this.props.details)) {
          await Promise.all([this.fetchCampaignDetails()]);
        } else {
          this.setState({ loadingDetails: false });
        }

        if (!this.props.cases.length) {
          await Promise.all([this.fetchCases()]);
        } else {
          this.setState({
            xhr: false,
            loadingCase: false,
            cases: this.props.cases,
            axiosSource: null,
          });
        }
      }
    );

    this.setState({ isLoadingPage: false })
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }
  //For compatibility reasons
  clearInput = () => {
    return;
  };

  render() {
    let {
      id,
      bot,
      description,
      title,
      saved,
      maxCalls,
      selected,
      showDatePicker,
      date,
      modal,
      from,
      to,
      percentage_completed,
      percentage_called,
      status,
      conversation_type,

      xhr,
      loadingCase,
      loadingDetails,

      casesCount,
      page,
      filter,
      selectedCheckboxes,
      checkAll,
      importCases,
      file,
      fileIsValid,
      imported,
      casesFound,
      deleteCampaignModal,
      timeInvalid,
      deleteCaseModal,
      comingSoon,
      statusList,
      processedWithErrors,
      receivedWithErrors,
      addNewCase,
      nameInput,
      surnameInput,
      idInput,
      productInput,
      debtInput,
      phoneInput,
      caseAdded,
      modalXhr,
      botId,
      network_failed,
      isChattigo,
      isPelikanus,
      isLoadingPage,
      closes_eod,
    } = this.state;
    const { cases } = this.props;
    const { duration, commitment, contacted, notContacted } =
      this.props.details;

    if (
      this.props.location !== undefined &&
      this.props.location.state !== undefined
    ) {
      var { activeFilter, activeSection, search,timezone } = this.props.location.state;
    } else {
      var activeFilter, activeSection, search,timezone;
    }
    const { ui, isLoading } = this.props.ui;
    return (
      <div className={"campaign-page-container"}>
        {isLoadingPage ? (
          <Loader />
        ) : (
          <div className={"campaign-page"}>
            {importCases ? (
              <CustomModal
                clearInput={this.clearInput}
                modal={modal}
                backdropDisabled
                type={"import-cases"}
                confirmed={saved}
                onCancel={this.handleCancel}
                onConfirm={this.handleImportNewCases}
                file={file}
                onClick={this.handleImport}
                onDrop={this.handleDrop}
                onDeleteFile={this.handleDeleteFile}
                fileIsValid={fileIsValid}
                imported={imported}
                casesFound={casesFound}
                receivedWithErrors={receivedWithErrors}
                processedWithErrors={processedWithErrors}
                onBack={this.handleBack}
                xhr={modalXhr}
                onClose={this.handleClose}
              />
            ) : addNewCase ? (
              <CustomModal
                clearInput={this.clearInput}
                modal={modal}
                backdropDisabled
                type={"add-new-case"}
                onDateChange={this.handleDateChange}
                date={date}
                id={idInput.value}
                idIsValid={idInput.isValid}
                name={nameInput.value}
                nameIsValid={nameInput.isValid}
                surname={surnameInput.value}
                surnameIsValid={surnameInput.isValid}
                product={productInput.value}
                productIsValid={productInput.isValid}
                debt={debtInput.value}
                debtIsValid={debtInput.isValid}
                phone={phoneInput.value}
                phoneIsValid={phoneInput.isValid}
                onChange={this.handleChange}
                onClick={this.handleAddCase}
                caseAdded={caseAdded}
                xhr={modalXhr}
                onClose={this.handleClose}
                onCancel={this.handleCancel}
              />
            ) : (
              <CustomModal
                clearInput={this.clearInput}
                modal={modal}
                backdropDisabled
                type={"edit-campaign"}
                title={title}
                description={description}
                onChange={this.handleChange}
                confirmed={saved}
                onCancel={this.handleCancel}
                cases={casesCount}
                maxCalls={maxCalls}
                onAdd={this.handleAdd}
                onRest={this.handleRest}
                from={from}
                to={to}
                onError={this.handleTimeError}
                status={status}
                onTimeChange={(value) => this.handleTimeChange(value)}
                onConfirm={(error) => this.handleSave(error)}
                onSelect={this.handleSelect}
                selected={selected}
                showDatePicker={showDatePicker}
                onDateChange={this.handleDateChange}
                date={date}
                onDelete={this.handleConfirmDeleteCampaign}
                timeInvalid={timeInvalid}
                xhr={modalXhr}
                onClose={this.handleClose}
                closes_eod={closes_eod}
                handleClosesEODChange={this.handleClosesEODChange}
              />
            )}
            {
              <ConfirmationModal
                modal={
                  deleteCampaignModal ||
                  deleteCaseModal ||
                  comingSoon ||
                  processedWithErrors
                }
                onClose={this.handleClose}
                onConfirm={this.handleClose}
                onDeleteCases={this.handleConfirmDeleteCases}
                onDeleteCampaign={this.handleDeleteCampaign}
                toDelete={deleteCampaignModal || deleteCaseModal}
                type={
                  deleteCampaignModal
                    ? "campaign"
                    : deleteCaseModal
                      ? "case"
                      : comingSoon
                        ? "coming-soon"
                        : "processedWithErrors"
                }
                xhr={xhr}
              />
            }
            <Breadcrumbs
              text={bot}
              from={"home"}
              botId={botId}
              activeBot={botId}
              activeFilter={activeFilter}
              activeSection={activeSection}
              search={search}
            />
            <div className={"campaign-page-content"}>
              <div className={"campaign-page-content-header"}>
                <CampaignInfo
                  title={title}
                  description={description}
                  percentage_completed={percentage_completed}
                  percentage_called={percentage_called}
                  from={"campaign"}
                  xhr={xhr}
                />
                <Stats
                  type={"campaign"}
                  status={status}
                  commitment={commitment}
                  contacted={contacted}
                  notContacted={notContacted}
                  duration={duration}
                  onOpenModal={this.handleModal}
                  onSelect={this.handleStatus}
                  statusList={!isLoading && ui && ui.campaignStatus}
                  xhr={loadingDetails}
                  conversation_type={conversation_type}
                />
              </div>
              <div className={"campaign-page-content-filter"}>
                <Filters
                  status={status}
                  cases={cases}
                  filter={filter}
                  onClick={this.handleFilter}
                  casesCount={casesCount}
                  selected={this.state.selectedCheckboxes.length}
                  onDelete={this.handleDeleteCase}
                  onOpenModal={() => this.handleModal("import")}
                  onAddNewCase={this.handleNewCase}
                  statusList={statusList}
                  xhr={xhr}
                />
              </div>
              <div className={"campaign-page-content-grid"}>
                <Table
                  status={status}
                  cases={cases}
                  xhr={loadingCase}
                  casesCount={casesCount}
                  onTableChange={this.handleTableChange}
                  page={page}
                  onSelectCheckbox={this.handleCheckbox}
                  selectedCheckboxes={selectedCheckboxes}
                  onAllCheckboxes={this.handleAllCheckboxes}
                  checkAll={checkAll}
                  onExport={this.handleExport}
                  statusList={statusList}
                  campaignId={id}
                  prevPath={this.props.location.pathname}
                  resultFilter={filter}
                  from={"campaign"}
                  activeFilter={activeFilter}
                  activeSection={activeSection}
                  search={search}
                  reporting={true}
                  isChattigo={isChattigo}
                  isPelikanus={isPelikanus}
                  timezone={timezone}
                />
                {
                  (network_failed)
                  &&
                  <div style={{ padding: ".5em 0" }}>
                    <Alert severity={"info"} onClose={this.handleCloseAlertNetworkError}>
                      <AlertTitle>Importante</AlertTitle>
                      La generación del reporte está demorando más de lo esperado. El mismo será enviado por mail una vez generado.
                    </Alert>
                  </div>
                }
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ui: state.ui,
    cases: state.cases,
    details: state.details,
    campaign: state.campaign,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ storeCases, storeDetails }, dispatch);
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CampaignPage)
);
