import {
  USER_LOGIN,
  USER_LOGIN_FAILED,
  USER_LOGOUT,
  USER_LOGIN_THROTTLED,
  USER_LOGIN_OTP_VERIFY,
  USER_LOGIN_OTP_EXPIRED,
  USER_LOGIN_EXPIRED,
  USER_LOGIN_OTP_FAILED,
  USER_RESETED
} from "./actions";

export default function (state = null, action) {
  switch (action.type) {
    case USER_LOGIN:
      let user = action.payload;
      user["token_date"] = new Date().getTime();
      localStorage.setItem("activeUser", JSON.stringify(user));
      return user;
    case USER_LOGIN_FAILED:
      return { userLoginFailed: true };
    case USER_LOGOUT:
      localStorage.clear();
      return null;
    case USER_LOGIN_THROTTLED:
      localStorage.clear();
      return { userLoginFailed: true, userLoginThrottled: true };
    case USER_LOGIN_OTP_VERIFY:
      return { userLogin2fa: true, userLoginFailed: false };
    case USER_LOGIN_OTP_EXPIRED:
      localStorage.clear();
      return { userLogin2fa: true, userLoginOTPExpired: true, userLoginFailed: false };
    
    case USER_LOGIN_EXPIRED:
      let {url} = action.payload;
      return { userLoginExpired: true, userLoginFailed: false, url: url };
    
    case USER_RESETED:
      return null;

    case USER_LOGIN_OTP_FAILED:
      localStorage.clear();
      return { userLogin2fa: true, userLoginOTPFailed: true, userLoginFailed: false };


    default:
      const data = localStorage.getItem("activeUser");
      if (data) return JSON.parse(data);
      return state;
  }
}
