import React, { Component } from "react";
import "./InboundCaseInfo.scss";
import Loader from "../Loader";

class InboundCaseInfo extends Component {
    render() {
        let {name, surname,
            docNumber,
            uuid,
            phone,
            xhr} = this.props
        return(
            <div className={'case-info-container'}>
              {xhr ? <Loader/> :
                <div className={'case-info'}>
                  <h4>{`${name} ${surname}`}</h4>
                  <div className={'info'}>
                    <div className={'left'}>
                      <div className={'ind'}>
                        <span>ID Único</span><span>{docNumber ? docNumber : '----'}</span>
                      </div>
                      <div className={'ind'}>
                        <span>Teléfono</span><span>{phone ? phone : '----'}</span>
                      </div>
                    </div>
                    <div className={'right'}>
                      <div className={'ind'}>
                        <span>UUID Caso</span><span>{uuid ? uuid : '----'}</span>
                      </div>
                      <div className={'ind'}>
                        <span>Nombre y Apellido</span><span>{name ? name + ' ' + surname : '----'}</span>
                      </div>
                    </div>
                  </div>
                </div>
              }
          </div>
    );
  }
}

export default InboundCaseInfo;