import React, { Component } from "react";
import "./BotInboundNavbar.scss";

import Search from "../../assets/search.png";
import CustomInput from "../CustomInput";


class BotInboundNavbar extends Component {

    render() {

        let { activeSection, search, xhr } = this.props;
        let searchPlaceHolder = activeSection === "inbound_reports" ? "ID Caso, ID Cliente o Tel" : "Buscar Inbound UUID";

        return (
            <div className={"bot-inbound-navbar-container"}>
                <div className={"bot-inbound-navbar"}>
                    <ul className={"bot-inbound-navbar-section-container"}>
                        {/*<li className={`bot-inbound-navbar-section ${activeSection ===  "inbounds" && "active"}`}
                            onClick={() => this.props.onClick("inbounds")}>
                            INBOUNDS
                        </li>*/}
                        <li className={`bot-inbound-navbar-section ${activeSection === "inbound_reports" && "active"}`}
                            onClick={() => this.props.onClick("inbound_reports")}>
                            REPORTES
                        </li>
                    </ul>
                    <div className={"bot-inbound-navbar-input"}>
                        <CustomInput
                            type={"text"}
                            placeholder={searchPlaceHolder}
                            value={search}
                            name={"search"}
                            onChange={this.props.onChange}
                            width={"240px"}
                            height
                            img={Search}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default BotInboundNavbar;
