import React, {Component, Fragment} from 'react'
import './DoubleStat.scss'
import Skeleton from 'react-loading-skeleton';
import { FormattedMessage } from 'react-intl';

class DoubleStat extends Component {
    render() {
        let {contacted, notContacted, firstImg, secondImg, firstName, secondName, from, xhr} = this.props
        return (
            <div className={`double-stat-container ${from === 'case' && 'full-size'}`}
                 style={{ lineHeight:'75px', fontSize: '75px', display: xhr ? 'block' : 'flex', padding: xhr && '0px', border: xhr && 'none'}}>
              {xhr ? <Skeleton/> :
                <Fragment>
                  <div className={'double-stat-first'}>
                    <label>
                      <FormattedMessage id="stat.Contactado"/>
                      {/* {firstName} */}
                      </label>
                    <div className={'double-stat'}>
                        <img src={firstImg} alt={firstName}/><span>{(contacted || contacted === 0) ? contacted : '----'}</span>
                    </div>
                  </div>
                  <div className={'double-stat-second'}>
                    <label>
                      {<FormattedMessage id={`stat.${secondName}`}/>}
                      {/* {secondName} */}
                      </label>
                    <div className={'double-stat'}>
                        <img src={secondImg} alt={secondName}/><span>{(notContacted || notContacted === 0) ? notContacted : '----'}</span>
                    </div>
                  </div>
                </Fragment>
              }
            </div>
        )
    }
}

export default DoubleStat