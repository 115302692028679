import React, { Component } from 'react'
import './SettingsSidebar.scss'

class SettingsSidebar extends Component {
  render() {
    let { type } = this.props

    return (
      <div className={'settings-sidebar-container'}>
        <button
          onClick={() => this.props.onClick('users')}
          value={'users'}
          className={`type ${type === 'users' && 'active'}`}
        >
          <span>Usuarios</span>
        </button>
        <button
          onClick={() => this.props.onClick('bots')}
          className={`type ${type === 'bots' && 'active'}`}
        >
          <span>Bots</span>
        </button>
      </div>
    )
  }
}

export default SettingsSidebar
