import urls from "../../lib/urls";
import axios from "axios";

export const MS_RESOLVE_SUCCESS = "MS_RESOLVE_SUCCESS";
export const MS_RESOLVE_NEW_PURCHASE = "MS_RESOLVE_NEW_PURCHASE";
export const MS_RESOLVE_SUBSCRIPTION_CREATED = "MS_RESOLVE_SUBSCRIPTION_CREATED";
export const MS_RESOLVE_FAILED = "MS_RESOLVE_FAILED";

export function resolvePurchaseToken(token) {
    return (dispatch) => {
        const resolve_url = `${urls.API_ROOT}/msmarket/resolve`;
        axios.post(resolve_url, { purchase_token: token }, { withCredentials: true })
            .then((response) => {
                const { status } = response;
                if (status === 200) {
                    dispatch(resolvePurchaseTokenSuccess(response.data));
                } else if (status === 201) {
                    dispatch(resolvePurchaseTokenNewSubscription(response.data));
                } else {
                    dispatch(resolvePurchaseTokenSuccess(response.data));
                }
            })
            .catch((error) => {
                if (!error || !error.response) return;
                console.log(error.response)
                dispatch(resolvePurchaseTokenFailed(error.response))
            })
    }
}

export function updateMsAccount(firstName, lastName, email) {
    return (dispatch) => {
        const resolve_url = `${urls.API_ROOT}/msmarket/resolve`;
        axios.put(resolve_url, {
            first_name: firstName,
            last_name: lastName,
            email: email
        }, { withCredentials: true })
            .then((response) => {
                dispatch(resolveSubscriptionCreated(response.data))
            })
            .catch((error) => {
                if (!error || !error.response) return;
                dispatch(resolvePurchaseTokenFailed(error.response))
            })
    }
}

export function resolvePurchaseTokenSuccess(payload) {
    return {
        type: MS_RESOLVE_SUCCESS,
        payload: payload
    }
}

export function resolvePurchaseTokenNewSubscription(payload) {
    return {
        type: MS_RESOLVE_NEW_PURCHASE,
        payload: payload
    }
}

export function resolvePurchaseTokenFailed(payload) {
    return {
        type: MS_RESOLVE_FAILED,
        error: payload
    }
}

export function resolveSubscriptionCreated(payload) {
    return {
        type: MS_RESOLVE_SUBSCRIPTION_CREATED,
        payload: payload,
    }
}
