export const STORE_CASES = "STORE_CASES";
export const STORE_CASES_DELETED = "STORE_CASES_DELETED";
export const SET_ACTIVE_CASE = "SET_ACTIVE_CASE";

export const storeCases = (cases) => {
  return { type: "STORE_CASES", payload: cases };
};

export const storeCasesDeleted = (cases) => {
  return { type: "STORE_CASES_DELETED", payload: cases };
};
