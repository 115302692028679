import React, { Component, Fragment } from "react";
import _ from 'lodash';

import "./ReportingInbound.scss";

import BotInboundReportingNavbar from "../BotInboundReportingNavbar";
import ReportingInbound_general from "./ReportingInbound_general";
import ReportingInbound_detail from "./ReportingInbound_detail";
import Reporting_custom from "../ReportingCustom";
import CustomDatePicker from "../CustomDatePicker";


class ReportingInbound extends Component {

    constructor(props) {
        super(props);

        this.state = {
            activeTab: "inbound_detail"
        }
    }

    handleReportingTab = section => {
        let { activeTab } = this.state;
        if (section === activeTab) {
            return;
        }
        this.setState({ activeTab: section }, () => { section === "dashboards" && this.props.refreshDashboards() });
    }

    render() {
        let { reportingData,
            totalreportingData,
            cases,
            casesCount,
            filterDict,
            resultFilterList,
            resultFilter,
            activeFilter,
            fromDate,
            toDate,
            page,
            activeBot,
            search,
            xhr,
            onClick,
            onDateChange,
            onTableChange,
            onReportingCasesResultFilter,
            onExport,
            columns_to_show,
            network_failed,
            handleCloseAlertNetworkError,
            isChattigo,
            isPelikanus,
            timezoneBot
        } = this.props;
        let { activeTab } = this.state
        let filterList = Object.keys(filterDict);
        let dateViews = activeFilter === 'year' ? ["year"] : (activeFilter === 'month' ? ["year", "month"] : ["year", "month", "day"])
        let dateLabel = activeFilter === 'year' ? "Año" : (activeFilter === 'month' ? "Mes y Año" : "Fecha")
        return (
            <div className={"bot-inbound-reporting-container"}>
                <div className={"bot-inbound-reporting"}>
                    <div className={"bot-inbound-reporting-body"}>
                        <div className={"bot-inbound-reporting-filters"}>
                            <BotInboundReportingNavbar activeTab={activeTab}
                                onClick={this.handleReportingTab} />
                            {activeTab !== "dashboards" &&
                                <Fragment>
                                    <div className={"bot-inbound-reporting-quick-filters"}>
                                        {filterList && filterList.map((filter, i) => (
                                            <Fragment key={i}>
                                                <button className={`${filter} ${activeFilter === filter && "active"}`}
                                                    onClick={() => this.props.onClick(filter)}>
                                                    {filterDict[filter].toUpperCase()}{activeFilter === filter ? `(${casesCount})` : ""}
                                                </button>
                                            </Fragment>
                                        ))}
                                    </div>
                                    <div className={"bot-inbound-reporting-filter-helpers"}>
                                        {activeFilter && filterList.map((filter, i) => {
                                            return (filter === 'all' || filter !== activeFilter ? <></> :
                                                <Fragment key={i}>
                                                    <CustomDatePicker
                                                        date={fromDate}
                                                        onDateChange={this.props.onFromDateChange}
                                                        views={dateViews}
                                                        label={"Desde"}
                                                    />
                                                    <CustomDatePicker
                                                        date={toDate}
                                                        onDateChange={this.props.onToDateChange}
                                                        views={dateViews}
                                                        label={"Hasta"}
                                                    />
                                                </Fragment>
                                            )
                                        })}
                                    </div>
                                </Fragment>
                            }
                        </div>
                        {activeTab === 'inbound_general' ?
                            <ReportingInbound_general onClick={onClick}
                                onDateChange={onDateChange}
                                onTableChange={onTableChange}
                                onReportingCasesResultFilter={onReportingCasesResultFilter}
                                onExport={onExport}
                                cases={cases}
                                reportingData={reportingData}
                                totalreportingData={totalreportingData}
                                casesCount={casesCount}
                                filterDict={filterDict}
                                resultFilterList={resultFilterList}
                                activeFilter={activeFilter}
                                resultFilter={resultFilter}
                                activeBot={activeBot}
                                fromDate={fromDate}
                                toDate={toDate}
                                page={page}
                                xhr={xhr}
                            /> :
                            activeTab === 'inbound_detail' ?
                                <ReportingInbound_detail onClick={onClick}
                                    onDateChange={onDateChange}
                                    onTableChange={onTableChange}
                                    onReportingCasesResultFilter={onReportingCasesResultFilter}
                                    onExport={onExport}
                                    cases={cases}
                                    reportingDataByResult={totalreportingData}
                                    casesCount={casesCount}
                                    filterDict={filterDict}
                                    resultFilterList={resultFilterList}
                                    activeFilter={activeFilter}
                                    resultFilter={resultFilter}
                                    activeBot={activeBot}
                                    fromDate={fromDate}
                                    toDate={toDate}
                                    page={page}
                                    search={search}
                                    xhr={xhr}
                                    columns_to_show={columns_to_show}
                                    network_failed={network_failed}
                                    handleCloseAlertNetworkError={handleCloseAlertNetworkError}
                                    isChattigo={isChattigo}
                                    isPelikanus={isPelikanus}
                                    timezoneBot={timezoneBot}
                                    
                                /> :
                                activeTab === 'dashboards' ?
                                  <Reporting_custom
                                    page={page}
                                    xhr={xhr}
                                    activeBot={activeBot}
                                    isChattigo={isChattigo}
                                    isPelikanus={isPelikanus}
                                  /> 
                                 :
                                    <></>}
                    </div>
                </div>
            </div>
        );
    }
}

export default ReportingInbound;
