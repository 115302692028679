import React, { Component, Fragment } from "react";
import "./SingleStat.scss";
import Skeleton from 'react-loading-skeleton';
import { FormattedMessage } from "react-intl";

class SingleStat extends Component {
  render() {
    let { data, img, name, type, from, color, xhr } = this.props;
    return (
      <div className={`single-stat-container ${type && "duration"} ${from === "case" && "full-size"} ${color && color}`}
           style={{ lineHeight:'75px', fontSize: '75px', display: xhr ? 'block' : 'flex', padding: xhr && '0px', border: xhr && 'none'}}>
        {xhr ? <Skeleton/> :
          <Fragment>
            <label>
              <FormattedMessage id={`stat.${name}`}/>
              {/* {name} */}
            </label>
            <div className={"single-stat"}>
              <img src={img} alt={name}/><span>{data ? data : "----"}</span>
            </div>
          </Fragment>
        }
      </div>
    );
  }
}

export default SingleStat;