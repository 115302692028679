import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import "./BotCampaignReportingNavbar.scss";


class BotCampaignReportingNavbar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeTab: this.props.activeTab
        }
    }

    render() {

        let { activeTab } = this.props;

        return (
            <ul className={"bot-campaigns-report-navbar"}>
                <li className={`bot-campaigns-report-navbar-section ${activeTab === "general" && "active"}`}
                    onClick={() => this.props.onClick("general")}>
                    <FormattedMessage id="report.general" />
                    {/* General */}
                </li>
                <li className={`bot-campaigns-report-navbar-section ${activeTab === "detail" && "active"}`}
                    onClick={() => this.props.onClick("detail")}>
                    <FormattedMessage id="report.detail" />
                    {/* Detalle */}
                </li>
                 <li className={`bot-campaigns-report-navbar-section ${activeTab === "dashboards" && "active"}`}
                    onClick={() => this.props.onClick("dashboards")}>
                    <FormattedMessage id="report.exports" />
                    {/* Exportaciones */}
                </li>
            </ul>
        )
    }
}

export default BotCampaignReportingNavbar;
