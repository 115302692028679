import React, { Component } from 'react'
import './BotSidebar.scss'

class Bot extends Component {
  render() {
    let { company, activeBot } = this.props
    let alias = company.alias.replaceAll("_", " ");
        return (
            <div className={`bot-container ${activeBot === company.id && 'active'}`} onClick={this.props.onClick}>
                <div className={'bot'}>
                    {alias}
                </div>
            </div>
        )
    }
}

export default Bot