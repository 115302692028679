/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import _ from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import overlayFactory from "react-bootstrap-table2-overlay";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

import columns from "./columns";
import helpers from "../../lib/helpers";
import Export from "../../components/Export";
import formatters from "../../lib/formatters";
import "./Table.scss";
import { FormattedMessage } from "react-intl";

class Table extends Component {
  render() {
    let {
      cases,
      xhr,
      casesCount,
      page,
      selectedCheckboxes,
      checkAll,
      campaignId,
      statusList,
      status,
      reportingFromDate,
      reportingToDate,
      activeReportingFilter,
      resultFilter,
      activeSection,
      activeBot,
      activeFilter,
      from,
      prevPath,
      search,
      isChattigo,
      isPelikanus,
      timezone
    } = this.props;

    let cursor = "pointer";
    if (status === helpers.campaignStatus.closed) {
      cursor = "default";
    }
    let data = [];

    if (cases && cases instanceof Array) {
      cases.forEach((_case) => {
        let row = { id: _case.id, campaign_id: _case.campaign.id };
        columns.forEach((col) => {
          row[col.dataField] = _.get(_case, col.path, "-");
          if (col.isCustom) {
            switch (col.dataField) {
            case "calls": {
              row[col.dataField] = helpers.getCallsValue(
                _case["call_count"],
                _case["call_duration"]
              );
              break;
            }
            case "commitment": {
              row[col.dataField] = helpers.getCommitmentValue(
                _case["commitment"]
              );
              break;
            }
            case "result": {
              row[col.dataField] = helpers.getStatusValue(
                _case["result"],
                _case["is_final"],
                _case["got_promise"]
              );
              break;
            }
            case "debt": {
              row[col.dataField] = formatters.amountFormatter(
                row[col.dataField]
              );
              break;
            }
            case "expiration": {
              row[col.dataField] = formatters.dateTimeFormatter(
                row[col.dataField]
              );
              break;
            }
            case "callEndDate":
              {
                row[col.dataField] = helpers.getCallEndValue(
                  _case["is_completed"],
                  _case["last_updated"]
                );
              }
              break;
            }
          }
        });
        data.push(row);
      });
    }

    const customTotal = (page, size, total) => {
      let decimal = (total / size) % 1;

      if (total < size) {
        return (
          <span className="react-bootstrap-table-pagination-total">
            Mostrando {total} de {total}
          </span>
        );
      } else {
        if (page === page - decimal) {
          return (
            <span className="react-bootstrap-table-pagination-total">
              Mostrando {page * size} de {total}
            </span>
          );
        } else {
          if (total / size - decimal >= page) {
            return (
              <span className="react-bootstrap-table-pagination-total">
                Mostrando {page * size} de {total}
              </span>
            );
          } else {
            return (
              <span className="react-bootstrap-table-pagination-total">
                Mostrando {(page - 1) * size + decimal * size} de {total}
              </span>
            );
          }
        }
      }
    };

    const pagination = paginationFactory({
      page: page,
      sizePerPage: 20,
      totalSize: casesCount,
      hideSizePerPage: true,
      showTotal: true,
      paginationTotalRenderer: () => {
        return customTotal(page, 20, casesCount);
      },
    });

    const rowStyle = (row) => {
      if (selectedCheckboxes.length > 0) {
        if (
          selectedCheckboxes.find((checkboxId) => {
            return row.id === checkboxId;
          }) !== undefined
        ) {
          return { backgroundColor: "#EFF3F9" };
        }
      }
      if (checkAll) {
        return { backgroundColor: "#EFF3F9" };
      }
    };

    return (
      <div className={isChattigo || isPelikanus?'campaign-table-container-chattigo':'campaign-table-container'}>
        <div className={`campaign-table ${cursor}`}>
          <BootstrapTable
            columns={columns.map((column, index) => {
              return {
                ...column,
                events: {
                  onClick: (e, column, columnIndex, row) => {
                    this.props.history.push({
                      pathname: `/campaigns/${campaignId ? campaignId : row.campaign_id
                      }/cases/${row.id}`,
                      state: {
                        from: from,
                        activeReportingFilter: activeReportingFilter,
                        resultFilter: resultFilter,
                        reportingFromDate: reportingFromDate
                          ? reportingFromDate.toDate()
                          : null,
                        reportingToDate: reportingToDate
                          ? reportingToDate.toDate()
                          : null,
                        page: page,
                        activeSection: activeSection,
                        activeBot: activeBot,
                        activeFilter: activeFilter,
                        prevPath: prevPath,
                        search: search,
                        timezone:timezone
                      },
                    });
                  },
                },
              };
            })}
            remote
            keyField={"id"}
            bordered={false}
            data={data}
            noDataIndication={() => {
              return <p className={"empty"}>
                <FormattedMessage id="empty.info" />
                {/* No hay información disponible */}
              </p>;
            }}
            pagination={pagination}
            loading={xhr}
            overlay={overlayFactory({
              spinner: true,
              background: "rgba(192,192,192,0.85)",
              text: <FormattedMessage id="table.load.users" />,
              // text: "Cargando usuarios...",
            })}
            onTableChange={this.props.onTableChange}
            rowStyle={rowStyle}
          />
          {this.props.reporting && (
            <Export 
              onExport={this.props.onExport} 
              xhr={xhr} 
            />
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(Table);
