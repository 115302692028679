import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import _ from 'lodash'
import BootstrapTable from 'react-bootstrap-table-next'
import overlayFactory from 'react-bootstrap-table2-overlay'
import paginationFactory from 'react-bootstrap-table2-paginator'
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'

import callColumns from './columns'
import helpers from '../../lib/helpers'
import Export from '../Export'
import formatters from '../../lib/formatters'
import voca from 'voca'
import './InboundTable.scss'
import { FormattedMessage } from 'react-intl'

class InboundTable extends Component {
  render() {
    let {
      cases,
      xhr,
      casesCount,
      page,
      selectedCheckboxes,
      checkAll,
      inboundCaseId,
      statusList,
      status,
      reportingFromDate,
      reportingToDate,
      activeReportingFilter,
      resultFilter,
      activeSection,
      activeBot,
      from,
      prevPath,
      search,
      columns_to_show,
      isChattigo,
      isPelikanus,
      timezoneBot
    } = this.props

    let cursor = 'pointer'
    if (status === helpers.inboundStatus.closed) {
      cursor = 'default'
    }

    let extra_columns = [];

    _.filter(cases, (c) => !_.isEmpty(c.extra_metadata)).forEach(_case => {
      for (const [key, value] of Object.entries(_case.extra_metadata)) {
        if (_.get(columns_to_show, key, true) && (!(_.find(extra_columns, { 'path': 'extra_metadata.' + key })))) {
          let newKey = key.replace(/_/g, ' ');
          extra_columns.push(
            {
              dataField: key,
              text: newKey[0].toUpperCase() + newKey.slice(1),
              path: "extra_metadata." + key,
              isCustom: false,
              headerStyle: () => {
                return { width: '7%' };
              },
              style: {
                width: '7%',
              },
            }
          );
        }
      }
    });

    let totalColumns = callColumns.slice(0, -2).concat(extra_columns).concat(callColumns.slice(-2));

    let data = []
    let allIds = []
    if (cases && cases instanceof Array) {
      cases.forEach(_case => {
        let row = { id: _case.id, case_uuid: _case.case_uuid }
        totalColumns.forEach(col => {
          row[col.dataField] = _.get(_case, col.path, '-')
          if (col.isCustom) {
            switch (col.dataField) {
              case 'calls': {
                row[col.dataField] = helpers.getInboundCallValue(
                  _case['case_duration']
                )
                break
              }
              case 'result': {
                row[col.dataField] = helpers.getInboundStatusValue(_case['case_result'].name, _case['case_result'].is_final)
                break
              }
              case 'is_active': {
                let selected =
                  selectedCheckboxes.length > 0 &&
                  selectedCheckboxes.find(checkboxId => {
                    return row.id === checkboxId
                  }) !== undefined &&
                  'selected'
                allIds.push(row.id)
                row[col.dataField] = helpers.getCheckbox(
                  'body',
                  checkAll,
                  selected,
                  cursor,
                  allIds
                )
                break
              }
              case 'callEndDate': {
                row[col.dataField] = helpers.getCallEndValue(_case['is_complete'], _case['last_updated']);
              }
                break
            }
          }
        })
        data.push(row)
      })
    }

    const customTotal = (page, size, total) => {
      let decimal = (total / size) % 1

      if (total < size) {
        return (
          <span className="react-bootstrap-table-pagination-total">
            Mostrando {total} de {total}
          </span>
        )
      } else {
        if (page === page - decimal) {
          return (
            <span className="react-bootstrap-table-pagination-total">
              Mostrando {page * size} de {total}
            </span>
          )
        } else {
          if (total / size - decimal >= page) {
            return (
              <span className="react-bootstrap-table-pagination-total">
                Mostrando {page * size} de {total}
              </span>
            )
          } else {
            return (
              <span className="react-bootstrap-table-pagination-total">
                Mostrando {(page - 1) * size + decimal * size} de {total}
              </span>
            )
          }
        }
      }
    }

    const pagination = paginationFactory({
      page: page,
      sizePerPage: 20,
      totalSize: casesCount,
      hideSizePerPage: true,
      showTotal: true,
      paginationTotalRenderer: () => {
        return customTotal(page, 20, casesCount)
      }
    })

    const rowStyle = row => {
      if (selectedCheckboxes.length > 0) {
        if (
          selectedCheckboxes.find(checkboxId => {
            return row.id === checkboxId
          }) !== undefined
        ) {
          return { backgroundColor: '#EFF3F9' }
        }
      }
      if (checkAll) {
        return { backgroundColor: '#EFF3F9' }
      }
    }

    return (
      <div className={isChattigo || isPelikanus?'inbound-table-container-chattingo':'inbound-table-container'}>
        <div className={`inbound-table ${cursor}`}>
          <BootstrapTable
            columns={totalColumns.map((column, index) => {
              return {
                ...column,
                events: {
                  onClick: (e, column, columnIndex, row) => {
                    let slug = voca.slugify(row.case_uuid);
                    this.props.history.push({
                      pathname: `/inbound-case/${inboundCaseId ? inboundCaseId : row.id}/${slug}`,
                      state: {
                        from: from,
                        activeReportingFilter: activeReportingFilter,
                        resultFilter: resultFilter,
                        reportingFromDate: reportingFromDate ? reportingFromDate.toDate() : null,
                        reportingToDate: reportingToDate ? reportingToDate.toDate() : null,
                        page: page,
                        activeSection: activeSection,
                        activeBot,
                        search: search,
                        prevPath: prevPath,
                        timezone:timezoneBot
                      }
                    })
                  }
                }
              }
            })}
            remote
            keyField={'id'}
            bordered={false}
            data={data}
            noDataIndication={() => {
              return <p className={'center'}>
                <FormattedMessage id="empty.info" />
                {/* No hay información disponible */}
              </p>
            }}
            pagination={pagination}
            loading={xhr}
            overlay={overlayFactory({
              spinner: true,
              background: 'rgba(192,192,192,0.85)',
              text: 'Cargando casos...'
            })}
            onTableChange={this.props.onTableChange}
            rowStyle={rowStyle}
          />
          {this.props.reporting && <Export onExport={this.props.onExport} xhr={xhr} />}
        </div>
      </div>
    )
  }
}

export default withRouter(InboundTable)
