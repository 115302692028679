import React, { Component } from "react";
import "./Navbar.scss";
import Config from "../../assets/config.png";
import Profile from "../../assets/profile-pic.png";
import { Link } from "react-router-dom";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { userLogout } from "../../modules/user/actions";
import helpers from "../../lib/helpers";
import { FormattedMessage } from "react-intl";
import CHATTIGO from '../../assets/chattigo/logo.png'
import PELIKANUS from '../../assets/pelikanus/logo.png'
import Divider from '@mui/material/Divider';

class Navbar extends Component {
  state = {
    open: false,
    active: false,
    isChattigo:false,
    isPelikanus:false
  };

  handleToggle = () => {
    this.setState(state => ({ open: !state.open }));
  };

  handleClose = event => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }
    this.setState({ open: false });
  };

  componentDidMount() {
    this.setState({ active: this.props.location.pathname === "/settings" });
    const Url = window.location.hostname;
    if (Url==='chattigo.inceptia.ai') {
     this.setState({isChattigo:true}) 
    }
    if (Url==='pelikanus.inceptia.ai') {
     this.setState({isPelikanus:true})
    }
  }

  componentDidUpdate(prevProps) {
    const { pathname } = this.props.location;

    if (pathname !== prevProps.location.pathname) {
      this.setState({ active: pathname === "/settings" });
    }
  }

  handleLogout = () => {
    this.props.userLogout();
    this.props.history.push("/");
  };

  render() {
    let { first_name, last_name, profile_image, groups } = this.props.user || this.props.msUser;
    let { open, active,isChattigo,isPelikanus } = this.state;
    return (
      <div className={"navbar-container"}>
        <div className={"navbar"}>
          <div className={"navbar-title"}>
            {isChattigo && <img src={CHATTIGO} alt="logo" />}
            {isPelikanus && 
              <div className={"pelikanus"}>
                <img src={PELIKANUS} alt="logo" />
                <Divider orientation="vertical" variant="middle" flexItem />                
                <span>powered by INCEPTIA</span>    
              </div >            
            }      
          </div>
          <div className={`navbar-config ${active && "active"}`}>
            {groups[0]["name"] === helpers.groups.ADMINISTRATOR && (
              <Link to={"/settings"}>
                <img src={Config} alt={"Configuración"} />
              </Link>
            )}
          </div>
          <div className={"navbar-dropdown"}>
            <button className={"navbar-profile"} onClick={this.handleToggle} ref={node => {
              this.anchorEl = node;
            }}>
              <img src={profile_image !== null ? profile_image : Profile} alt={"Foto"} />
              <span>
                {first_name} {last_name}
              </span>
            </button>
            <Popper open={open} anchorEl={this.anchorEl} transition disablePortal>
              {({ TransitionProps }) => (
                <Grow
                  {...TransitionProps}
                  id="menu-list-grow"
                  style={{ transformOrigin: "center top" }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={this.handleClose}>
                      <MenuList>
                        <MenuItem onClick={this.handleClose}>
                          <button onClick={this.handleLogout} className={"navbar-logout"}>
                            <FormattedMessage id="login.close"/>
                            {/* Cerrar sesión */}
                            </button>
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    msUser: state.msal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ userLogout }, dispatch);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navbar));
