import { STORE_CASES, STORE_CASES_DELETED } from "./actions";

export default function (state = [], action) {
  switch (action.type) {
    case STORE_CASES:
      return action.payload;
    case STORE_CASES_DELETED:
      return [];
    default:
      return state;
  }
}
