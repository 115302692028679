import React, { Component } from 'react'
import './ResetPasswordPage.scss'
import { Link, withRouter } from 'react-router-dom'
import InitialNavbar from '../../components/InitialNavbar'
import CustomInput from '../../components/CustomInput'
import CustomBottom from '../../components/CustomButton'
import validators from '../../lib/validators'
import urls from '../../lib/urls'
import { errorParser } from '../../lib/helpers'
import axios from 'axios'
import Loader from '../../components/Loader'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { resetUser } from '../../modules/user/actions'

class ResetPasswordPage extends Component {
  constructor() {
    super()

    this.state = {
      success: false,
      password1: '',
      password2: '',
      password1Invalid: false,
      password2Invalid: false,
      passwordError: false,
      extraErrors: false,
      xhr: false,
      isChattigo:false,
      isPelikanus:false
    }
  }

  validateField = () => {
    let invalid = false
    if (validators.general(this.state.password1)) {
      this.setState({
        password1Invalid: true
      })
      invalid = true
    }
    if (validators.general(this.state.password2)) {
      this.setState({
        password2Invalid: true
      })
      invalid = true
    }
    return invalid
  }


  HandleBack = () => {
    this.props.resetUser();
  }

  handleClick = e => {
    e.preventDefault()
    if (this.validateField()) {
      return
    } else if (this.state.password1 !== this.state.password2) {
      this.setState({
        passwordError: true
      })
    } else {
      const {token, id} = this.props.match.params;
      const api_url = `${urls.API_ROOT}/api/v1/password_reset/`;
      this.setState({xhr: true, extraErrors: false})
      axios
        .post(api_url, {
          token,
          uid: id,
          new_password1: this.state.password1,
          new_password2: this.state.password2
        })
        .then((response) => {
          this.setState({xhr: false})
          this.setState({success: true})
        })
        .catch((error) => {
          let errors = errorParser(error);
          if(errors.length > 0){
            this.setState({
              xhr: false,
              password1Invalid: false,
              password2Invalid: false,
              passwordError: true,
              extraErrors: errors
            })
          }else{
            this.setState({xhr: false})
          }
        });
    }
  }

  handleChange = (target) => {
    const { name, value } = target
    this.setState({
      [name]: value
    })
  }

  handleFocus = name => {
    this.setState({
      [name]: false,
      passwordError: false
    })
  }

  componentDidMount(){
    const Url = window.location.hostname;
    if (Url==='chattigo.inceptia.ai') {
     this.setState({isChattigo:true}) 
    }
    if (Url==='pelikanus.inceptia.ai') {
     this.setState({isPelikanus:true})
    } 
  }
  render() {
    const {isChattigo,isPelikanus,success}= this.state;
    const { user} = this.props;

   
    return (
      <div className={'reset-password-page-container'}>
        <InitialNavbar />
        <div className={'reset-password-page'}>
          {this.state.xhr ? (
            <Loader/>
          ) : (
            (success)
            ?
            (<div className={'success'}>
              <span>
                {("Password reset successfully").toUpperCase()}
              </span>
               <Link to={'/'} onClick={this.HandleBack} > 
                Go to Login
              </Link>
            </div>)
            
            :
            (<form className={'reset-password'}>
              <span>
                {user && user.userLoginExpired 
                  ? 'YOUR PASSWORD HAS EXPIRED, PLEASE CREATE A NEW ONE' 
                  : 'RESET YOUR PASSWORD'}
                
              </span>
              <div className={'inputs'}>
                <CustomInput
                  type={'password'}
                  name={'password1'}
                  placeholder={'New Password'}
                  value={this.state.password1}
                  onChange={this.handleChange}
                  onFocus={() => this.handleFocus('password1Invalid')}
                  invalid={this.state.password1Invalid}
                  error={this.state.passwordError}
                />
                <CustomInput
                  type={'password'}
                  name={'password2'}
                  placeholder={'Confirm your password'}
                  value={this.state.password2}
                  onChange={this.handleChange}
                  onFocus={() => this.handleFocus('password2Invalid')}
                  invalid={this.state.password2Invalid}
                  error={this.state.passwordError}
                  extraErrors={this.state.extraErrors}
                />
              </div>
              <CustomBottom
                isChattigo={isChattigo}
                isPelikanus={isPelikanus}
                className={'reset-password-btn'}
                text={'Accept'}
                onClick={this.handleClick}
              />
              <Link to={'/'} onClick={this.HandleBack} > 
                Go to Login
              </Link>
        
            </form>
            )
       )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    msAuth: state.msal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({resetUser }, dispatch);
};

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(ResetPasswordPage));
