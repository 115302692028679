const validators = {
  phone: (phoneNumber) => {
    const validation = phoneNumber.length === 13
    return !validation
  },
  file: (attachments) => {
    return attachments.length === 0
  },
  email: (e) => {
    // eslint-disable-next-line
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return !re.test(e);
  },
  general: (e) => {
    return e.trim() === "";
  },
  number1to100: (c) => {
    const v = c === "" || (Number.isInteger(parseInt(c)) && parseInt(c) > 0 && parseInt(c) <= 100);
    return !v;
  }
};

export default validators;

