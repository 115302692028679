import React, {Component} from 'react'
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import voca from 'voca'

class ReportingCard extends Component {
    render() {
        let {title, val} = this.props
        return (
            <Grid item xs={4}>
                <Paper>
                        <div className={'card-container'}>
                            <div className={'card-title'}>
                                <h4>{title}</h4>
                            </div>
                            <div className={'card-data'}>
                                <span>{val}</span>
                            </div>
                        </div>
                </Paper>
            </Grid>
        )
    }
}

export default ReportingCard
