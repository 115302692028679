/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import _ from 'lodash';
import BootstrapTable from 'react-bootstrap-table-next';
import overlayFactory from 'react-bootstrap-table2-overlay';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

import columns from './columns';
import helpers, { arrayFiltersParser, languageStatus } from '../../lib/helpers';
import Export from '../Export';
import formatters from '../../lib/formatters';
import './TableExports.scss';
import { FormattedMessage } from 'react-intl';

class TableExports extends Component {
  
  render() {
    let {
      tasks,
      tasksCount,
      xhr,
      page,
      onTableChange,
      onExport,
      isChattigo,
      isPelikanus
    } = this.props;

    let cursor = 'pointer';
    let data = [];
    
    if (tasks && tasks instanceof Array) {
      tasks.forEach((_task) => {
        let row = { id: _task.id };
        columns.forEach((col) => {
          row[col.dataField] = _.get(_task, col.path, '-');          
          switch (col.dataField) {
            case 'Id':
              row[col.dataField]= _task.id;
              break;
            case 'File':
              row[col.dataField]= 
              helpers.getStyleExport(
                <FormattedMessage id="export.download" />,
                _task.status
              );
              break;
            case 'Filters':
              row[col.dataField]=arrayFiltersParser(_task.export_filters);
              break;
            case 'Created at':
              row[col.dataField]= formatters.dateTimeFormatter(_task.created_at);
              break;
            case 'Status':
              row[col.dataField]=languageStatus(_task.status);
              break;          
            default:
              break;
          }       
        });
        data.push(row);
      });
    }

    const customTotal = (page, size, total) => {
      let decimal = (total / size) % 1;
      
      if (total < size) {
        return (
          <span className="react-bootstrap-table-pagination-total">
            Mostrando {total} de {total}
          </span>
        );
      } else {
        if (page === page - decimal) {
          return (
            <span className="react-bootstrap-table-pagination-total">
              Mostrando {page * size} de {total}
            </span>
          );
        } else {
          if (total / size - decimal >= page) {
            return (
              <span className="react-bootstrap-table-pagination-total">
                Mostrando {page * size} de {total}
              </span>
            );
          } else {
            return (
              <span className="react-bootstrap-table-pagination-total">
                Mostrando {(page - 1) * size + decimal * size} de {total}
              </span>
            );
          }
        }
      }
    };
    
    const pagination = paginationFactory({
      page: page,
      sizePerPage: 20,
      totalSize: tasksCount,
      hideSizePerPage: true,
      showTotal: true,
      paginationTotalRenderer: () => {    
        return customTotal(page, 20, tasksCount);
      },
    });
    return (
      <div className={isChattigo || isPelikanus ?'campaign-table-container-chattigo':'campaign-table-container'}>
        <div className={`campaign-table ${cursor}`}>
          <BootstrapTable
            columns={columns.map((column) => {
              return {
                ...column,
                events: {                  
                  onClick: (e, column, columnIndex, row) => {
                    if (column.dataField==='File') {
                      onExport(row.id);
                    }
                  },
                },
              };
            })}
            remote
            keyField={'id'}
            bordered={false}
            data={data}
            noDataIndication={() => {
              return <p className={'center'}>
                <FormattedMessage id="empty.info" />
                {/* No hay información disponible */}
              </p>;
            }}
            pagination={pagination}
            loading={xhr}
            overlay={overlayFactory({
              spinner: true,
              background: 'rgba(192,192,192,0.85)',
            })}
            onTableChange={onTableChange} 
          />
  

        </div>
      </div>
    );
  }
}

export default withRouter(TableExports);
