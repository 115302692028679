import React, { Component } from "react";
import "./InboundCasePage.scss";
import { withRouter } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs";
import { instance as axios } from "../../lib/axios";
import InboundCaseInfo from "../../components/InboundCaseInfo";
import Chat from "../../components/Chat";
import Call from "../../components/Call";
import CallDetails from "../../components/CallDetails";
import urls from "../../lib/urls";
import { tokenHeader } from "../../lib/headers";
import Loader from '../../components/Loader'
import EmptyState from "../../components/EmptyState";
import { connect } from "react-redux";
import List from '@material-ui/core/List';

class InboundCasePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      // prevPath: null,
      xhr: false,
      name: "",
      surname: "",
      case_code: "",
      contacted: "-",
      duration: "--:--:--",
      phone: "",
      extra_metadata: {},
      inbound_case: null,
      timezoneBot:null
    };
  }

  async componentDidMount() {
    await Promise.all([
      this.fetchInboundCase()
    ]);
  }

  getBotConfiguration = async (id) => {
    if (id === null || id === undefined) return {};
    const botConfigurationUrl = `${urls.API_ROOT}/api/v1/bot-configurations/${id}/`;
    await axios.get(botConfigurationUrl, { ...tokenHeader() })
    .then((res) => {
      if (res.status === 404) return {};
      this.setState({ timezoneBot: res.data.timezone }); 
    })
    .catch((err) => {
        console.log(err)
        return {};
      });
  };

  fetchInboundCase = async () => {
    this.setState({ xhr: true });
    const { id } = this.state;
    let caseUrl = `${urls.API_ROOT}/api/v1/inbound-case/${id}/`;
    try {
      const res = await axios.get(caseUrl, tokenHeader());
      const { first_name, last_name, code, case_duration, phone, case_uuid, id, extra_metadata,bot } = res.data;
      //if it finds the saved timezone it sets the state, otherwise it does apicall
      if (this.props.location.state && this.props.location.state.timezone) {
        this.setState({ timezoneBot: this.props.location.state.timezone}); 
      }else{
        await this.getBotConfiguration(bot.id)
      }
      this.setState({
        xhr: false,
        name: first_name,
        surname: last_name,
        case_code: code,
        duration: case_duration,
        uuid: case_uuid,
        inbound_case: res.data,
        activeCase: id,
        phone,
        extra_metadata,
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ xhr: false });
    }
  };

  handleClick = (id) => {
    this.setState({
      activeCase: id
    });
  };

  render() {
    let {
      //prevPath,
      name,
      surname,
      case_code,
      phone,
      uuid,
      inbound_case,
      activeCase,
      xhr,
      extra_metadata,
      timezoneBot
    } = this.state;

    if (inbound_case) {
      // BACKWARDS COMPATIBILITY
      inbound_case.gateway_id = inbound_case.case_uuid;
      inbound_case.result = inbound_case.case_result.name;
      inbound_case.duration = inbound_case.case_duration;
      inbound_case.is_final = inbound_case.case_result.is_final;
    }

    let isChat = inbound_case && !inbound_case.recording;
    let isCall = inbound_case && inbound_case.recording;

    if (this.props.location !== undefined && this.props.location.state !== undefined) {
      var { prevPath, from, reportingFromDate, reportingToDate, activeReportingFilter, resultFilter, activeSection, page, search, activeBot } = this.props.location.state;
    } else {
      var prevPath, from, reportingFromDate, reportingToDate, activeReportingFilter, resultFilter, activeSection, page, search, activeBot;
    }

    const { isLoading } = this.props.ui;
    return (
      <div className={"case-page-container"}>
        {isLoading ? <Loader /> : (
          <div className={"case-page"}>
            <Breadcrumbs text={"INBOUNDS"}
              from={from}
              reportingFromDate={reportingFromDate}
              reportingToDate={reportingToDate}
              activeReportingFilter={activeReportingFilter}
              resultFilter={resultFilter}
              activeSection={activeSection}
              activeBot={activeBot}
              page={page}
              prevPath={prevPath}
              search={search}
              inbound
            />
            <div className={"case-page-content"}>
              <div className={"case-page-content-header"}>
                <InboundCaseInfo
                  name={name}
                  surname={surname}
                  docNumber={case_code}
                  uuid={uuid}
                  phone={phone}
                  xhr={xhr}
                />
              </div>
              <div className={"case-page-content-body"}>
                <div className={"calls-container"}>
                  <div className={`calls ${!inbound_case && 'empty'}`}>
                    {xhr ? <Loader /> :
                      (!inbound_case ?
                        <EmptyState text={'No hay se registran casos aún'} /> :
                        isCall &&
                        <List style={{ padding: 0 }}>
                          <Call call={inbound_case} activeCall={activeCase} extra={extra_metadata} onClick={() => this.handleClick(inbound_case.id)} />
                        </List> ||
                        isChat &&
                        <Chat chat={inbound_case} activeChat={activeCase} extra={extra_metadata} onClick={() => this.handleClick(inbound_case.id)} />
                      )
                    }
                  </div>
                  <div className={"call-details"}>
                    {(xhr || timezoneBot===null) ? <Loader /> :
                      <CallDetails
                        data={inbound_case && inbound_case.case_log}
                        name={name} surname={surname}
                        phone={phone} uuid={uuid}
                        recording={inbound_case && inbound_case.recording}
                        activeBot={inbound_case && inbound_case.bot.name}
                        isInbound
                        timezoneBot={timezoneBot}
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ui: state.ui
  };
};

export default withRouter(connect(mapStateToProps)(InboundCasePage));
