import React, { useState, useMemo, useEffect } from 'react'
import moment from 'moment'
import Select from 'react-select'
import './TimeRangePicker.scss'

moment.updateLocale('es', {})

const createOptions = ({ interval }) => {
  const options = []
  const maxTime = { hour: 23, minute: 59 }

  let actualMoment = moment('00:00', 'HH:mm')
  const maxMomentTime = moment(actualMoment).set(maxTime)

  while (actualMoment.isBefore(maxMomentTime)) {
    const value = actualMoment.format()
    const label = actualMoment.format('HH:mm')

    options.push({ value, label })
    actualMoment.add(interval, 'minutes')
  }

  return options
}

const dot = (color = '#ccc') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    position: 'absolute',
    right: 2,
    content: '"hs"',
    display: 'block',
    marginRight: 8,
    fontSize: 15,
    color: '#00124B'
  }
})

const colourStyles = {
  input: styles => ({ ...styles, ...dot() }),
  control: (styles) => ({
    ...styles,
    border: '1.1px solid #CFCFCF !important',
    borderRadius: 2,
    alignItems: 'center',
    minHeight: 28,
    display: 'flex',
    height: 30,
    boxSizing: 'border-box',
    boxShadow: 'none'
  }),
  container: (styles) => ({
    ...styles,
    height: 30,
    width: '40%',
    marginRight: 20
  }),
  option: (provided, {isFocused, isSelected}) => ({
    ...provided,
    backgroundColor: (isSelected || isFocused) && '#EFF0F9',
    color: isSelected && '#0066CA'
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    color: '#0066CA !important'
  })
}

const TimeRangePicker = ({ handleRangeChange, handleErrorChange, timeStart, timeEnd }) => {
  const actualMoment = moment(timeStart || '00:00', 'HH:mm')
  const maxMomentTime = moment(timeEnd || '23:45', 'HH:mm')

  const [startTime, setStartTime] = useState({ value: actualMoment.format(), label: actualMoment.format('HH:mm')})
  const [endTime, setEndTime] = useState({ value: maxMomentTime.format(), label: maxMomentTime.format('HH:mm')})
  const [error, setError] = useState(false)

  const handleStartTimeChange = startTime => {
    setStartTime(startTime)

    if (!moment(startTime.value).isBefore(endTime.value) && !(endTime.value === startTime.value)) {
      setError('Por favor ingrese un rango válido. La hora de comienzo no puede estar después de la hora de fin.')
    } else {
      setError('')
    }
  }

  const handleEndTimeChange = endTime => {
    setEndTime(endTime)

    if (!moment(endTime.value).isAfter(startTime.value) && !(endTime.value === startTime.value)) {
      setError('Por favor ingrese un rango válido. La hora de fin no puede estar antes de la hora de comienzo.')
    } else {
      setError('')
    }
  }

  const options = useMemo(() => {
    return createOptions({ interval: 15 })
  }, [])

  useEffect(() => {
    if (!error) return handleRangeChange({ startTime, endTime })
    return handleErrorChange(error)
  }, [startTime, endTime])

  return (
      <div className='time-range-pickers-container'>
        <Select options={options} value={startTime} onChange={handleStartTimeChange} styles={colourStyles} />
        <Select options={options} value={endTime} onChange={handleEndTimeChange} styles={colourStyles} />
      </div>
  )
}

export default TimeRangePicker