import React, { Component } from "react";
import "./CasePage.scss";
import { withRouter } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs";
import { instance as axios } from "../../lib/axios";
import CaseInfo from "../../components/CaseInfo";
import Stats from "../../components/Stats";
import Call from "../../components/Call";
import CallDetails from "../../components/CallDetails";
import urls from "../../lib/urls";
import { tokenHeader } from "../../lib/headers";
import formatters from '../../lib/formatters'
import _ from 'lodash'
import Loader from '../../components/Loader'
import EmptyState from "../../components/EmptyState";
import { connect } from "react-redux";
import moment from 'moment-timezone';
import { FormattedMessage } from "react-intl";

class CasePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.match.params.id,
      // prevPath: null,
      xhr: false,
      name: "",
      surname: "",
      docNumber: "",
      dueAmount: "",
      dueDate: "",
      product: "",
      commitment: "",
      contacted: "-",
      failed: "-",
      datetime: "",
      duration: "--:--:--",
      callsCount: 0,
      activeCall: null,
      campaignId: this.props.match.params.campaignId,
      calls: [],
      statusList: [],
      timezoneBot:null,
    };
  }

  async componentDidMount() {
    await Promise.all([
      this.fetchCase(),
      this.fetchCalls()
    ]);
  }

  getBotConfiguration = async (id) => {
    if (id === null || id === undefined) return {};
    const botConfigurationUrl = `${urls.API_ROOT}/api/v1/bot-configurations/${id}/`;
    await axios.get(botConfigurationUrl, { ...tokenHeader() })
    .then((res) => {
      if (res.status === 404) return {};
      console.log(res.data.timezone)
      this.setState({ timezoneBot: res.data.timezone }); 
    })
    .catch((err) => {
      console.log(err);
        return {};
      });
  };

  fetchCase = async () => {
    this.setState({ xhr: true });
    const { id, campaignId } = this.state;
    let caseUrl = `${urls.API_ROOT}/api/v1/campaigns/${campaignId}/cases/${id}/`;
    try {
      const res = await axios.get(caseUrl, tokenHeader());
      const { first_name, last_name, code, product, debt, expiration, commitment, call_duration,campaign } = res.data;
      this.setState({
        xhr: false,
        name: first_name,
        surname: last_name,
        docNumber: code,
        dueAmount: formatters.amountFormatter(debt),
        dueDate: expiration,
        commitment: commitment,
        duration: call_duration,
        product,
      });
      //if it finds the saved timezone it sets the state, otherwise it does apicall
      if (this.props.location.state && this.props.location.state.timezone) {
        this.setState({ timezoneBot: this.props.location.state.timezone}); 
      }else{
        await this.getBotConfiguration(campaign.bot)
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ xhr: false });
    }
  };

  fetchCalls = async () => {
    if (this.state.xhr) this.state.axiosSource.cancel("abort");
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    this.setState({ xhr: true, axiosSource: source });
    const { id, campaignId } = this.state;
    let callsUrl = `${urls.API_ROOT}/api/v1/campaigns/${campaignId}/cases/${id}/calls/`;
    try {
      const res = await axios.get(callsUrl, { ...tokenHeader(), cancelToken: source.token });
      const { count: callsCount, results: calls } = res.data;
      const contactedCalls = calls.filter(call => call.is_final);
      const contacted = contactedCalls.length;
      const failedCalls = calls.filter(call => !call.is_final);
      const failed = failedCalls.length;
      const activeCall = calls.length === 0 ? null : calls[0].id;
      this.setState({
        xhr: false,
        axiosSource: null,
        calls,
        activeCall,
        callsCount,
        contacted,
        failed
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ xhr: false });
    }
  };

  handleClick = (id) => {
    this.setState({
      activeCall: id
    });
  };

  
  render() {
    let {
      //prevPath,
      name,
      surname,
      docNumber,
      dueAmount,
      dueDate,
      product,
      commitment,
      contacted,
      failed,
      duration,
      callsCount,
      activeCall,
      calls,
      datetime,
      xhr,
      timezoneBot
      
    } = this.state;
    if (this.props.location !== undefined && this.props.location.state !== undefined) {
      var { prevPath, from, reportingFromDate, reportingToDate, activeReportingFilter, resultFilter, activeSection, page, activeBot, activeFilter, search } = this.props.location.state;
    } else {
      var prevPath, from, reportingFromDate, reportingToDate, activeReportingFilter, resultFilter, activeSection, page, activeBot, activeFilter, search;
    }

    const orderedCalls = _.orderBy(calls, ["id"], ["desc"]);
    const data = calls && calls.find((c) => activeCall === c.id);
    
    if (data && timezoneBot!==null ) {
      let stillUtc = moment.utc(data.last_updated).toDate();
      datetime = moment(new Date(stillUtc)).tz(timezoneBot).format("L LT")
    }

    // datetime = data && moment(new Date(data.last_updated)).format("DD/MM/YYYY HH:mm");;
    const { isLoading } = this.props.ui;

    return (
      <div className={"case-page-container"}>
        {isLoading ? <Loader /> : (
          <div className={"case-page"}>
            <Breadcrumbs
              text={<FormattedMessage id="text.campaign" />}
              // text={"CAMPAÑA"}
              from={from}
              reportingFromDate={reportingFromDate}
              reportingToDate={reportingToDate}
              activeReportingFilter={activeReportingFilter}
              resultFilter={resultFilter}
              activeSection={activeSection}
              activeBot={activeBot}
              activeFilter={activeFilter}
              page={page}
              prevPath={prevPath}
              search={search}
            />
            <div className={"case-page-content"}>
              <div className={"case-page-content-header"}>
                <CaseInfo name={name}
                  surname={surname}
                  docNumber={docNumber}
                  dueAmount={dueAmount}
                  dueDate={dueDate}
                  product={product}
                  xhr={xhr}
                />
                <Stats
                  type={"case"}
                  commitment={commitment}
                  contacted={contacted}
                  failed={failed}
                  duration={duration}
                  xhr={xhr}
                />
              </div>
              <div className={"case-page-content-body"}>
                <div className={"calls-count"}>
                  <span>
                    <FormattedMessage id="case.call" />{` (${callsCount})`}
                    {/* {`LLAMADAS (${callsCount})`} */}
                  </span>
                </div>
                <div className={"calls-container"}>
                  <div className={`calls ${callsCount === 0 && 'empty'}`}>
                    {xhr ? <Loader /> :
                      callsCount === 0 ?
                        <EmptyState
                          text={<FormattedMessage id="empty.call" />}
                        // text={'No hay llamadas realizadas'}
                        />
                        :
                        orderedCalls.map((call, i) =>
                          <Call key={i} call={call} activeCall={activeCall} extra={call.extra_metadata} onClick={() => this.handleClick(call.id)} />
                        )
                    }
                  </div>
                  <div className={"call-details"}>
                    {(xhr || timezoneBot===null) ? <Loader /> :
                      <CallDetails
                        data={data && data.data}
                        name={name} surname={surname}
                        recording={data && data.recording}
                        datetime={datetime}
                        timezoneBot={timezoneBot}
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ui: state.ui
  };
};

export default withRouter(connect(mapStateToProps)(CasePage));
