/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import './BotCampaignNavbar.scss';

import Search from '../../assets/search.png';
import Add from '../../assets/add-santander.png';
import CustomInput from '../CustomInput';
import { FormattedMessage } from 'react-intl';


class BotCampaignNavbar extends Component {

  render() {

    let { activeSection, search, xhr } = this.props;

    let searchPlaceHolder = activeSection === 'reports' ? 'Buscar ID Cliente ó Tel' : 'Buscar Campaña';


    return (
      <div className={'bot-campaigns-navbar-container'}>
        <div className={'bot-campaigns-navbar'}>
          <ul className={'bot-campaigns-navbar-section-container'}>
            <li className={`bot-campaigns-navbar-section ${activeSection === 'campaigns' && 'active'}`}
              onClick={() => this.props.onClick('campaigns')}>
              <FormattedMessage id="campaign.name" />
              {/* CAMPAÑAS */}
            </li>
            <li className={`bot-campaigns-navbar-section ${activeSection === 'reports' && 'active'}`}
              onClick={() => this.props.onClick('reports')}>
              <FormattedMessage id="campaign.report" />
              {/* REPORTES */}
            </li>
          </ul>
          <div className={'bot-campaigns-navbar-input'}>
            <FormattedMessage id={`campaign.${searchPlaceHolder}`} defaultMessage="Buscar">
              {placeholder =>
                <CustomInput
                  type={'text'}
                  placeholder={placeholder}
                  value={search}
                  name={'search'}
                  onChange={this.props.onChange}
                  width={'240px'}
                  height
                  img={Search}
                />
              }
            </FormattedMessage>
            {/* <CustomInput
                            type={"text"}
                            placeholder={searchPlaceHolder}
                            value={search}
                            name={"search"}
                            onChange={this.props.onChange}
                            width={"240px"}
                            height
                            img={Search}
                        /> */}
          </div>
          {
            activeSection === 'campaigns' &&
            <div className={'bot-campaigns-navbar-add'}>
              <button onClick={this.props.openModal} disabled={xhr}>
                <img src={Add} alt={'Agregar'} />
              </button>
            </div>
          }
        </div>
      </div>
    );
  }
}

export default BotCampaignNavbar;
