import React, {Component} from 'react'
import './Progress.scss'

class Progress extends Component {
    render() {
        let {percentage, size} = this.props

        return (
            <div className={`progress-container ${size}`}>
                <div className={'progress'}>
                    <div className={'progress-color'} style={{width: `${percentage}%`}}>
                    </div>
                </div>
                <div className={'progress-percentage'}>
                    <span>{`${percentage}%`}</span>
                </div>
            </div>
        )
    }
}

export default Progress