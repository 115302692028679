import React, { Component } from 'react'
import './SettingsUserRow.scss'
import SettingsTag from '../../components/SettingsTag'
import Edit from '../../assets/edit.png'
import Delete from '../../assets/delete.png'
import helpers, { groups } from "../../lib/helpers";

class SettingsUserRow extends Component {
  render() {
    let { data, currentUser } = this.props
    let { bots } = data
    let bots_count = bots.length

    let userType = 'Lector'
    if (data.groups && data.groups.length > 0) {
      if (data.groups[0]['name'] === helpers.groups[groups[1]]) {
        userType = 'Admin'
      } else if (data.groups[0]['name'] === helpers.groups[groups[2]]) {
        userType = 'Manager'
      }
    }

    let count
    let rest
    if (bots_count) {
      if (bots_count > 5) {
        rest = bots_count - 5
        count = [...bots].slice(0, 5)
      } else {
        count = bots
      }
    }

    let text
    if (rest && rest > 0) {
      let arr = [...bots].slice(5, bots_count)
      arr.forEach((company, i) => {
        if (i === 0) {
          text = company.name;
        } else {
          text = text.concat('\n', company.name)
        }
      })
    }

    return (
      <div className={'settings-user-row-container'}>
        <div className={'settings-user-data'}>
          <h5>{`${data.first_name} ${data.last_name}`}</h5>
          <span>{data.email}</span>
        </div>
        <div className={'settings-user-details'}>
          <span className="user-type">{userType}</span>
          <div className={'settings-tags'}>
            {count && count.map((bot, i) => {
              return <SettingsTag key={i} data={bot.name} />
            })}
            {rest && <SettingsTag key={'hidden'} hidden data={`+${rest}`} text={text} />}
          </div>
        </div>
        <div className={'settings-user-actions'}>
          <button onClick={this.props.onEdit}>
            <img src={Edit} alt="Editar" />
          </button>
          <button onClick={this.props.onDelete}
            disabled={currentUser && currentUser.id && currentUser.id === data.id}
            className={currentUser && currentUser.id && currentUser.id === data.id && 'disabled'}>
            <img src={Delete} alt="Eliminar" />
          </button>
        </div>
      </div>
    )
  }
}

export default SettingsUserRow
