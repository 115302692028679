import { STORE_DETAILS, STORE_DETAILS_DELETED } from "./actions";

export default function (state = [], action) {
  switch (action.type) {
    case STORE_DETAILS:
      return action.payload;
    case STORE_DETAILS_DELETED:
      return [];
    default:
      return state;
  }
}
