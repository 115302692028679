import React from 'react';
import { FormattedMessage } from 'react-intl';

const columns = [{
  dataField: 'name',
  text: <FormattedMessage id="report.Apertura"/>,
  // text: 'Apertura Descanso',
  isCustom: true,
  headerStyle: () => {
    return {width: '50%'};
  },
  style: {
    width: '50%'
  }
}, {
  dataField: 'count',
  text: <FormattedMessage id="report.Cantidad-ID-Clientes"/>,
  // text: 'Cantidad ID Clientes',
  isCustom: true,
  headerStyle: () => {
    return {width: '50%'};
  },
  style: {
    width: '50%',
  }
}
];

export default columns;
