import React, { Component } from 'react'
import './LoginPage.scss'
import { Link, withRouter } from 'react-router-dom'
import InitialNavbar from '../../components/InitialNavbar'
import CustomInput from '../../components/CustomInput'
import CustomBottom from '../../components/CustomButton'
import validators from '../../lib/validators'
import Profile from '../../assets/profile.png'
import Key from '../../assets/key.png'
import { loginUser } from '../../modules/user/actions';
import { getMSAuthUrl, loginMsUser } from '../../modules/msal/actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Loader from '../../components/Loader';
import MsftSSO from '../../components/MsftSSO';
import { Alert, AlertTitle } from '@material-ui/lab';
import _ from 'lodash';
import { parse } from "qs";
import { FormattedMessage } from 'react-intl'


// const url = this.props.match;
//     console.log(url)

class LoginPage extends Component {
  constructor() {
    super()

    this.state = {
      username: '',
      password: '',
      usernameInvalid: false,
      passwordInvalid: false,
      error: false,
      xhr: false,
      isChattigo:false,
      isPelikanus:false,
    }
  }

  validateField = () => {
    let invalid = false
    if (validators.general(this.state.username)) {
      this.setState({
        usernameInvalid: true
      })
      invalid = true
    }
    if (validators.general(this.state.password)) {
      this.setState({
        passwordInvalid: true
      })
      invalid = true
    }
    return invalid
  }

  handleClick = e => {
    e.preventDefault()
    if (!this.validateField()) {
      this.props.loginUser(this.state.username, this.state.password);
      this.setState({ xhr: true })
    }
  }

  handleChange = ({ name, value }) => {
    this.setState({
      [name]: value
    })
  }

  handleFocus = () => {
    this.setState({
      usernameInvalid: false,
      passwordInvalid: false,
      error: false
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.msAuth !== this.props.msAuth) {
      this.setState({ xhr: false })
    }

    if (prevProps.user !== this.props.user && this.props.user !== null && this.props.user.hasOwnProperty('userLoginThrottled') && this.props.user.userLoginThrottled) {
      this.setState({
        error: true,
        userThrottled: true,
        usernameInvalid: true,
        passwordInvalid: true,
        xhr: false
      })
    } else if (prevProps.user !== this.props.user && this.props.user !== null && this.props.user.hasOwnProperty('userLoginFailed')) {
      this.setState({
        usernameInvalid: true,
        passwordInvalid: true,
        userThrottled: false,
        error: true,
        xhr: false
      })
    }
  }

  componentDidMount() {
    const { msRedirect, error_message } = parse(this.props.location.search, { ignoreQueryPrefix: true });        
    const Url = window.location.hostname;
    if (Url==='chattigo.inceptia.ai') {
     this.setState({isChattigo:true}) 
    }   
    if (Url==='pelikanus.inceptia.ai') {
      this.setState({isPelikanus:true})
    }

    if (error_message || !msRedirect) {
      this.props.getMSAuthUrl();
      this.setState({ xhr: true })
    } else if (msRedirect) {
      this.props.loginMsUser();
      this.setState({ xhr: true })
    }
  }

  render() {
    const { error_message } = parse(this.props.location.search, { ignoreQueryPrefix: true });
    const { error, subscriptionCreated, creationEmail } = this.props.msMarket;
    const {isChattigo,isPelikanus}= this.state;
    
    return (
      <div className={isChattigo?'login-page-container background-chattigo':isPelikanus?'login-page-container background-pelikanus':'login-page-container background-inceptia'}>
        <InitialNavbar isChattigo={isChattigo} isPelikanus={isPelikanus} />
        {error_message &&
          <Alert severity="error">
            <AlertTitle>
              <FormattedMessage id="microsoft.error"/>
              {/* Error ingresando con su cuenta de Microsoft */}
            </AlertTitle>
            {`${error_message}`}
          </Alert>
        }
        {error &&
          <Alert severity={`${error.type}`}>
            <AlertTitle>
              <FormattedMessage id="microsoft.error.verif"/>
              {/* Error verificando su subscripción */}
            </AlertTitle>
            {`${error.message}`}
          </Alert>
        }
        {subscriptionCreated && creationEmail &&
          <Alert severity={"success"}>
            <AlertTitle>
              <FormattedMessage id="microsoft.error.success"/>
              {/* Proceso de subscripción finalizado con éxito */}
            </AlertTitle>
              <FormattedMessage id="microsoft.error.pending"/>
              creationEmail
            {/* {"Su subscripción se encuentra pendiente de activación. Una vez finalizado el proceso de aprovisionamiento estará recibiendo un correo a " + `${creationEmail}`} */}
          </Alert>
        }
        {this.state.userThrottled &&
          <Alert severity="error">
            <AlertTitle>
              <FormattedMessage id="microsoft.error.login"/>
              {/* Error ingresando a su cuenta */}
            </AlertTitle>
            {<FormattedMessage id="microsoft.error.failed"/>}
            {/* {"Demasiados intentos de inicio de sesión fallidos. Por favor intente nuevamente en unos minutos."} */}
          </Alert>
        }
        <div className={'login-page'}>
          {this.state.xhr ? (
            <Loader />
          ) : (
            <form className={'login'}>
              <span>
                <FormattedMessage id="login.init"/>
                {/* INICIAR SESIÓN */}
                </span>
              <div className={'inputs'}>
                 <FormattedMessage id="login.user" defaultMessage="Usuario">
                  {placeholder=>  
                     <CustomInput
                      type={'text'}
                      name={'username'}
                      placeholder={placeholder}
                      value={this.state.username}
                      onChange={this.handleChange}
                      invalid={this.state.usernameInvalid}
                      onFocus={this.handleFocus}
                      img={Profile}
                    />
                  }
                </FormattedMessage>  
                <FormattedMessage id="login.pass" defaultMessage="Contraseña">
                  {placeholder=>  
                    <CustomInput
                      type={'password'}
                      name={'password'}
                      placeholder={placeholder}
                      value={this.state.password}
                      onChange={this.handleChange}
                      invalid={this.state.passwordInvalid}
                      onFocus={this.handleFocus}
                      img={Key}
                      error={this.state.error}
                    />
                  }
                </FormattedMessage>             
              </div>
              <CustomBottom
                isChattigo={isChattigo}
                isPelikanus={isPelikanus}
                className={'login-btn'}
                text={<FormattedMessage id="login.enter"/>}
                // text={'Ingresar'}
                onClick={this.handleClick}
                onChange={this.handleChange}
              />
              <MsftSSO
                url={this.props.msAuth ? this.props.msAuth.ms_login_url : "#"}
                text={<FormattedMessage id="login.msAuth"/>}
                // text={"Ingresar con cuenta Microsoft"}
              />
              <Link to={'/forgot-password'}>
                <FormattedMessage id="login.forgot"/>
                {/* Olvidé mi contraseña */}
              </Link>
            </form>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    msAuth: state.msal,
    msMarket: state.msmarket,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ loginUser, loginMsUser, getMSAuthUrl }, dispatch);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginPage));
