import React from 'react'
import './Chat.scss'
import Status from '../Status'
import Anchor from '../../assets/anchor-santander.png'
import AnchorPink from '../../assets/anchor-santander.png'
import {
  Card,
  CardActions,
  CardContent,
  IconButton,
  Collapse,
  Divider,
  List,
  ListItem,
  Grid,
} from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";

const css = {
  root: {
    marginBottom: 10,
    cursor: "pointer",
  },
  active: {
    borderColor: red[600],
  },
  content: {
    color: red[600],
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0px 15px",
    "&:last-child": {
      paddingBottom: 5
    },
  },
  chat: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    '& span': {
      fontSize: 15,
      lineHeight: "18px",
      letterSpacing: 0.4,
      fontWeight: 500,
    },
  },
  iconchat: {
    margin: "auto 18px",
  },
  info: {
    width: "100%",
  },
  duration: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    '& span': {
      fontSize: 14,
      lineHeight: "15px",
    },
  },
  cardaction: {
    marginRight: -15,
    marginLeft: 5,
  },
  extraItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}

const useStyles = makeStyles(theme => (css));

export default function Chat(props) {
  const classes = useStyles();

  let { chat, activeChat, extra } = props;

  let color = 'success';
  let img = Anchor;
  if (!chat.is_final) {
    color = 'pink';
    img = AnchorPink;
  }

  let extra_info = []

  let i = 1;
  let value;
  if (extra) {
    _.forEach(extra, (k, v) => {
      let newKey = v.replace(/_/g, ' ');
      if (k instanceof Array) {
        value = '[' + k.join(', ') + ']';
      } else {
        value = k;
      }
      extra_info.push(
        <ListItem className={classes.extraItem}>
          <Grid container spacing={3}>
            <Grid item xs={2}>
              <span>{newKey[0].toUpperCase() + newKey.slice(1)}</span>
            </Grid>
            <Grid item xs={10}>
              <span>{value !== null ? value.toString() : ""}</span>
            </Grid>
          </Grid>
        </ListItem>
      )
      if (!(i === Object.keys(extra).length))
        extra_info.push(<Divider />)
      i++;
    })
  }

  return (
    <Card className={`${classes.root} ${chat.id === activeChat && classes.active}`} variant="outlined" onClick={props.onClick}>
      <CardContent className={classes.content}>
        <div className={classes.chat}>
          <span>#{chat.case_uuid}</span>
          <ChatOutlinedIcon className={classes.iconchat} style={{ color: chat.is_final ? "#0066CA" : "#F92232" }} />
        </div>
        <div className={classes.info}>
          <Status status={chat.result} color={color} size={'small'} from={'chat'} />
        </div>
        <div className={classes.duration}>
          {chat.duration && <span>{chat.duration}</span>}
        </div>
        <CardActions disableSpacing>
          <IconButton className={classes.cardaction}>
            {chat.id === activeChat ? <ExpandMoreIcon /> : <ChevronRightIcon />}
          </IconButton>
        </CardActions>
      </CardContent>
      <Collapse in={chat.id === activeChat} unmountOnExit>
        <Divider />
        <CardContent>
          {extra_info.length ? (
            <List>
              {_.forEach(extra_info, info => info)}
            </List>
          ) : "No hay información adicional"}
        </CardContent>
      </Collapse>
    </Card>
  )
}
