import React from 'react';
import { FormattedMessage } from 'react-intl';


export const fixTranslateSankey = (node) => {
  if ( typeof node.name ==='string') {
    if (node.name.includes('RECIBIDOS')) {
      node.name = <FormattedMessage id="reporting.cases.Received" values={ {value: node.name.replace('RECIBIDOS', '')}}/>;
      return;
    } else if (node.name.includes('GESTIONADOS')) {
      node.name = <FormattedMessage id="reporting.cases.Processed" values={ {value: node.name.replace('GESTIONADOS', '')}}/>;
      return;
    } else if (node.name.includes('LLAMADA EXITOSA')) {
      node.name = <FormattedMessage id="reporting.cases.successfulCall" values={ {value: node.name.replace('LLAMADA EXITOSA', '')}}/>;
      return;
    } else if (node.name.includes('TITULAR')) {
      node.name = <FormattedMessage id="reporting.cases.ownerTag" values={ {value: node.name.replace('TITULAR', '')}}/>;
      return;
    } else if (node.name.includes('DESCARTADOS')) {
      node.name = <FormattedMessage id="reporting.cases.Discarded" values={ {value: node.name.replace('DESCARTADOS', '')}}/>;
      return;
    } else if (node.name.includes('LLAMADA FALLIDA')) {
      node.name = <FormattedMessage id="reporting.cases.unsuccessfulCall" values={ {value: node.name.replace('LLAMADA FALLIDA', '')}}/>;
      return;
    } else if (node.name.includes('DESCANSO')) {
      node.name = <FormattedMessage id="reporting.cases.resting" values={ {value: node.name.replace('DESCANSO', '')}}/>;
      return;
    } else if (node.name.includes('DATOS ERRÓNEOS')) {
      node.name = <FormattedMessage id="reporting.cases.ithErrors" values={ {value: node.name.replace('DATOS ERRÓNEOS', '')}}/>;
      return;
    } else if (node.name.includes('COMPROMISOS')) {
      node.name = <FormattedMessage id="reporting.cases.commitments" values={ {value: node.name.replace('COMPROMISOS', '')}}/>;
      return;
    } else if (node.name.includes('SIN COMPROMISO')) {
      node.name = <FormattedMessage id="reporting.cases.noCommitments" values={ {value: node.name.replace('SIN COMPROMISO', '')}}/>;
      return;
    } else if (node.name.includes('CON EXCUSA')) {
      node.name = <FormattedMessage id="reporting.cases.excuseWithCommitments" values={ {value: node.name.replace('CON EXCUSA', '')}}/>;
      return;
    } else if (node.name.includes('EXCUSAS')) {
      node.name = <FormattedMessage id="reporting.cases.excuse" values={ {value: node.name.replace('EXCUSAS', '')}}/>;
      return;
    }         
  }
}; 