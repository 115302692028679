import React, { Component } from "react";
import "./CustomInput.scss";
import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputBase from "@material-ui/core/InputBase";
import FormHelperText from "@material-ui/core/FormHelperText";
import { FormattedMessage } from "react-intl";

const styles = {
  bootstrapRootFullWidth: {
    width: "100%"
  },
  bootstrapRoot: {
    width: "85%",
    "label + &": {
      marginTop: "3px"
    }
  },
  bootstrapInput: {
    borderRadius: "0px 4px 4px 0px",
    borderLeft: "none",
    color: "#3C3B4B",
    fontSize: 17,
    padding: "14px 16px",
    "&::placeholder": {
      opacity: 1,
      color: "#777B8D"
    }
  },
  errorInput: {
    borderRadius: "0px 4px 4px 0px",
    color: "#E74A78",
    fontSize: 17,
    padding: "14px 16px",
    "&::placeholder": {
      color: "#E74A78",
      opacity: 1
    }
  },
  input: {
    width: "280px"
  },
  helper: {
    height: 20,
    color: "#E74A78 !important",
    textAlign: "center"
  },
  height: {
    padding: "12px 16px",
    height: "14px",
    borderRadius: "4px 0px 0px 4px",
    color: "#3C3B4B",
    lineHeight: "23px",
    fontSize: 15,
    "&::placeholder": {
      color: "#777B8D",
      opacity: 0.57
    }
  }
};

class CustomInput extends Component {
  render() {
    const {
      classes,
      type,
      placeholder,
      img,
      width,
      name,
      invalid,
      error,
      height,
      extraErrors
    } = this.props;

    let errorMessage;
    if (name === "email") {
      errorMessage = <FormattedMessage id="login.error.email"/>; 
      // "* Email inválido";
    } else if (name === "password") {
      errorMessage = <FormattedMessage id="login.error.date"/>; 
      // "* Los datos ingresados no coinciden";
    } else if (name === "password2") {
      errorMessage = <FormattedMessage id="login.error.pass-coincidence"/>; 
      // "* Las contraseñas no coinciden";
    }

    if(extraErrors){
      if(Array.isArray(extraErrors)){
        errorMessage = '';
        for(let error of extraErrors){
          errorMessage = `${errorMessage}*${error} `
        }
      }
    }

    return (
      <div className={"input-container"}>
        <FormControl
          className={classes.input}
          style={{ width: width && width }}
        >
          <div className={`img-input ${height && "search"}`}>
            {img && !height && <img src={img} alt={"img"}/>}

            <FormattedMessage id="login.error.field" defaultMessage="Campos obligatorios">
              {err=>
                <InputBase
                  onChange={e => this.props.onChange(e.target)}
                  name={name}
                  onFocus={this.props.onFocus}
                  value={this.props.value}
                  type={type}
                  id="bootstrap-input"
                  autoComplete="off"
                  placeholder={invalid ? err : placeholder}
                  classes={{
                    root: img
                      ? classes.bootstrapRoot
                      : classes.bootstrapRootFullWidth,
                    input: height
                      ? classes.height
                      : invalid || error
                        ? classes.errorInput
                        : classes.bootstrapInput
                  }}
                />
              }
            </FormattedMessage> 

            {img && height && <img src={img} alt={"img"}/>}
          </div>
          {name === "email" && (
            <FormHelperText error={error} className={classes.helper}>
              {error && errorMessage}
            </FormHelperText>
          )}
          {name === "password2" && (
            <FormHelperText error={error} className={classes.helper}>
              {error && errorMessage}
            </FormHelperText>
          )}
          {name === "password" && (
            <FormHelperText error={error} className={classes.helper}>
              {error && errorMessage}
            </FormHelperText>
          )}
        </FormControl>
      </div>
    );
  }
}

export default withStyles(styles)(CustomInput);
