import React, { Component } from 'react'
import Add from '../../assets/add-santander.png'
import './Filters.scss'
import Delete from '../../assets/delete-santander.png'
import helpers from '../../lib/helpers'
import _ from 'lodash';
import { FormattedMessage } from 'react-intl'

class Filters extends Component {
  render() {
    let { filter, selected, statusList, status, xhr, inbound } = this.props;
    let values_by_group = {};
    if (!statusList) {
      statusList = [];
    }
    for (status of statusList) {

      if (status.group === '') {
        values_by_group[status.value] = [status.value];
        continue
      }
      if (status.status == "SUCCESS") {
        values_by_group[status.group] = "commitments"
        continue
      }
      if (!values_by_group[status.group]) {
        values_by_group[status.group] = []
      }
      values_by_group[status.group].push(status.value);
    }

    return (
      <div className={'filters-container'}>
        {selected > 0 ? (
          <div className={'filters-content-delete'}>
            <div className={'selected'}>
              <span>{`SELECCIÓN (${selected})`}</span>
            </div>
            <button className={'delete'} onClick={this.props.onDelete} disabled={xhr}>
              <img src={Delete} alt={'Eliminar'} />
              <span>Eliminar Casos</span>
            </button>
          </div>
        ) : (
          <div className={'filters-content'}>
            <div className={'filters'}>
              {statusList && statusList.find(status => status.value !== null) &&
                <button
                  className={filter === 'all' ? 'selected' : undefined}
                  onClick={() => this.props.onClick('all')}
                  disabled={xhr}
                >
                  <FormattedMessage id="filter.all"/>
                  {/* {`TODOS`} */}
                </button>}
              {statusList && statusList.find(status => status.value !== null) && _.map(values_by_group, (values, group) => {
                return <button
                  key={values[0]}
                  className={_.isEqual(values, filter) ? 'selected' : undefined}
                  onClick={() => this.props.onClick(values)}
                  disabled={xhr}
                >{`${group.toUpperCase().replace('_', ' ')}`}</button>
              })}
            </div>
            {/*status && status !== helpers.campaignStatus.closed && selected !== undefined &&
              <div className={'btns'}>
                <button onClick={this.props.onAddNewCase} disabled={xhr}>
                  <img src={Add} alt={'Agregar'} />
                  <span>Agregar un caso</span>
                </button>
            </div>*/}
          </div>
        )}
      </div>
    )
  }
}

export default Filters
