import React, { Component } from "react";
import "./CaseInfo.scss";
import Loader from "../Loader";
import { FormattedMessage } from "react-intl";

class CaseInfo extends Component {
  render() {
    let { name, surname,
      docNumber,
      dueAmount,
      dueDate,
      product,
      xhr } = this.props
    return (
      <div className={'case-info-container'}>
        {xhr ? <Loader /> :
          <div className={'case-info'}>
            <h4>{`${name} ${surname}`}</h4>
            <div className={'info'}>
              <div className={'left'}>
                <div className={'ind'}>
                  <span>
                    <FormattedMessage id="case.ID" />
                    {/* ID Bot */}
                  </span><span>{docNumber ? docNumber : '----'}</span>
                </div>
                <div className={'ind'}>
                  <span>
                    <FormattedMessage id="case.Product" />
                    {/* Producto */}
                  </span><span>{product ? product : '----'}</span>
                </div>
              </div>
              <div className={'right'}>
                <div className={'ind'}>
                  <span>
                    <FormattedMessage id="case.Debt" />
                    {/* Deuda */}
                  </span>
                  <div>{dueAmount ? dueAmount : '----'}</div>
                </div>
                <div className={'ind'}>
                  <span>
                    <FormattedMessage id="case.Exp" />
                    {/* Vto. */}
                  </span><span>{dueDate ? dueDate : '----'}</span>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}

export default CaseInfo;