import React, {Component} from 'react'
import './Export.scss'
import Download from '../../assets/download.png'

class Export extends Component {
    render() {
        const {xhr} = this.props
        return (
            <button className={'export-container'} onClick={this.props.onExport} disabled={xhr}>
                <span>Exportar datos</span><img src={Download} alt={'Exportar'}/>
            </button>
        )
    }
}

export default Export