import React, { Component, Fragment } from 'react'
import './ForgotPasswordPage.scss'
import InitialNavbar from '../../components/InitialNavbar'
import CustomInput from '../../components/CustomInput'
import CustomButton from '../../components/CustomButton'
import { withRouter, Link } from 'react-router-dom'
import validators from '../../lib/validators'
import Email from '../../assets/email.png'
import urls from '../../lib/urls'
import axios from 'axios'
import Loader from '../../components/Loader'
import { FormattedMessage } from 'react-intl'

class ForgotPasswordPage extends Component {
  constructor() {
    super()

    this.state = {
      sent: false,
      email: '',
      emailInvalid: false,
      emailError: false,
      xhr: false,
      isChattigo:false,
      isPelikanus: false,
    }
  }

  handleClick = e => {
    e.preventDefault()
    let {email} = this.state

    if (this.state.sent) {
      this.props.history.push('/')
    } else {
      if (validators.general(email) || validators.email(email)) {
        this.setState({
          emailInvalid: true,
          emailError: true
        })
      } else {
        const email_url = `${urls.API_ROOT}/api/v1/password_forgot/`;
        this.setState({xhr: true})
        axios
          .post(email_url, {email})
          .then((response) => {this.setState({
            sent: true,
            xhr: false
          })})
          .catch((error) => {
              if (error.response.status === 412) {
                this.setState({
                  emailInvalid: true,
                  emailError: true,
                  xhr: false
                })
              } else {
                console.log(error)
              }
          });
      }
    }
  }

  handleChange = (target) => {
    const { name, value } = target
    this.setState({
      [name]: value
    })
  }

  handleFocus = name => {
    this.setState({
      [name]: false
    })
  }

  componentDidMount() {
    const Url = window.location.hostname;
     if (Url==='chattigo.inceptia.ai') {
     this.setState({isChattigo:true}) 
    }    
    if (Url==='pelikanus.inceptia.ai') {
      this.setState({isPelikanus:true})
    } 
  }

  render() {
    const {isChattigo,isPelikanus}= this.state;
    return (
      <div className={
        isChattigo?'forgot-password-page-container background-chattigo'
        :isPelikanus?'forgot-password-page-container background-pelikanus'
        :'forgot-password-page-container background-inceptia'
      }>
        <InitialNavbar isChattigo={isChattigo} isPelikanus={isPelikanus}/>
        <div className={'forgot-password-page'}>
          {this.state.sent ? (
            <form className={'forgot-password sent'}>
              <img src={Email} alt={'Enviado'} />
              <span>
                <FormattedMessage id="forgot.send"/>
                {/* ENVIADO! */}
              </span>
              <p className={'forgot-password-p'}>
                <FormattedMessage id="forgot.text.custom"/>
                {/* Si no recibe un correo, revise su casilla de spam y
                <br />
                asegúrese de haber ingresado el e-mail correcto */}
              </p>
              <CustomButton
                className={'forgot-password-btn'}
                text={<FormattedMessage id="login.error.accept"/>}
                // text={'Aceptar'}
                onClick={this.handleClick}
              />
            </form>
          ) : (
            <Fragment>
              {this.state.xhr ? (
                <Loader/>
                ) : (
                <form className={'forgot-password'}>
                  <span>
                    <FormattedMessage id="forgot.title"/>
                    {/* ¿HA OLVIDADO SU CLAVE? */}
                  </span>

                  <FormattedMessage id="forgot.input" defaultMessage="Ingrese su e-mail">
                  {placeholder=>  
                    <CustomInput
                      type={'email'}
                      placeholder={placeholder}
                      value={this.state.email}
                      name={'email'}
                      onChange={this.handleChange}
                      invalid={this.state.emailInvalid}
                      error={this.state.emailError}
                      onFocus={() => {
                        this.handleFocus('emailInvalid')
                        this.handleFocus('emailError')
                      }}
                      width={'360px'}
                      img={Email}
                    />                   
                  }
                </FormattedMessage> 

                  <p className={'forgot-password-p'}>
                     <FormattedMessage id="forgot.text"/>
                    {/* Le enviaremos las instrucciones para
                    <br />
                    establecer una nueva clave */}
                  </p>
                  <CustomButton
                    isChattigo={isChattigo}
                    isPelikanus={isPelikanus}
                    className={'forgot-password-btn'}
                    text={'Continuar'}
                    name={'email'}
                    onClick={this.handleClick}
                  />
                  <Link to={'/'}>
                    <FormattedMessage id="forgot.back"/>
                    {/* Volver */}
                  </Link>
                </form>
              )}
            </Fragment>
          )}
        </div>
      </div>
    )
  }
}

export default withRouter(ForgotPasswordPage)
