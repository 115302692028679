import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import "./Status.scss";

class Status extends Component {
  render() {
    let { status, size, color, statusList, from,isDone } = this.props;

    if (from === 'table' && status) {
      return <div className={`status-value-container ${color}`}>
        <span>{status.toUpperCase()}</span>
      </div>;
    }
    if (from == 'export' && status) {      
      if (isDone !=='DONE') {
        return (
          <div className={'status-value-container-export disabled'} disabled>
            <span>{status}</span>
          </div>
        )
      }
      return <div className={'status-value-container-export'}>
        <span>{status}</span>
      </div>;
    }

    let statusClassname = ''

    if ((from === 'card' || from === 'campaign') && statusList && statusList[status]) {
      statusClassname = status.toLowerCase()
      status = statusList[status].status
    }

    return (
      <div className={`status-container ${statusClassname} ${color} ${size}`}>
        <div className={"status"}>

          <FormattedMessage id={`campaign.${status}`} defaultMessage={status}>
            {stat=>  
             <span>
              {status && stat}
            </span>}
          </FormattedMessage> 

          {/* <span>
            {status &&
            
            <FormattedMessage id={`campaign.${status}`}/>
            //  status.toUpperCase()
             }
            </span> */}
        </div>
      </div>
    );
  }
}

Status.defaultProps = {
  color: '',
  size: ''
}

export default Status;



