/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable indent */
import React, { Component } from 'react'
import './BotSidebar.scss'
import Bot from './Bot'
import EmptyState from '../EmptyState'
import Loader from "../Loader";

class BotSidebar extends Component {

    shouldComponentUpdate(nextProps, nextState) {
        let { activeBot, xhr } = this.props;
        let { activeBot: incomingActiveBot, xhr: incomingXhr } = nextProps;
        return activeBot !== incomingActiveBot || xhr !== incomingXhr;
    }  

    render() {
        let { bots, activeBot, xhr,isChattigo,isPelikanus } = this.props;

        return (
            <div className={isChattigo?'bot-sidebar-container-chattigo': isPelikanus?'bot-sidebar-container-pelikanus': 'bot-sidebar-container'}>
                <div className={'bot-sidebar'}>
                    <div className={'bot-sidebar-title'}>
                        <span>BOT</span>
                    </div>
                    <div className={'bots'}>
                        {!xhr && bots.map((company) => {
                            return <Bot key={company.id} company={company} onClick={() => this.props.onClick(company.id)} activeBot={activeBot} />
                        })}
                        {!bots.length && !xhr &&
                            <EmptyState text={'No hay bots disponibles'} />
                        }
                        {xhr && <Loader />}
                    </div>
                </div>
            </div>
        )
    }
}

export default BotSidebar