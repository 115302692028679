import React, { Component, Fragment } from "react";
import {
  XYPlot,
  XAxis,
  YAxis,
  ChartLabel,
  HorizontalGridLines,
  VerticalGridLines,
  LineSeries,
  VerticalBarSeries,
  Crosshair,
  DiscreteColorLegend
} from 'react-vis';
import _ from 'lodash';

import EmptyState from "../EmptyState";
import "./ReportingInbound_general.scss";
// import '../node_modules/react-vis/dist/style.css';
import TableGeneral from "../TableGeneral";
import TableDetail from "../TableDetail";
import Grid from "@material-ui/core/Grid";
import ReportingInboundCard from "./ReportingInboundCard";

class ReportingInbound_general extends Component {

  constructor(props) {
    super(props)
    this.state = {
      crosshairValues: [],
      graph_data: {
        received_dataseries: [],
        resting_dataseries: [],
        managed_dataseries: [],
        contacted_dataseries: [],
        contacted_p_dataseries: [],
        promise_dataseries: [],
        promise_p_dataseries: []
      }
    }
  }

  reportingDataToTable = (reportingData) => {
    let reportingData_grouped = _.groupBy(reportingData, 'modified_date');
    let daily_result_list = [];
    let resting_detail = [];
    let last_day = Object.keys(reportingData_grouped)[Object.keys(reportingData_grouped).length - 1]
    _.forEach(reportingData_grouped, (result_info, date_row,) => {
      let received = 0;
      let resting_cases = 0;
      let managed_cases = 0;
      let contacted_cases = 0;
      let owner_cases = 0
      let promise_cases = 0;
      let owner_result_ids = [9, 10, 16, 17]

      _.forEach(result_info, (info) => {
        received += info.total_results + info.total_rest_results
        if (info.rest_result) {
          resting_cases += info.total_rest_results
          if (last_day == date_row) {
            resting_detail.push(
              {
                name: info.rest_result,
                count: info.total_rest_results
              }
            )
          }
        } else {
          managed_cases += info.total_results
        }
        if (info.result_id > 2) {
          contacted_cases += info.total_results
          if (!owner_result_ids.includes(info.result_id)) {
            owner_cases += info.total_results
          }
        }
        if (info.got_promise) {
          promise_cases += info.total_results
        }
      });
      let contacted_cases_p = contacted_cases / managed_cases
      let promise_cases_p = promise_cases / owner_cases
      daily_result_list.push({
        day: date_row.substring(5),
        received: received,
        resting: resting_cases,
        managed: managed_cases,
        contacted: contacted_cases,
        contacted_p: contacted_cases_p,
        promise: promise_cases,
        promise_p: promise_cases_p
      });
      if (last_day == date_row) {
        resting_detail.push(
          {
            name: "Total",
            count: resting_cases
          }
        )
      }

    });
    return { daily_result_list: daily_result_list, resting_detail: resting_detail }
  }

  calculateTotals = (daily_result_list) => {
    let daily_result_list_with_totals = daily_result_list.slice()
    let received = 0;
    let resting_cases = 0;
    let managed_cases = 0;
    let contacted_cases = 0;
    let owner_cases = 0
    let promise_cases = 0;
    let owner_result_ids = [9, 10, 16, 17]
    _.forEach(this.props.totalreportingData, (info) => {
      received += info.total_results + info.total_rest_results
      if (info.rest_result) {
        resting_cases += info.total_rest_results
        // if (info.rest_result_id == 23) {
        //   promise_cases += info.total_rest_results
        // }
      } else {
        managed_cases += info.total_results
      }
      if (info.result_id > 2) {
        contacted_cases += info.total_results
        if (!owner_result_ids.includes(info.result_id)) {
          owner_cases += info.total_results
        }
      }
      if (info.got_promise) {
        promise_cases += info.total_results
      }
    });
    let contacted_cases_p = contacted_cases / managed_cases
    let promise_cases_p = promise_cases / owner_cases
    daily_result_list_with_totals.push({
      day: 'Total',
      received: received,
      resting: resting_cases,
      managed: managed_cases,
      contacted: contacted_cases,
      contacted_p: contacted_cases_p,
      promise: promise_cases,
      promise_p: promise_cases_p
    });
    return daily_result_list_with_totals
  }

  /**
   * Event handler for onMouseLeave.
   * @private
   */
  _onMouseLeave = () => {
    this.setState({ crosshairValues: [] })
  }
  /**
   * Event handler for onNearestX.
   * @param {Object} value Selected value.
   * @param {index} index Index of the value in the data array.
   * @private
   */
  _onNearestX = (value, { index }) => {
    this.setState({
      crosshairValues: [
        this.state.graph_data.received_dataseries[index],
        this.state.graph_data.resting_dataseries[index],
        this.state.graph_data.managed_dataseries[index],
        this.state.graph_data.contacted_dataseries[index],
        this.state.graph_data.contacted_p_dataseries[index],
        this.state.graph_data.promise_dataseries[index],
        this.state.graph_data.promise_p_dataseries[index]
      ]
    })
  }
  _itemsDataFormat = (crosshairValues_array) => (
    [
      { title: "Recibidos", value: crosshairValues_array[0].y },
      { title: "Descanso", value: crosshairValues_array[1] }.y,
      { title: "Gestionados", value: crosshairValues_array[2].y },
      { title: "Contactados", value: crosshairValues_array[3].y },
      { title: "Contactados%", value: `${(crosshairValues_array[4].y * 100).toFixed(2)}%` },
      { title: "Compromisos", value: crosshairValues_array[5].y },
      { title: "Compromisos%", value: `${(crosshairValues_array[6].y * 100).toFixed(2)}%` }

    ]
  )
  _titleFormat = (crosshairValues_array) => (
    { title: "", value: crosshairValues_array[0] }
  )

  render() {
    let { reportingData, casesCount, resultFilter, activeFilter, fromDate, toDate, page, activeBot, xhr } = this.props;

    let tabledata = this.reportingDataToTable(reportingData)
    let emptyState = _.isEmpty(tabledata.daily_result_list);
    let tabledata_detail = []
    let last_result = {}
    if (!emptyState) {
      tabledata_detail = this.calculateTotals(tabledata.daily_result_list)
      last_result = tabledata_detail[tabledata_detail.length - 1]
      this.state.graph_data = {
        received_dataseries: [],
        resting_dataseries: [],
        managed_dataseries: [],
        contacted_dataseries: [],
        contacted_p_dataseries: [],
        promise_dataseries: [],
        promise_p_dataseries: []
      }
      _.forEach(tabledata.daily_result_list, (item, i) => {
        this.state.graph_data.received_dataseries.push({ x: item.day, y: item.received })
        this.state.graph_data.resting_dataseries.push({ x: item.day, y: item.resting })
        this.state.graph_data.managed_dataseries.push({ x: item.day, y: item.managed })
        this.state.graph_data.contacted_dataseries.push({ x: item.day, y: item.contacted })
        this.state.graph_data.contacted_p_dataseries.push({ x: item.day, y: item.contacted_p })
        this.state.graph_data.promise_dataseries.push({ x: item.day, y: item.promise })
        this.state.graph_data.promise_p_dataseries.push({ x: item.day, y: item.promise_p })
      });
    }
    return (
      <div className={"bot-campaign-reporting-general-body"}>
        <Grid container spacing={2}>
          {emptyState ?
            <Grid item xs={12}>
              <EmptyState text={"No hay información disponible"} />
            </Grid>
            :
            <Fragment>
              <Grid item xs={2}>
                <ReportingInboundCard title={"ID Cliente Recibidos"} val={last_result.received} />
              </Grid>
              <Grid item xs={2}>
                <ReportingInboundCard title={"Gestionados"} val={last_result.managed} />
              </Grid>
              <Grid item xs={2}>
                <ReportingInboundCard title={"Contactados"} val={last_result.contacted} />
              </Grid>
              <Grid item xs={2}>
                <ReportingInboundCard title={"Contactados%"} val={`${(last_result.contacted_p * 100).toFixed(2)}%`} />
              </Grid>
              <Grid item xs={2}>
                <ReportingInboundCard title={"Compromisos"} val={last_result.promise} />
              </Grid>
              <Grid item xs={2}>
                <ReportingInboundCard title={"Compromisos%"} val={`${(last_result.promise_p * 100).toFixed(2)}%`} />
              </Grid>
            </Fragment>
          }
          <Grid item xs={6}>
            <TableGeneral cases={tabledata_detail}
              xhr={xhr}
              casesCount={casesCount}
              onTableChange={this.props.onTableChange}
              page={page}
              selectedCheckboxes={[]}
              checkAll={false}
              from={"home"}
              reportingFromDate={fromDate}
              reportingToDate={toDate}
              activeReportingFilter={activeFilter}
              activeSection={"reports"}
              activeBot={activeBot}
              resultFilter={resultFilter}
              onExport={this.props.onExport}
            />
          </Grid>
          {emptyState ?
            <Grid item xs={6}>
              <EmptyState text={"No hay información disponible"} />
            </Grid>
            :
            <Grid item xs={6}>
              <div style={{ position: 'relative' }}>
                <div>
                  <XYPlot height={400} width={740} xType="ordinal" margin={{ right: 180, bottom: 70 }}>
                    <VerticalGridLines />
                    <HorizontalGridLines />
                    <XAxis tickLabelAngle={-45} />
                    <YAxis />
                    <DiscreteColorLegend
                      style={{ position: 'absolute', right: '0px', top: '10px' }}
                      orientation="vertical"
                      items={[
                        {
                          title: 'Descanso',
                          color: '#A31621'
                        },
                        {
                          title: 'Recibidos',
                          color: '#FF0000'
                        },
                        {
                          title: 'Gestionados',
                          color: '#BFDBF7'
                        },
                        {
                          title: 'Contactados',
                          color: '#053C5E'
                        },
                        {
                          title: 'Compromisos',
                          color: '#1F7A8C'
                        },
                        {
                          title: 'Contactados%',
                          color: '#22181C'
                        },
                        {
                          title: 'Compromisos%',
                          color: '#F45B69'
                        }
                      ]}
                    />
                    <VerticalBarSeries color="#A31621" data={this.state.graph_data.resting_dataseries} />
                    <VerticalBarSeries color="#FF0000" data={this.state.graph_data.received_dataseries} />
                    <VerticalBarSeries color="#BFDBF7" data={this.state.graph_data.managed_dataseries} />
                    <VerticalBarSeries color="#053C5E" data={this.state.graph_data.contacted_dataseries} />
                    <VerticalBarSeries color="#1F7A8C" data={this.state.graph_data.promise_dataseries} />
                    <Crosshair
                      values={this.state.crosshairValues}
                      itemsFormat={this._itemsDataFormat}
                    />
                  </XYPlot>
                </div>
                <div style={{ position: 'absolute', top: 0 }}>
                  <XYPlot onMouseLeave={this._onMouseLeave} height={400} width={740} yDomain={[0, 1]} xType="ordinal" margin={{ right: 180, bottom: 70 }}>
                    <YAxis orientation="right" tickFormat={v => `${(v * 100).toFixed(2)}%`} />
                    <LineSeries color="#22181C" onNearestX={this._onNearestX} data={this.state.graph_data.contacted_p_dataseries} />
                    <LineSeries color="#F45B69" data={this.state.graph_data.promise_p_dataseries} />
                  </XYPlot>
                </div>
              </div>
            </Grid>
          }
          <Grid item xs={3}>
            <TableDetail cases={tabledata.resting_detail}
              xhr={xhr}
              casesCount={casesCount}
              onTableChange={this.props.onTableChange}
              page={page}
              selectedCheckboxes={[]}
              checkAll={false}
              from={"home"}
              reportingFromDate={fromDate}
              reporingToDate={toDate}
              activeReportingFilter={activeFilter}
              activeSection={"reports"}
              activeBot={activeBot}
              resultFilter={resultFilter}
              onExport={this.props.onExport}
            />
          </Grid>
        </Grid>
      </div>
    )
  }
}
export default ReportingInbound_general;
