import { combineReducers } from "redux";
import userReducer from "./modules/user/reducer";
import uiReducer from "./modules/ui/reducer";
import msalReducer from "./modules/msal/reducer";
import msMarketReducer from "./modules/msmarket/reducer";
import botsReducer from "./modules/bots/reducer";
import casesReducer from "./modules/cases/reducer";
import detailsReducer from "./modules/details/reducer";

const rootReducer = combineReducers({
  user: userReducer,
  ui: uiReducer,
  msal: msalReducer,
  msmarket: msMarketReducer,
  bots: botsReducer,
  cases: casesReducer,
  details: detailsReducer,
});

export default rootReducer;
