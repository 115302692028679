import React from "react";
import Calendar from "../assets/calendar-santander.png";
import Phone from "../assets/phone-santander.png";
import Duration from "../assets/duration-santander.png";
import Status from "../components/Status";
import formatters from "./formatters";
import { spacing } from "@mui/system";
import { FormattedMessage } from 'react-intl';


export const groups = {
  1: "ADMINISTRATOR",
  2: "MANAGER",
  3: "REPORT"
};

const helpers = {
  dialmodes: {
    vertical: "VERTICAL",
    horizontal_vertical: "HORIZONTAL_VERTICAL",
    horizontal_horizontal: "HORIZONTAL_HORIZONTAL"
  },
  groups: {
    [groups[1]]: "ADMINISTRATOR",
    [groups[2]]: "MANAGER",
    [groups[3]]: "REPORT"
  },

  groupsDisplay: {
    [groups[1]]: "Admin",
    [groups[2]]: "Manager",
    [groups[3]]: "Lector"
  },
  campaignStatus: {
    onGoing: 'ONGOING',
    paused: 'PAUSED',
    scheduled: 'SCHEDULED',
    closed: 'CLOSED'
  },
  inboundStatus: {
    onGoing: 'ONGOING',
    closed: 'CLOSED'
  },
  getCallsValue: (count, duration) => {
    return (
      <div className={"call"}>
        <div className={"call-count"}>
          <img src={Phone} alt={"Llamadas"} /> <span>{count ? count : '-'}</span>
        </div>
        <div className={"call-duration"}>
          <img src={Duration} alt={"Duarción"} /> <span>{duration ? duration.substr(0, 8) : '--:--:--'}</span>
        </div>
      </div>
    );
  },
  getInboundCallValue: (duration) => {
    return (
      <div className={"call"}>
        <div className={"call-duration"}>
          <img src={Duration} alt={"Duración"} /> <span>{duration ? duration.substr(0, 8) : '--:--:--'}</span>
        </div>
      </div>
    );
  },
  getCommitmentValue: value => {
    return (
      <div className={"compromise"}>
        <img src={Calendar} alt={"Compromiso"} />
        <span>{!value ? "----" : formatters.dateTimeFormatter(value)}</span>
      </div>
    );
  },
  getCallEndValue: (is_completed, value) => {
    return (
      <div className={"callEndDateTime"}>
        <img src={Calendar} alt={"Fecha y Hora de Gestión"} />
        <span>{(!value || !is_completed) ? "----" : value}</span>
      </div>
    )
  },
  getStatusValue: (status, is_final, got_promise) => {
    if (status) {
      return <Status status={status} color={got_promise ? 'green' : is_final ? 'contacted' : 'pink'} from={'table'} />
    } else {
      return '----'
    }
  },
  getStyleExport: (name,isDone) => {
    if (name) {
      return <Status isDone={isDone} status={name} from={'export'} />
    } else {
      return '----'
    }
  },
  getInboundStatusValue: (status, is_final) => {
    if (status) {
      return <Status status={status} color={is_final ? 'contacted' : 'pink'} from={'table'} />
    } else {
      return '----'
    }
  },
  getCheckbox: (from, checkAll, selected, cursor) => {
    return (
      <div className={"table-checkbox"}>
        <div
          className={`checkbox-content ${from} ${selected} ${checkAll ? "selected" : undefined
          } ${cursor}`}
        >
          <div className={"checkbox"} />
        </div>
      </div>
    );
  },
  handleTokenExpiration: () => {
    localStorage.clear();
    window.location = '/';
  }
};

export default helpers;

export function errorParser(error) {
  let errors = [];
  let { response } = error;
  if (response && response.status && response.status === 400) {
    let keys = Object.keys(response.data);
    for (let i in keys) {
      errors.push(response.data[keys[i]].join(' '));
    }
  }
  return errors;
}

export const arrayFiltersParser = (filters) => {
  let arrayReturn = [];
  for(const [key, value] of Object.entries(filters)){
    if (value!=='') {
      switch (key) {
      case 'from':
        break;
      case 'to':
        arrayReturn.push(<FormattedMessage id="export.from" />,`: ${formatters.dateTimeFormatter(filters.from)} `,<FormattedMessage id="export.to" />,`: ${formatters.dateTimeFormatter(filters.to)}`);
        arrayReturn.push(' - ');
        break;
      case 'result_id':
        value.forEach(function(v) {
          arrayReturn.push(`${v}`);
          arrayReturn.push(' - ');
        });
        break;
      case 'campaign_id':
        break;
      case 'campaign':
        arrayReturn.push(`${filters.campaign}`);
        arrayReturn.push(' - ');
        break;
      case 'search':
        arrayReturn.push(`${value}`);
        arrayReturn.push(' - '); 
        break;          
      default:
      }      
    }
  }
  if (arrayReturn[arrayReturn.length-1] === ' - ') arrayReturn.pop();
  if (arrayReturn.length===0) arrayReturn.push(' - ');
  return arrayReturn;

};

export const languageStatus = (status) => {
  switch (status) {
  case 'DONE':
    return <FormattedMessage id="export.DONE" />;  
  case 'RECEIVED_WITH_ERRORS':
    return <FormattedMessage id="export.RECEIVED_WITH_ERRORS" />;  
  case 'RECEIVED':
    return <FormattedMessage id="export.RECEIVED" />;  
  case 'IN_PROCESS':
    return <FormattedMessage id="export.IN_PROCESS" />;  
  default:
    break;
  }


};
