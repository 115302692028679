import { 
  CAMPAIGN_STATUS_SUCCESS,
  CAMPAIGN_STATUS_FAILURE,
  CAMPAIGN_STATUS_REQUEST,
  INBOUND_CASE_STATUS_REQUEST,
  INBOUND_CASE_STATUS_SUCCESS,
  INBOUND_CASE_STATUS_FAILURE
} from "./actions";

export default function(state = {}, action) {
  switch (action.type) {
    case CAMPAIGN_STATUS_REQUEST: {
      return {
        ...state,
        isLoading: true
      }
    }

    case CAMPAIGN_STATUS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        ui: {...state.ui, ...action.payload}
      }
    }

    case CAMPAIGN_STATUS_FAILURE: {
      return {
        ...state,
        isLoading: false,
      }
    }

    case INBOUND_CASE_STATUS_REQUEST: {
      return {
        ...state,
        isLoading: true
      }
    }

    case INBOUND_CASE_STATUS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        ui: {...state.ui, ...action.payload}
      }
    }

    case INBOUND_CASE_STATUS_FAILURE: {
      return {
        ...state,
        isLoading: false
      }
    }

    default:
      return state
  }
}

