import React, { Component } from "react";
import fileDownload from "js-file-download";
import {
  fetchCampaignStatuses,
  fetchInboundCaseStatuses,
} from "../../modules/ui/actions";
import { storeBots } from "../../modules/bots/actions";
import { storeCasesDeleted } from "../../modules/cases/actions";
import { storeDetailsDeleted } from "../../modules/details/actions";
import "./HomePage.scss";
import Sidebar from "../../components/BotSidebar";
import BotCampaignNavbar from "../../components/BotCampaignNavbar";
import BotCampaign from "../../components/BotCampaign";
import CustomModal from "../../components/CustomModal";
import validators from "../../lib/validators";
import urls from "../../lib/urls";
import { tokenHeader } from "../../lib/headers";
import Loader from "../../components/Loader";
import voca from "voca";
import _ from "lodash";
import moment from "moment";
import { instance as axios } from "../../lib/axios";
import helpers from "../../lib/helpers";
import { connect } from "react-redux";
import { isIE } from "react-device-detect";
import { bindActionCreators } from "redux";
import Reporting from "../../components/Reporting/Reporting";
import ReportingInbound from "../../components/ReportingInbound/ReportingInbound";
import BotInboundNavbar from "../../components/BotInboundNavbar";
// import BotInbound from "../../components/BotInbound";


const state = {
  modal: false,
  titleInput: {
    name: "titleInput",
    value: "",
    isValid: true,
    validator: "general",
  },
  descriptionTextarea: {
    name: "descriptionTextarea",
    value: "",
    isValid: true,
    validator: "general",
  },
  file: { name: "file", value: [], isValid: true, validator: "file" },
  exclusionsFile: {
    name: "exclusionsFile",
    value: [],
    isValid: true,
    validator: "file",
  },
  contactability: {
    name: "contactability",
    value: "",
    isValid: true,
    validator: "number1to100",
  },
  sent: false,
  confirmed: false,
  cases: 213,
  maxCalls: 1,
  from: "09:00",
  to: "21:00",
  selected: "now",
  showDatePicker: false,
  date: moment(new Date()).format("YYYY-MM-DD"),
  reportingFromDate: moment(new Date()).startOf("week"),
  reportingToDate: moment(new Date()),
  inboundReportingFromDate: moment(new Date()).startOf("week"),
  inboundReportingToDate: moment(new Date()),
  timeInvalid: false,
  taskId: null,
  campaignId: null,
  campaignName: null,
  receivedWithErrors: false,
  processedWithErrors: false,
  network_failed: false,
  modalXhr: false,
  reportingFilterDict: {
    year: "año",
    month: "mes",
    week: "semana",
    day: "día",
  },
};

class HomePage extends Component {
  constructor() {
    super();

    this.state = {
      ...state,
      timezoneBot: '',
      isChattigo: false,
      isPelikanus: false,
      xhr: false,
      activeBot: null,
      campaigns: null,
      inboundCases: null,
      botCases: null,
      selectedBotCampaignCases: [],
      selectedBotCampaigns: [],
      selectedBotInboundCases: [],
      selectedBotDashboards: [],
      allBotCampaigns: [],
      allBotInboundCases: null,
      loadingBots: false,
      sort: false,
      search: "",
      inboundSearch: "",
      rutSearch: "",
      page: 1,
      casesCount: 0,
      inboundCasesCount: 0,
      axiosSource: false,
      onReporting: false,
      onInboundReporting: false,
      reportingData: null,
      inboundReportingData: null,
      totalreportingData: null,
      activeFilter: "all",
      caseResultFilter: "Compromete Fecha",
      inboundCaseResultFilter: "all",
      commitmentFilter: null,
      casesStatusList: null,
      inboundCasesStatus: null,
      inboundCasesResultList: null,
      activeReportingFilter: "day",
      activeInboundReportingFilter: "day",
      activeSection: "reports",
      input: "",
      campaignsResultsIds: null,
      resultsFilterArray: [],
      phonePriorities: {},
      maxPhonePriorities: {},
      dialmode: helpers.dialmodes.horizontal_vertical,
      closes_eod: false,
      selectedCycles: [],
      availableCycles: [],
      canSelectCycles: false,
      shouldDownloadFile: false,
      nextCampaignPage: null,
      botHasMoreCampaigns: true,
      loadingCampaigns: false,
      errorLoadingCampaigns: false,
      columns_to_show: {},
    };
  }

  handleSelect = (val) => {
    this.setState({
      selected: val,
      showDatePicker: val !== "now",
    });
  };

  handleConfirm = async (e) => {
    e.preventDefault();
    const { timeInvalid } = this.state;
    if (!timeInvalid) {
      this.setState({ modalXhr: true });
      await this.handleCampaignCreation();
    }
  };

  handleDeleteCampaign = () => {
    this.handleCancel();
  };

  handleTimeChange = (val) => {
    const from = val.startTime.label;
    const to = val.endTime.label;
    this.setState({ from, to, timeInvalid: from >= to || to <= from });
  };

  handleTimeError = (error) => {
    this.setState({ timeInvalid: error });
  };

  handleAdd = (e) => {
    e.preventDefault();
    let newMax = this.state.maxCalls + 1;
    this.setState({
      maxCalls: newMax,
    });
  };

  handleRest = (e) => {
    e.preventDefault();
    if (this.state.maxCalls === 1) {
      return;
    }
    let newMax = this.state.maxCalls - 1;
    this.setState({
      maxCalls: newMax,
    });
  };

  handleDeleteFile = (e) => {
    e.preventDefault();
    this.setState({
      file: { ...this.state.file, value: [] },
    });
  };

  handleDeleteExclusionsFile = (e) => {
    e.preventDefault();
    this.setState({
      exclusionsFile: { ...this.state.exclusionsFile, value: [] },
    });
  };

  handleValidation = (name) => {
    let input = this.state[name];
    const validator = validators[input.validator];
    const isValid = !validator(input.value);

    if (isValid)
      this.setState((prevState) => ({
        [name]: { ...prevState[name], isValid },
      }));
  };

  handleValidations = () => {
    const { titleInput, descriptionTextarea, file, contactability } =
      this.state;
    const inputs = [titleInput, descriptionTextarea, file, contactability];

    const areAllInputsValid = inputs.every((input) => {
      const validator = validators[input.validator];
      const isValid = !validator(input.value);

      this.setState((prevState) => ({
        [input.name]: { ...prevState[input.name], isValid },
      }));

      return isValid;
    });

    return areAllInputsValid;
  };

  handleClose = () => {
    let { phonePriorities, activeBot } = this.state;
    this.createNewResultFilterArray();
    phonePriorities[activeBot] = 0;
    this.setState({ ...state, phonePriorities, network_failed: false });
  };

  handleCancel = async () => {
    let { phonePriorities, activeBot } = this.state;
    this.createNewResultFilterArray();
    phonePriorities[activeBot] = 0;
    this.setState({ ...state, phonePriorities });
    await this.fetchCampaigns();
    await this.getBotCampaigns();
  };

  handleBack = (e) => {
    e.preventDefault();
    this.setState({
      ...this.state,
      sent: false,
      receivedWithErrors: null,
      file: state.file,
    });
  };

  handleStatus = async (status, data) => {
    switch (status) {
      case "RECEIVED": {
        await this.getTaskStatus("VALIDATED");
        break;
      }
      case "RECEIVED_WITH_ERRORS": {
        this.setState({
          xhr: false,
          modalXhr: false,
          receivedWithErrors: data,
        });
        break;
      }
      case "VALIDATED": {
        const cases = data.result.to_create.length;
        const availableCycles = data.result.cycles;
        this.setState({
          xhr: false,
          modalXhr: false,
          cases,
          sent: true,
          availableCycles,
        });
        break;
      }
      case "IN_PROCESS": {
        await this.getTaskStatus("DONE");
        break;
      }
      case "PROCESSED_WITH_ERRORS": {
        this.setState({
          xhr: false,
          modalXhr: false,
          processedWithErrors: data,
        });
        break;
      }
      case "DONE": {
        this.setState({ xhr: false, modalXhr: false, confirmed: true });
        break;
      }
    }
  };

  getTaskStatus = async (desiredStatus) => {
    const { taskId: id, shouldDownloadFile } = this.state;
    const taskUrl = `${urls.API_ROOT}/api/v1/tasks/${id}/`;
    try {
      const res = await axios.get(taskUrl, tokenHeader());
      const { data } = res;
      const { status } = data;
      let mostRecentLog = _.orderBy(data.logs, "created_at", "desc")[0];
      let timer;
      if (
        status === "RECEIVED_WITH_ERRORS" ||
        status === "PROCESSED_WITH_ERRORS"
      ) {
        return this.handleStatus(status, mostRecentLog);
      }
      if (status !== desiredStatus) {
        timer = setTimeout(() => this.getTaskStatus(desiredStatus), 3000);
        return timer;
      }
      if (shouldDownloadFile) {
        await this.handleFilteredFileDownlad(id);
      }
      clearTimeout(timer);
      return this.handleStatus(status, mostRecentLog);
    } catch (error) {
      console.log(error);
    }
  };

  handleCampaignCreation = async () => {
    const campaignCreationUrl = `${urls.API_ROOT}/api/v1/campaigns/`;
    const {
      titleInput: title,
      descriptionTextarea: description,
      activeBot: bot_id,
      maxCalls: max_calls_per_case,
      from: call_from,
      to: call_to,
      date,
      contactability,
      dialmode: dialing_type,
      closes_eod,
      phonePriorities,
      maxPhonePriorities,
    } = this.state;
    const start_date = moment(date).format("YYYY-MM-DD");
    let phonePriority = phonePriorities[bot_id];
    let min_phone_priority =
      dialing_type === helpers.dialmodes.vertical ? phonePriority : 0;
    let max_phone_priority =
      dialing_type === helpers.dialmodes.vertical
        ? phonePriority
        : maxPhonePriorities[bot_id];
    this.setState({ modalXhr: true });
    try {
      const res = await axios.post(
        campaignCreationUrl,
        {
          name: title.value,
          description: description.value,
          contactability: contactability.value,
          bot_id: bot_id,
          max_calls_per_case,
          call_from,
          call_to,
          start_date,
          dialing_type,
          closes_eod,
          min_phone_priority,
          max_phone_priority,
        },
        tokenHeader()
      );
      const { id, name } = res.data;
      this.setState({ campaignId: id, campaignName: voca.slugify(name) });
      await this.handleCases(id);
    } catch (error) {
      console.log(error);
    }
  };

  handleCases = async (campaign_id) => {
    const {
      taskId,
      activeBot,
      phonePriorities,
      maxPhonePriorities,
      exclusionsFile,
      selectedCycles,
      shouldDownloadFile,
      contactability,
      dialmode: dialing_type,
    } = this.state;
    const { bots } = this.props;
    const bot_name = _.filter(bots, { id: activeBot })[0].name;
    const phone_priority = phonePriorities[activeBot];
    let min_phone_priority =
      dialing_type === helpers.dialmodes.vertical ? phone_priority : 0;
    let max_phone_priority =
      dialing_type === helpers.dialmodes.vertical
        ? phone_priority
        : maxPhonePriorities[activeBot];
    const campaignCasesUrl = `${urls.API_ROOT}/api/v1/tasks/${taskId}/`;
    const filtered_results = JSON.stringify(
      this.getFilteredResults(activeBot)
    );
    const cycles = JSON.stringify(selectedCycles.map((cycle) => cycle.value));
    const formData = new FormData();
    formData.append("campaign_id", campaign_id);
    formData.append("bot_name", bot_name);
    formData.append("min_phone_priority", min_phone_priority);
    formData.append("max_phone_priority", max_phone_priority);
    formData.append("contactability", contactability.value);
    formData.append("filtered_results", filtered_results);
    formData.append("should_export_file", shouldDownloadFile);
    if (this.state.canSelectCycles) {
      formData.append("valid_cycles", cycles);
    }
    if (exclusionsFile.value.length > 0) {
      let exclusion_file = exclusionsFile.value[0][0];
      formData.append("exclusion_file", exclusion_file);
    }
    try {
      const res = await axios.put(campaignCasesUrl, formData, {
        ...tokenHeader(),
        ...{ "Content-Type": "multipart/form-data" },
      });
      const { status } = res.data;
      return this.handleStatus(status);
    } catch (error) {
      console.log(error);
    }
  };

  handleSend = async (e) => {
    e.preventDefault();
    if (this.handleValidations()) {
      const {
        activeBot,
        phonePriorities,
        maxPhonePriorities,
        contactability,
      } = this.state;
      const { bots } = this.props;
      const bot = _.filter(bots, { id: activeBot })[0];
      const tasksUrl = `${urls.API_ROOT}/api/v1/tasks/`; 
      this.setState({ modalXhr: true });
      let file = this.state.file.value[0][0];
      let phonePriority = phonePriorities[activeBot];
      let minPhonePriority =
        this.state.dialmode === helpers.dialmodes.vertical ? phonePriority : 0;
      let maxPhonePriority =
        this.state.dialmode === helpers.dialmodes.vertical
          ? phonePriority
          : maxPhonePriorities[activeBot];
      const formData = new FormData();
      formData.append("file", file);
      formData.append("type", "IMPORT_CAMPAIGN");
      formData.append("bot", bot.name);
      formData.append("bot_key", bot.id);
      formData.append(
        "filtered_results",
        JSON.stringify(this.getFilteredResults(activeBot))
      );
      formData.append("min_phone_priority", minPhonePriority);
      formData.append("max_phone_priority", maxPhonePriority);
      formData.append("contactability", contactability.value);
      try {
        const res = await axios.post(tasksUrl, formData, {
          ...tokenHeader(),
          ...{ "Content-Type": "multipart/form-data" },
        });
        const { id, status } = res.data;
        const { data } = res;
        const mostRecentLog = _.orderBy(data.logs, "created_at", "desc")[0];
        this.setState({ taskId: id });
        await this.handleStatus(status, mostRecentLog);

      } catch (error) {
        console.log(error);
        if (window.navigator.onLine === false) {
          this.setState({ network_failed: true });
        }
      }
    }
  };

  handleDrop = (file) => {
    let stateFile = [...this.state.file.value];
    stateFile.push(file);

    this.setState(
      (prevState) => ({
        file: { ...prevState.file, value: stateFile },
      }),
      () => this.handleValidation("file")
    );
  };

  handleDropExclusions = (exclusionsFile) => {
    let stateExclusionsFile = [...this.state.exclusionsFile.value];
    stateExclusionsFile.push(exclusionsFile);

    this.setState(
      (prevState) => ({
        exclusionsFile: {
          ...prevState.exclusionsFile,
          value: stateExclusionsFile,
        },
      }),
      () => this.handleValidation("exclusionsFile")
    );
  };

  handleChange = (target) => {
    let { activeSection } = this.state;
    const { name, value } = target;
    if (name === "search") {
      if (activeSection === "campaigns")
        this.setState(
          {
            page: 1,
            search: value,
            botHasMoreCampaigns: true,
            errorLoadingCampaigns: false,
            selectedBotCampaigns: [],
            allBotCampaigns: [],
            nextCampaignPage: null,
          },
          async () => await this.handleScrollCampaigns()
        );
      else if (activeSection === "reports")
        this.setState({ search: value }, () => this.updateReporting());
      else if (activeSection === "inbound_reports")
        this.setState({ page: 1, inboundSearch: value }, () =>
          this.updateInboundReporting()
        );
      return;
    }
    this.setState(
      (prevState) => ({
        [name]: { ...prevState[name], value },
      }),
      () => this.handleValidation(name)
    );
  };

  handleModal = () => {
    this.setState({
      modal: true,
      date: moment(new Date()).format("YYYY-MM-DD"),
    });
  };

  handleClick = async (companyId) => {
    if (companyId !== this.state.activeBot) {
      let { maxPhonePriorities } = this.state;
      this.getBotConfiguration(companyId).then((configuration) => {
        maxPhonePriorities[companyId] = configuration.max_phone_priority;
        this.setState(
          {
            timezoneBot: configuration && configuration.timezone,
            activeBot: companyId,
            activeSection: configuration
              ? configuration.is_outbound
                ? "campaigns"
                : "inbound_reports"
              : "campaigns",
            casesCount: 0,
            canSelectCycles:
              configuration && configuration.cycleFilteringEnabled,
            inboundBot: configuration && configuration.is_inbound,
            outboundBot: configuration ? configuration.is_outbound : true,
            inboundOutboundBot:
              configuration && configuration.is_inbound_outbound,
            chatConversation: configuration && configuration.is_chat,
            voiceConversation: configuration ? configuration.is_voice : true,
            maxPhonePriorities: maxPhonePriorities,
            page: 1,
            reportingFromDate: moment(new Date()).startOf("week"),
            reportingToDate: moment(new Date()),
            caseResultFilter: "Compromete Fecha",
            activeReportingFilter: "day",
            selectedBotCampaignCases: [],
            selectedBotCampaigns: [],
            allBotCampaigns: [],
            nextCampaignPage: null,
            botHasMoreCampaigns: true,
            errorLoadingCampaigns: false,
          },
          async () => {
            await this.getBotData();
            await this.fetchCampaignResultsIds();
          }
        );
      });
    }
  };

  handleFileDownloadInput = (event) => {
    this.setState({ shouldDownloadFile: event.target.checked });
  };

  getBotConfiguration = async (id) => {
    if (id === null || id === undefined) return {};
    const botConfigurationUrl = `${urls.API_ROOT}/api/v1/bot-configurations/${id}/`;
    const botConfiguration = await axios
      .get(botConfigurationUrl, { ...tokenHeader() })
      .then((res) => {
        if (res.status === 404) return {};
        this.setState({ columns_to_show: res.data.columns_to_show });
        return res.data;
      })
      .catch((err) => {
        return {};
      });
    return botConfiguration;
  };

  getCycleFilteringEnabledForBot = async (id) => {
    if (id === null || id === undefined) return false;

    const botConfigurationUrl = `${urls.API_ROOT}/api/v1/bot_configurations/${id}/`;
    const cycleFilteringEnabled = await axios
      .get(botConfigurationUrl, { ...tokenHeader() })
      .then((res) => {
        return res.data.cycleFilteringEnabled;
      })
      .catch(() => {
        return false;
      });
    return cycleFilteringEnabled;
  };

  getNewQuery = () => {
    const { activeBot, search, activeFilter, sort } = this.state;
    const { ui } = this.props.ui;
    let campaignStatus;
    if (ui) campaignStatus = ui.campaignStatus;
    let status;
    if (activeFilter === "all") {
      status = "";
    } else {
      status = campaignStatus[activeFilter].value;
    }
    // const url = `?bot=${activeBot}&name__icontains=${search}&status=${status}&ordering=${sort ? "-" : ""}percentage_completed`;
    const url = `?bot=${activeBot}&name__icontains=${search}&status=${status}&ordering=-start_date`;
    return url;
  };

  getNewInboundCasesQuery = () => {
    const { activeBot, search, activeFilter, sort } = this.state;
    const { ui } = this.props.ui;
    let inboundCasesStatus;
    if (ui) inboundCasesStatus = ui.inboundCasesStatus;
    let status;
    if (activeFilter === "all") {
      status = "";
    } else {
      status = inboundCasesStatus[activeFilter].value;
    }

    const url = `?all&bot=${activeBot}&search=${search}&status=${status}&ordering=${sort ? "-" : ""
      }last_updated`;
    return url;
  };

  getReportingDateLookupQuery = (
    activeReportingFilter,
    reportingFromDate,
    reportingToDate
  ) => {
    let dateLookup = "";

    if (activeReportingFilter === "year")
      dateLookup = `local_created__gte=${moment(reportingFromDate)
        .startOf("year")
        .format("YYYY-MM-DD")}&local_created__lte=${moment(reportingToDate)
          .endOf("year")
          .format("YYYY-MM-DD")}`;
    else if (activeReportingFilter === "month") {
      let startOfMonth = moment(reportingFromDate)
        .startOf("month")
        .format("YYYY-MM-DD");
      let endOfMonth = moment(reportingToDate)
        .endOf("month")
        .format("YYYY-MM-DD");
      dateLookup = `local_created__gte=${startOfMonth}&local_created__lte=${endOfMonth}`;
    } else if (activeReportingFilter === "week") {
      let startOfWeek = moment(reportingFromDate)
        .startOf("week")
        .format("YYYY-MM-DD");
      let endOfWeek = moment(reportingToDate)
        .endOf("week")
        .format("YYYY-MM-DD");
      dateLookup = `local_created__gte=${startOfWeek}&local_created__lte=${endOfWeek}`;
    } else if (activeReportingFilter === "day")
      dateLookup = `local_created__gte=${moment(reportingFromDate).format(
        "YYYY-MM-DD"
      )}&local_created__lte=${moment(reportingToDate).format("YYYY-MM-DD")}`;
    return dateLookup;
  };

  getInboundReportingDateLookupQuery = (
    activeInboundReportingFilter,
    inboundReportingFromDate,
    inboundReportingToDate
  ) => {
    let dateLookup = "";

    if (activeInboundReportingFilter === "year")
      dateLookup = `local_updated__date__gte=${moment(inboundReportingFromDate)
        .startOf("year")
        .format("YYYY-MM-DD")}&local_updated__date__lte=${moment(
          inboundReportingToDate
        )
          .endOf("year")
          .format("YYYY-MM-DD")}`;
    else if (activeInboundReportingFilter === "month") {
      let startOfMonth = moment(inboundReportingFromDate)
        .startOf("month")
        .format("YYYY-MM-DD");
      let endOfMonth = moment(inboundReportingToDate)
        .endOf("month")
        .format("YYYY-MM-DD");
      dateLookup = `local_updated__date__gte=${startOfMonth}&local_updated__date__lte=${endOfMonth}`;
    } else if (activeInboundReportingFilter === "week") {
      let startOfWeek = moment(inboundReportingFromDate)
        .startOf("week")
        .format("YYYY-MM-DD");
      let endOfWeek = moment(inboundReportingToDate)
        .endOf("week")
        .format("YYYY-MM-DD");
      dateLookup = `local_updated__date__gte=${startOfWeek}&local_updated__date__lte=${endOfWeek}`;
    } else if (activeInboundReportingFilter === "day")
      dateLookup = `local_updated__date__gte=${moment(
        inboundReportingFromDate
      ).format("YYYY-MM-DD")}&local_updated__date__lte=${moment(
        inboundReportingToDate
      ).format("YYYY-MM-DD")}`;

    return dateLookup;
  };

  getNewInboundReportingFilterQuery = () => {
    const {
      activeBot,
      inboundSearch,
      activeInboundReportingFilter,
      inboundReportingFromDate,
      inboundReportingToDate,
      inboundCaseResultFilter,
      inboundCasesResultList,
    } = this.state;

    let dateLookup = this.getInboundReportingDateLookupQuery(
      activeInboundReportingFilter,
      inboundReportingFromDate,
      inboundReportingToDate
    );

    let url = `?bot=${activeBot}&${dateLookup}&`;

    if (inboundSearch) url += `search=${inboundSearch}&`;

    let statuses = [];
    if (inboundCaseResultFilter && inboundCaseResultFilter !== "all")
      statuses = _.map(
        inboundCasesResultList.filter((value) =>
          inboundCaseResultFilter.includes(value.value)
        ),
        (status) => {
          return status.value;
        }
      );

    if (statuses !== []) url += `case_result=${_.join(statuses, ",")}&`;

    return url;
  };

  getNewReportingFilterQuery = (got_promise) => {
    const {
      search,
      activeReportingFilter,
      reportingFromDate,
      reportingToDate,
      caseResultFilter,
      casesStatusList,
    } = this.state;

    let dateLookup = this.getReportingDateLookupQuery(
      activeReportingFilter,
      reportingFromDate,
      reportingToDate
    );

    let url = `?${dateLookup}&`;

    if (search !== "") url += `search=${search}&`;

    let statuses = [];
    let gotPromise = false;
    if (
      caseResultFilter &&
      (caseResultFilter === "commitments" ||
        caseResultFilter === "Compromete Fecha")
    ) {
      gotPromise = true;
    } else if (caseResultFilter && caseResultFilter !== "all") {
      statuses = _.map(
        casesStatusList.filter((value) =>
          caseResultFilter.includes(value.value)
        ),
        (status) => {
          return status.value;
        }
      );
    }

    if (gotPromise) {
      url += "got_promise=True&";
    } else if (statuses !== []) {
      url += `result=${_.join(statuses, ",")}&`;
    }

    if (got_promise || got_promise === false) {
      url += `got_promise=${got_promise}&`;
    }

    return url;
  };

  getNewCampaignsReportingFilterQuery = () => {
    const {
      activeReportingFilter,
      reportingFromDate,
      reportingToDate,
      search,
    } = this.state;

    let dateLookup = this.getReportingDateLookupQuery(
      activeReportingFilter,
      reportingFromDate,
      reportingToDate
    );

    let url = `?${dateLookup}&`;

    if (search !== "") url += `search=${search}&`;

    return url;
  };

  getNewInboundGeneralReportingFilterQuery = () => {
    const {
      activeInboundReportingFilter,
      inboundReportingFromDate,
      inboundReportingToDate,
      inboundSearch,
    } = this.state;

    let dateLookup = this.getInboundReportingDateLookupQuery(
      activeInboundReportingFilter,
      inboundReportingFromDate,
      inboundReportingToDate
    );

    let url = `?${dateLookup}&`;

    if (inboundSearch !== "") url += `search=${inboundSearch}&`;

    return url;
  };

  getFilteredCampaigns = async (url) => {
    const campaignsUrl = `${urls.API_ROOT}/api/v1/campaigns/${url}`;
    const notCachedUrl = `${campaignsUrl}?cb=${new Date().getTime()}`;
    const filterUrl = isIE ? notCachedUrl : campaignsUrl;

    try {
      const res = await axios.get(filterUrl, {
        cancelToken: this.state.axiosSource.token,
        ...tokenHeader(),
      });
      const campaigns = res.data;
      this.setState({
        allBotCampaigns: campaigns,
        selectedBotCampaigns: campaigns,
        xhr: false,
        axiosSource: null,
      });
      return campaigns;
    } catch (error) {
      console.log(error);
    }
  };

  getFilteredCampaignCasesByResult = async (url) => {
    let { activeBot } = this.state;
    if (activeBot === null) return;

    const statusUrl = `${urls.API_ROOT
      }/api/v1/status/?calls&bot=${activeBot}&cb=${new Date().getTime()}`;

    try {
      const res = await axios.get(statusUrl, tokenHeader());
      const { data: casesStatusList } = res;
      this.setState({ casesStatusList });
    } catch (error) {
      console.log(error);
    }
  };

  getFilteredInboundCasesByResult = async () => {
    let { activeBot } = this.state;
    if (activeBot === null) return;

    const resultsUrl = `${urls.API_ROOT
      }/api/v1/inbound-case/results/${activeBot}/?cb=${new Date().getTime()}`;

    try {
      const res = await axios.get(resultsUrl, tokenHeader());
      const { data: inboundCasesResultList } = res;
      this.setState({ inboundCasesResultList });
    } catch (error) {
      console.log(error);
    }
  };

  getFilteredInboundCases = async (url) => {
    const inboundCasesUrl = `${urls.API_ROOT}/api/v1/inbound-case/${url}`;
    const notCachedUrl = `${inboundCasesUrl}?cb=${new Date().getTime()}`;
    const filterUrl = isIE ? notCachedUrl : inboundCasesUrl;

    try {
      const res = await axios.get(filterUrl, {
        cancelToken: this.state.axiosSource.token,
        ...tokenHeader(),
      });
      const inboundCases = res.data;
      this.setState({
        selectedBotInboundCases: inboundCases,
        xhr: false,
        axiosSource: null,
      });
      return inboundCases;
    } catch (error) {
      console.log(error);
    }
  };

  getFilteredInboundReportingCases = async (url) => {
    let { activeBot, page } = this.state;
    if (activeBot === null) return;

    let inboundCasesUrl = `${urls.API_ROOT}/api/v1/inbound-case/${url}`;
    if (page && page > 1) inboundCasesUrl += `&page=${page}&`;
    const notCachedUrl = `${inboundCasesUrl}&cb=${new Date().getTime()}`;
    const inboundReportingFilterUrl = isIE ? notCachedUrl : inboundCasesUrl;

    try {
      const res = await axios.get(inboundReportingFilterUrl, {
        cancelToken: this.state.axiosSource.token,
        ...tokenHeader(),
      });
      const { results, count } = res.data;
      this.setState({
        selectedBotInboundCases: results,
        inboundCasesCount: count,
        xhr: false,
        axiosSource: null,
      });
      return results;
    } catch (error) {
      console.log(error);
    }
  };

  getFilteredCampaignCases = async (url) => {
    let { activeBot, page } = this.state;
    if (activeBot === null) return;

    let campaignCasesUrl = `${urls.API_ROOT}/api/v1/campaigns/cases/${activeBot}/${url}`;
    if (page && page > 1) campaignCasesUrl += `&page=${page}&`;
    const notCachedUrl = `${campaignCasesUrl}&cb=${new Date().getTime()}`;
    const reportingFilterUrl = isIE ? notCachedUrl : campaignCasesUrl;

    try {
      const res = await axios.get(reportingFilterUrl, {
        cancelToken: this.state.axiosSource.token,
        ...tokenHeader(),
      });
      const { results, count } = res.data;
      this.setState({
        selectedBotCampaignCases: results,
        casesCount: count,
        xhr: false,
        axiosSource: null,
      });
      return results;
    } catch (error) {
      console.log(error);
    }
  };


  getInboundReporting = async (url) => {
    let { activeBot } = this.state;
    if (activeBot == null) return;

    let baseReportingUrl = `${urls.API_ROOT}/api/v1/inbound-case/report/${activeBot}/${url}`;
    const notCachedUrl = `${baseReportingUrl}&cb=${new Date().getTime()}`;
    const reportingUrl = isIE ? notCachedUrl : baseReportingUrl;

    try {
      const res = await axios.get(reportingUrl, {
        cancelToken: this.state.axiosSource.token,
        ...tokenHeader(),
      });
      let { data: inboundReportingData } = res;

      this.setState({
        inboundReportingData: inboundReportingData,
        xhr: false,
        axiosSource: null,
        onInboundReporting: false,
      });

      return inboundReportingData;
    } catch (error) {
      this.setState({ onInboundReporting: false });
      console.log(error);
    }
  };

  getCampaignsReporting = async (url) => {
    let { activeBot } = this.state;
    if (activeBot == null) return;

    let baseReportingUrl = `${urls.API_ROOT}/api/v1/campaigns/report/${activeBot}/${url}`;
    const notCachedUrl = `${baseReportingUrl}&cb=${new Date().getTime()}`;
    const reportingUrl = isIE ? notCachedUrl : baseReportingUrl;

    try {
      const res = await axios.get(reportingUrl, {
        cancelToken: this.state.axiosSource.token,
        ...tokenHeader(),
      });
      const { data: reportingData } = res;

      this.setState({
        reportingData: reportingData,
        xhr: false,
        axiosSource: null,
        onReporting: false,
      });

      return reportingData;
    } catch (error) {
      this.setState({ onReporting: false });
      console.log(error);
    }
  };

  getCampaignsTotalReporting = async (url) => {
    let { activeBot } = this.state;
    if (activeBot == null) return;

    let baseReportingUrl = `${urls.API_ROOT}/api/v1/campaigns/totalreport/${activeBot}/${url}`;
    const notCachedUrl = `${baseReportingUrl}&cb=${new Date().getTime()}`;
    const reportingUrl = isIE ? notCachedUrl : baseReportingUrl;

    try {
      const res = await axios.get(reportingUrl, {
        cancelToken: this.state.axiosSource.token,
        ...tokenHeader(),
      });
      const { data: totalreportingData } = res;
      this.setState({
        totalreportingData: totalreportingData,
        xhr: false,
        axiosSource: null,
        onReporting: false,
      });
      return totalreportingData;
    } catch (error) {
      this.setState({ onReporting: false });
      console.log(error);
    }
  };

  handleFilteredFileDownlad = async (task_id) => {
    if (task_id === null || task_id === undefined || task_id === "") return;
    const url = `${urls.API_ROOT}/api/v1/task-processed-files/${task_id}/`;
    axios.get(url, { ...tokenHeader() }).then((response) => {
      fileDownload(response.data, "cases.dat"); //TODO the filename should not come from the frontend, it should be fetched from the backend
    });
  };

  handleCaseResultFilter = (filter, gotPromise) => {
    this.setState(
      {
        page: 1,
        caseResultFilter: filter,
        commitmentFilter: gotPromise,
      },
      () => this.updateCampaignCasesReport(gotPromise)
    );
  };

  handleInboundCaseResultFilter = (filter) => {
    this.setState(
      {
        page: 1,
        inboundCaseResultFilter: filter,
      },
      () => this.updateInboundCasesReport()
    );
  };

  updateBotData = async () => {
    if (this.state.outboundBot) {
      if (this.state.activeSection === "campaigns") {
        await this.updateCampaigns();
      } else {
        this.setState(
          { onReporting: true },
          async () => await this.updateReporting()
        );
      }
    } else if (this.state.inboundBot) {
      if (this.state.activeSection === "inbounds") {
        await this.updateInboundCases();
      } else {
        await this.updateInboundReporting();
      }
    }
  };

  updateCampaigns = async () => {
    if (this.state.xhr) this.state.axiosSource.cancel("abort");
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const url = this.getNewQuery();
    this.setState({ xhr: true, axiosSource: source }, () =>
      this.getFilteredCampaigns(url)
    );
  };

  updateInboundCases = async () => {
    if (this.state.xhr) this.state.axiosSource.cancel("abort");
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    let url = this.getNewInboundCasesQuery();
    this.setState({ xhr: true, axiosSource: source }, () =>
      this.getFilteredInboundCases(url)
    );
  };

  updateReporting = async (section) => {
    await Promise.all([this.getFilteredCampaignCasesByResult()]);
    if (section === "general") {
      await Promise.all([this.updateCampaignsReport()]);
    }
    await Promise.all([
      //this.updateCampaignsReport(),
      this.updateCampaignsTotalReport(),
      this.updateCampaignCasesReport(),
    ]);
  };

  updateInboundReporting = async () => {
    await Promise.all([
      this.updateInboundGeneralReport(),
      this.updateInboundCasesReport(),
    ]);
  };

  updateInboundGeneralReport = async () => {
    if (this.state.xhr) this.state.axiosSource.cancel("abort");

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const url = this.getNewInboundGeneralReportingFilterQuery();
    this.setState({ xhr: true, axiosSource: source }, () =>
      this.getInboundReporting(url)
    );
  };

  updateInboundCasesReport = async () => {
    if (!this.state.onInboundReporting && this.state.xhr)
      this.state.axiosSource.cancel("abort");

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const url = this.getNewInboundReportingFilterQuery();
    this.setState({ xhr: true, axiosSource: source }, () =>
      this.getFilteredInboundReportingCases(url)
    );
  };

  updateCampaignCasesReport = async (gotPromise) => {
    if (!this.state.onReporting && this.state.xhr)
      this.state.axiosSource.cancel("abort");
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const url = this.getNewReportingFilterQuery(gotPromise);
    this.setState({ xhr: true, axiosSource: source }, () =>
      this.getFilteredCampaignCases(url)
    );
  };

  updateCampaignsReport = async () => {
    if (!this.state.onReporting && this.state.xhr)
      this.state.axiosSource.cancel("abort");

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const url = this.getNewCampaignsReportingFilterQuery();
    this.setState({ xhr: true, axiosSource: source }, () =>
      this.getCampaignsReporting(url)
    );
  };

  updateCampaignsTotalReport = async () => {
    if (!this.state.onReporting && this.state.xhr)
      this.state.axiosSource.cancel("abort");

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const url = this.getNewCampaignsReportingFilterQuery();
    this.setState({ xhr: true, axiosSource: source }, () =>
      this.getCampaignsTotalReporting(url)
    );
  };

  updateDashboards = async () => {
    if (!this.state.onReporting && this.state.xhr)
      this.state.axiosSource.cancel("abort");
    const CancelToken = axios.CancelToken;
    this.setState({ xhr: false });
  };

  handleFilter = (filter) => {
    if (this.state.activeFilter !== filter) {
      this.setState(
        {
          activeFilter: filter,
          botHasMoreCampaigns: true,
          errorLoadingCampaigns: false,
          nextCampaignPage: null,
          selectedBotCampaigns: [],
          allBotCampaigns: [],
        },
        async () => await this.handleScrollCampaigns()
      );
    }
  };

  handleReportingFilter = (filter, section) => {
    if (this.state.activeReportingFilter !== filter) {
      this.setState(
        {
          page: 1,
          caseResultFilter: "Compromete Fecha",
          activeReportingFilter: filter,
          casesCount: 0,
          onReporting: true,
        },
        () => this.updateReporting(section)
      );
    }
  };

  handleInboundReportingFilter = (filter) => {
    if (this.state.activeInboundReportingFilter !== filter) {
      this.setState(
        {
          page: 1,
          activeInboundReportingFilter: filter,
          inboundCasesCount: 0,
          onInboundReporting: true,
        },
        () => this.updateInboundReporting()
      );
    }
  };

  handleSort = () => {
    this.setState(
      (prevState) => ({ sort: !prevState.sort }),
      () => this.updateBotData()
    );
  };

  handleDateChange = (date) => {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    this.setState({ date: formattedDate });
  };

  handleReportingFromDate = (date, section) => {
    this.setState(
      { page: 1, reportingFromDate: moment(date), onReporting: true },
      () => this.updateReporting(section)
    );
  };

  handleInboundReportingFromDate = (date) => {
    this.setState(
      {
        page: 1,
        inboundReportingFromDate: moment(date),
        onInboundReporting: true,
      },
      () => this.updateInboundReporting()
    );
  };

  handleReportingToDate = (date, section) => {
    this.setState(
      { page: 1, reportingToDate: moment(date), onReporting: true },
      () => this.updateReporting(section)
    );
  };

  handleInboundReportingToDate = (date) => {
    this.setState(
      {
        page: 1,
        inboundReportingToDate: moment(date),
        onInboundReporting: true,
      },
      () => this.updateInboundReporting()
    );
  };

  handleReportingTableChange = (type, { page, sizePerPage }) => {
    console.log(page)
    if (type === "pagination") {
      this.setState({ page }, () => this.updateCampaignCasesReport());
    }
  };

  handleInboundReportingTableChange = (type, { page, sizePerPage }) => {
    if (type === "pagination") {
      this.setState({ page }, () => this.updateInboundCasesReport());
    }
  };

  handleReportingTab = async (section) => {
    if (section === "general") {
      await this.updateCampaignsReport();
    } else if (section === "detail") {
      await this.updateReporting();
    } else if (section === "dashboards") {
      await this.updateDashboards();
    }
  };

  handleCloseAlertNetworkError = () => {
    this.setState({ ...state, network_failed: false });
  }

  handleExport = async (e) => {
    e.preventDefault();

    const tasksUrl = `${urls.API_ROOT}/api/v1/tasks/`;

    const {
      activeBot,
      activeReportingFilter,
      reportingFromDate,
      reportingToDate,
      caseResultFilter,
      casesStatusList,
      search,
      commitmentFilter,
    } = this.state;

    const { bots } = this.props;
    const bot = _.filter(bots, { id: activeBot })[0];

    let data = { type: "EXPORT_CAMPAIGN", bot_key: bot.id };

    let filters = {};

    filters["campaign__bot"] = activeBot;

    if (activeReportingFilter === "year") {
      filters["local_updated__gte"] = moment(reportingFromDate)
        .startOf("year")
        .format("YYYY-MM-DD");
      filters["local_updated__lte"] = moment(reportingToDate)
        .endOf("year")
        .format("YYYY-MM-DD");
    } else if (activeReportingFilter === "month") {
      filters["local_updated__gte"] = moment(reportingFromDate)
        .startOf("month")
        .format("YYYY-MM-DD");
      // filters["created_at__year__gte"] = moment(reportingFromDate).year();
      filters["local_updated__lte"] = moment(reportingToDate)
        .endOf("month")
        .format("YYYY-MM-DD");
      // filters["created_at__year__lte"] = moment(reportingToDate).year();
    } else if (activeReportingFilter === "week") {
      filters["local_updated__gte"] = moment(reportingFromDate)
        .startOf("week")
        .format("YYYY-MM-DD");
      filters["local_updated__lte"] = moment(reportingToDate)
        .endOf("week")
        .format("YYYY-MM-DD");
    } else if (activeReportingFilter === "day") {
      filters["local_updated__gte"] =
        moment(reportingFromDate).format("YYYY-MM-DD");
      filters["local_updated__lte"] =
        moment(reportingToDate).format("YYYY-MM-DD");
    }

    if (caseResultFilter !== "all") {
      let statuses;
      if (caseResultFilter === "commitments") {
        filters["got_promise"] = true;
      } else {
        statuses = _.map(
          casesStatusList.filter((value) =>
            caseResultFilter.includes(value.value)
          ),
          (status) => {
            return status.value;
          }
        );
        filters["case_result__name__in"] = statuses;

        if (commitmentFilter !== null && commitmentFilter !== undefined) {
          filters["got_promise"] = commitmentFilter;
        }
      }
    }

    data["filter"] = filters;
    if (search !== "") data["search"] = search;

    try {
      this.setState({ xhr: true });
      const res = await axios.post(tasksUrl, data, tokenHeader());
      const { status, file } = res.data;
      this.setState(
        { fileToExport: file },
        async () => await this.handleTaskStatus(status)
      );
    } catch (error) {
      if (error.toJSON().message === 'Network Error') {
        this.setState({ ...state, network_failed: true });
      }
      this.setState({ xhr: false });
    }
  };



  handleInboundExport = async (e) => {
    e.preventDefault();

    const tasksUrl = `${urls.API_ROOT}/api/v1/tasks/`;

    const {
      activeBot,
      activeInboundReportingFilter,
      inboundReportingFromDate,
      inboundReportingToDate,
      inboundCaseResultFilter,
      inboundCasesResultList,
      inboundSearch,
    } = this.state;

    const { bots } = this.props;
    const bot = _.filter(bots, { id: activeBot })[0];

    let data = { type: "EXPORT_INBOUND", bot_key: bot.id };

    let filters = {};

    filters["bot_id"] = activeBot;

    if (activeInboundReportingFilter === "year") {
      filters["last_updated__date__gte"] = moment(inboundReportingFromDate)
        .startOf("year")
        .format("YYYY-MM-DD");
      filters["last_updated__date__lte"] = moment(inboundReportingToDate)
        .endOf("year")
        .format("YYYY-MM-DD");
    } else if (activeInboundReportingFilter === "month") {
      filters["last_updated__date__gte"] = moment(inboundReportingFromDate)
        .startOf("month")
        .format("YYYY-MM-DD");
      filters["last_updated__date__lte"] = moment(inboundReportingToDate)
        .endOf("month")
        .format("YYYY-MM-DD");
    } else if (activeInboundReportingFilter === "week") {
      filters["last_updated__date__gte"] = moment(inboundReportingFromDate)
        .startOf("week")
        .format("YYYY-MM-DD");
      filters["last_updated__date__lte"] = moment(inboundReportingToDate)
        .endOf("week")
        .format("YYYY-MM-DD");
    } else if (activeInboundReportingFilter === "day") {
      filters["last_updated__date__gte"] = moment(
        inboundReportingFromDate
      ).format("YYYY-MM-DD");
      filters["last_updated__date__lte"] = moment(
        inboundReportingToDate
      ).format("YYYY-MM-DD");
    }

    if (inboundCaseResultFilter !== "all") {
      filters["case_result__name__in"] = _.map(
        inboundCasesResultList.filter((value) =>
          inboundCaseResultFilter.includes(value.value)
        ),
        (status) => {
          return status.value;
        }
      );
    }

    data["filter"] = filters;
    if (inboundSearch !== "") data["search"] = inboundSearch;

    try {
      this.setState({ xhr: true });
      const res = await axios.post(tasksUrl, data, tokenHeader());
      const { status, file } = res.data;
      this.setState(
        { fileToExport: file },
        async () => await this.handleTaskStatus(status)
      );
    } catch (error) {
      if (error.toJSON().message === 'Network Error') {
        this.setState({ ...state, network_failed: true });
      }
      this.setState({ xhr: false });
    }
  };

  handleTaskStatus = async (status) => {
    switch (status) {
      case "RECEIVED": {
        await this.getTaskStatus("IN_PROCESS");
        break;
      }
      case "IN_PROCESS": {
        await this.getTaskStatus("DONE");
        break;
      }
      case "PROCESSED_WITH_ERRORS": {
        this.setState({
          xhr: false,
          modalXhr: false,
          processedWithErrors: true,
        });
        break;
      }
      case "DONE": {
        this.setState({ xhr: false, modalXhr: false });
        const { fileToExport } = this.state;
        console.log(fileToExport)
        window.location = `${urls.API_ROOT}${fileToExport}`;
        break;
      }
    }
  };

  fetchCampaigns = async () => {
    const campaignsUrl = `${urls.API_ROOT}/api/v1/campaigns/`;
    const notCachedUrl = `${campaignsUrl}?cb=${new Date().getTime()}`;
    const url = isIE ? notCachedUrl : campaignsUrl;
    this.setState({ xhr: true });
    try {
      const res = await axios.get(url, tokenHeader());
      const campaigns = res.data;
      this.setState({ campaigns });
    } finally {
      this.setState({ xhr: false });
    }
  };

  fetchBots = async () => {
    let { phonePriorities, activeBot, maxPhonePriorities } = this.state;
    const botsUrl = `${urls.API_ROOT}/api/v1/bots/`;
    this.setState({ loadingBots: true });
    try {
      const res = await axios.get(botsUrl, tokenHeader());
      const bots = res.data;
      if (!activeBot) {
        const newActiveBot = bots.length ? bots[0].id : null;
        await this.getBotConfiguration(newActiveBot).then(
          (configuration) => {
            this.setPhonePriorities(bots, phonePriorities);
            if (newActiveBot !== null) {
              maxPhonePriorities[newActiveBot] =
                configuration.max_phone_priority;
            }
            this.props.storeBots(bots);
            this.setState({
              newActiveBot,
              canSelectCycles:
                configuration && configuration.cycleFilteringEnabled,
              inboundBot: configuration && configuration.is_inbound,
              outboundBot: configuration ? configuration.is_outbound : true,
              inboundOutboundBot:
                configuration && configuration.is_inbound_outbound,
              chatConversation: configuration && configuration.is_chat,
              voiceConversation: configuration ? configuration.is_voice : true,
              maxPhonePriorities: maxPhonePriorities,
              timezoneBot: configuration && configuration.timezone,
              //activeSection: configuration ? (configuration.is_outbound ? "campañas" : "inbounds") : "campañas"
            });
          }
        );
      } else {
        this.props.storeBots(bots);
        this.setPhonePriorities(bots, phonePriorities);
      }
    } finally {
      this.setState({ loadingBots: false });
    }
  };

  setPhonePriorities = (bots, phonePriorities) => {
    bots.map((bot) => (phonePriorities[bot.id] = 0));
    this.setState({ phonePriorities });
  };

  fetchInboundCases = async () => {
    const inboundCasesUrl = `${urls.API_ROOT}/api/v1/inbound-case/?all`;
    const notCachedUrl = `${inboundCasesUrl}?cb=${new Date().getTime()}`;
    const url = isIE ? notCachedUrl : inboundCasesUrl;
    this.setState({ xhr: true });
    try {
      const res = await axios.get(url, tokenHeader());
      const inboundCases = res.data;
      this.setState({ inboundCases });
    } finally {
      this.setState({ xhr: false });
    }
  };

  getBotCampaigns = async () => {
    const { activeBot, campaigns } = this.state;
    if (activeBot === null) return;
    await this.updateCampaigns();
  };

  getBotInboundCases = async () => {
    const { activeBot, inboundCases } = this.state;
    if (activeBot === null) return;

    const selectedBotInboundCases = inboundCases.filter(
      (inboundCase) => inboundCase.bot.id === activeBot
    );
    this.setState({ allBotInboundCases: selectedBotInboundCases });
    await this.updateInboundCases();
  };

  getBotData = async () => {
    if (this.state.outboundBot) {
      if (this.state.activeSection === "campaigns") {
        await this.handleScrollCampaigns();
      } else {
        this.setState(
          { onReporting: true },
          async () => await this.updateReporting()
        );
      }
    } else if (this.state.inboundBot) {
      await this.getInboundBotData();
      if (this.state.activeSection === "inbounds") {
        await this.updateInboundCases();
      } else {
        await this.updateInboundReporting();
      }
    }
  };

  fetchCampaignResultsIds = async () => {
    const { activeBot } = this.state;
    const campaignsResultsUrl = `${urls.API_ROOT}/api/v1/campaigns-results/${activeBot}/`;
    const notCachedUrl = `${campaignsResultsUrl}?cb=${new Date().getTime()}`;
    const url = isIE ? notCachedUrl : campaignsResultsUrl;
    this.setState({ xhr: true });
    try {
      const res = await axios.get(url, tokenHeader());
      const campaignsResultsIds = JSON.parse(res.data);
      this.setState({ campaignsResultsIds }, this.createNewResultFilterArray);
    } finally {
      this.setState({ xhr: false });
    }
  };

  createNewResultFilterArray = () => {
    const { campaignsResultsIds } = this.state;
    const resultsFilterArray = campaignsResultsIds.map((result) => ({
      ...result,
      filteredByBot: [],
    }));
    this.setState({ resultsFilterArray });
  };

  getFilteredResults = (activeBot) => {
    return this.state.resultsFilterArray.filter((result) =>
      result.filteredByBot.includes(activeBot)
    );
  };

  getResultOptions = (activeBot) => {
    return this.state.resultsFilterArray.filter(
      (result) => !result.filteredByBot.includes(activeBot)
    );
  };

  handleFilterSelectChange = (value) => {
    if (this.state.input !== value) {
      this.setState({
        input: value,
      });
    }
  };

  clearInput = () => {
    this.setState({
      input: "",
      selectedCycles: [],
    });
  };

  handleAddResultFilter = (e) => {
    e.preventDefault();
    let { input, resultsFilterArray, activeBot } = this.state;

    if (input === undefined || input === "") return;

    let result = _.find(resultsFilterArray, (res) => {
      return res.name === input.value;
    });
    result.filteredByBot.push(activeBot);
    this.setState({ resultsFilterArray, input: "" });
  };

  handleResultFilterTagDelete = (e, result) => {
    e.preventDefault();

    let { resultsFilterArray, activeBot } = this.state;

    let foundResult = _.find(resultsFilterArray, (res) => {
      return res.name === result.name;
    });
    let botIndex = foundResult.filteredByBot.indexOf(activeBot);
    if (botIndex > -1) {
      foundResult.filteredByBot.splice(botIndex, 1);
      this.setState({ resultsFilterArray });
    }
  };

  onCycleAdd = (e) => {
    e.preventDefault();

    let { input, selectedCycles } = this.state;

    if (input === undefined || input === "") return;

    let existingCycle = _.find(selectedCycles, (cycle) => {
      return cycle.value === input.value;
    });

    if (existingCycle === undefined) {
      selectedCycles.push(input);
      this.setState({ selectedCycles, input: "" });
    }
  };

  handleCycleDelete = (e, result) => {
    e.preventDefault();

    let { selectedCycles } = this.state;

    let cycleIndex = _.findIndex(selectedCycles, (res) => {
      return res.name === result.name;
    });
    if (cycleIndex > -1) {
      selectedCycles.splice(cycleIndex, 1);
      this.setState({ cycleIndex });
    }
  };

  getActiveBotName = () => {
    let activeBotInfo = _.find(this.props.bots, (botInfo) => {
      return botInfo.id == this.state.activeBot;
    });

    if (activeBotInfo === undefined) return;
    return activeBotInfo.name;
  };

  handleIncrementPhonePriority = (e) => {
    e.preventDefault();
    let { phonePriorities, maxPhonePriorities, dialmode, activeBot } =
      this.state;

    if (dialmode === helpers.dialmodes.vertical) {
      phonePriorities[activeBot] += 1;
      this.setState({ phonePriorities });
    } else {
      maxPhonePriorities[activeBot] += 1;
      this.setState({ maxPhonePriorities });
    }
  };

  handleDecrementPhonePriority = (e) => {
    e.preventDefault();
    let { phonePriorities, maxPhonePriorities, dialmode, activeBot } =
      this.state;

    if (dialmode === helpers.dialmodes.vertical) {
      if (phonePriorities[activeBot] > 0) {
        phonePriorities[activeBot] -= 1;
        this.setState({ phonePriorities });
      }
    } else {
      if (maxPhonePriorities[activeBot] > 0) {
        maxPhonePriorities[activeBot] -= 1;
        this.setState({ maxPhonePriorities });
      }
    }
  };

  handleDialModeChange = (dialmode) => {
    this.setState({ dialmode: dialmode });
  };
  handleClosesEODChange = (closes_eod) => {
    this.setState({ closes_eod: closes_eod })
  }

  setPrevActiveBot = async () => {
    if (this.props.location.state) {
      const { prevPath, activeBot, from } = this.props.location.state;
      let { maxPhonePriorities } = this.state;
      if (
        prevPath.includes("/inbound-case") ||
        (prevPath.includes("/campaigns/") &&
          (!prevPath.includes("/cases/") || from === "home") &&
          activeBot !== null)
      ) {
        await this.getBotConfiguration(activeBot).then(
          (configuration) => {
            maxPhonePriorities[activeBot] = configuration.max_phone_priority;
            this.setState({
              activeBot,
              canSelectCycles:
                configuration && configuration.cycleFilteringEnabled,
              inboundBot: configuration && configuration.is_inbound,
              outboundBot: configuration ? configuration.is_outbound : true,
              inboundOutboundBot:
                configuration && configuration.is_inbound_outbound,
              chatConversation: configuration && configuration.is_chat,
              voiceConversation: configuration ? configuration.is_voice : true,
              maxPhonePriorities: maxPhonePriorities,
              timezoneBot: configuration && configuration.timezone,
              //activeSection: configuration ? (configuration.is_outbound ? "campañas" : "inbounds") : "campañas"
            });
          }
        );
      }
    }
  };

  setPrevPage = async () => {
    if (this.props.location.state) {
      const { prevPath, from, page } = this.props.location.state;
      if (
        from === "home" &&
        (prevPath.includes("/cases/") || prevPath.includes("/inbound-case"))
      ) {
        this.setState({ page: page });
      }
    }
  };

  setPrevSection = async () => {
    if (this.props.location.state) {
      const { prevPath, from, activeSection } = this.props.location.state;
      if (
        (from === "home" && prevPath.includes("/campaigns/")) ||
        prevPath.includes("/inbound-case")
      ) {
        this.setState({ activeSection: activeSection });
      }
    }
  };

  setPrevReportingFilter = async () => {
    if (this.props.location.state) {
      const {
        prevPath,
        from,
        activeReportingFilter,
        activeInboundReportingFilter,
        search,
      } = this.props.location.state;
      if (
        from === "home" &&
        (prevPath.includes("/cases/") || prevPath.includes("/inbound-case"))
      ) {
        if (prevPath.includes("/cases/")) {
          this.setState({
            activeReportingFilter: activeReportingFilter,
            search: search,
          });
        } else {
          this.setState({
            activeInboundReportingFilter: activeInboundReportingFilter,
            inboundSearch: search,
          });
        }
      }
    }
  };

  setPrevReportingDate = async () => {
    if (this.props.location.state) {
      const {
        prevPath,
        from,
        reportingFromDate,
        reportingToDate,
        inboundReportingFromDate,
        inboundReportingToDate,
      } = this.props.location.state;
      if (
        from === "home" &&
        (prevPath.includes("/cases/") || prevPath.includes("/inbound-case"))
      ) {
        if (prevPath.includes("/cases/")) {
          //await this.getFilteredCampaignCasesByResult();
          this.setState({
            reportingFromDate: moment(reportingFromDate),
            reportingToDate: moment(reportingToDate),
          });
        } else {
          //await this.getFilteredInboundCasesByResult();
          this.setState({
            inboundReportingFromDate: moment(inboundReportingFromDate),
            inboundReportingToDate: moment(inboundReportingToDate),
          });
        }
      }
    }
  };

  setPrevCaseResultFilter = async () => {
    if (this.props.location.state) {
      const { prevPath, from, resultFilter } = this.props.location.state;
      if (
        from === "home" &&
        (prevPath.includes("/cases/") || prevPath.includes("/inbound-case"))
      ) {
        if (prevPath.includes("/cases/")) {
          this.setState({ caseResultFilter: resultFilter });
        } else {
          this.setState({ inboundCaseResultFilter: resultFilter });
        }
      }
    }
  };

  setPrevCampaignFilters = async () => {
    if (this.props.location.state) {
      const { prevPath, from, search, activeFilter } =
        this.props.location.state;
      if (from === "home" && prevPath.includes("/campaigns/")) {
        this.setState({ search: search, activeFilter: activeFilter });
      }
    }
  };

  handleBotCampaignSection = (section) => {
    let { activeSection } = this.state;
    if (section === activeSection) {
      return;
    }
    this.setState({ activeSection: section }, () =>
      this.changeBotCampaignSection(section)
    );
  };

  handleBotInboundSection = (section) => {
    let { activeSection } = this.state;
    if (section === activeSection) return;

    this.setState({ activeSection: section }, () =>
      this.changeInboundBotSection(section)
    );
  };

  changeBotCampaignSection = async (section) => {
    if (section === "campaigns") {
      this.setState(
        {
          search: "",
          allBotCampaigns: [],
          selectedBotCampaigns: [],
          botHasMoreCampaigns: true,
          nextCampaignPage: null,
          errorLoadingCampaigns: false,
        },
        async () => await this.handleScrollCampaigns()
      );
    } else if (section === "reports") {
      await this.getFilteredCampaignCasesByResult();
      this.setState({ onReporting: true }, () => this.updateReporting());
    }
  };

  changeInboundBotSection = async (section) => {
    if (section === "inbounds") {
      await this.getBotInboundCases();
    } else if (section === "inbound_reports") {
      await this.getInboundBotData();
      await this.updateInboundReporting();
    }
  };

  getInboundBotData = async () => {
    await this.getFilteredInboundCasesByResult();
    this.setState({
      activeSection: "inbound_reports",
      onInboundReporting: true,
    });
  };

  handleScrollCampaigns = async () => {
    if (
      !this.state.botHasMoreCampaigns ||
      this.state.loadingCampaigns ||
      this.state.activeBot === null ||
      this.state.errorLoadingCampaigns
    )
      return;

    if (this.state.xhr) this.state.axiosSource.cancel("abort");

    let { activeBot, nextCampaignPage, search, activeFilter } = this.state;
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const { ui } = this.props.ui;
    let campaignStatus;
    if (ui) campaignStatus = ui.campaignStatus;
    let status;
    if (activeFilter === "all") {
      status = "";
    } else {
      status = campaignStatus[activeFilter].value;
    }

    let baseCampaignsUrl;
    if (nextCampaignPage !== null) {
      baseCampaignsUrl = this.state.nextCampaignPage;
    } else {
      baseCampaignsUrl = `${urls.API_ROOT}/api/v1/campaigns/${activeBot}/infinite/?`;
    }
    if (nextCampaignPage === null) {
      baseCampaignsUrl += `&status=${status}`;
      if (search) {
        baseCampaignsUrl += `&search=${search}`;
      }
    }

    const notCachedUrl = `${baseCampaignsUrl}&cb=${new Date().getTime()}`;
    const campaignsUrl = isIE ? notCachedUrl : baseCampaignsUrl;

    this.setState(
      { xhr: true, loadingCampaigns: true, axiosSource: source },
      async () => {
        let get_error = false;
        let hasMore = false;
        let selectedBotCampaigns = [];

        try {
          const res = await axios.get(campaignsUrl, {
            cancelToken: this.state.axiosSource.token,
            ...tokenHeader(),
          });
          let { data: campaigns } = res;

          selectedBotCampaigns = campaigns.results;
          nextCampaignPage = campaigns.next;
          hasMore = nextCampaignPage !== null;
        } catch (error) {
          get_error = true;
          hasMore = false;
          nextCampaignPage = null;
          console.log(error);
        } finally {
          this.setState({
            xhr: false,
            errorLoadingCampaigns: get_error,
            nextCampaignPage: nextCampaignPage,
            botHasMoreCampaigns: hasMore,
            selectedBotCampaigns: this.state.selectedBotCampaigns.concat(
              selectedBotCampaigns
            ),
            allBotCampaigns: this.state.allBotCampaigns.concat(
              selectedBotCampaigns
            ),
            loadingCampaigns: false,
          });
        }
      }
    );
  };



  clearHistory = () => {
    this.props.history.replace(this.props.history.location.pathname, null);
  };

  async componentDidMount() {
    const Url = window.location.hostname;
    if (Url === 'chattigo.inceptia.ai') {
      this.setState({ isChattigo: true })
    }
    if (Url==='pelikanus.inceptia.ai') {
      this.setState({isPelikanus:true})
    }    
    try {
      await Promise.all([
        this.setPrevActiveBot(),
        this.setPrevSection(),
        this.setPrevReportingFilter(),
        this.setPrevCaseResultFilter(),
        this.setPrevPage(),
        this.setPrevCampaignFilters(),
      ]);

      await Promise.all([this.setPrevReportingDate(), this.clearHistory()]);

      if (!this.props.bots.length) {
        await Promise.all([this.fetchBots()]);
      } else {
        const { phonePriorities } = this.state;
        const { bots } = this.props;
        this.setPhonePriorities(bots, phonePriorities);
      }



      if (!Object.values(this.props.ui).length)
        await this.props.fetchCampaignStatuses();

      this.props.storeCasesDeleted();
      this.props.storeDetailsDeleted();
      await this.getBotData();
      await this.fetchCampaignResultsIds();

    } catch (error) {
      console.log(error);
      if (error && error.response) {
        const { status } = error.response;
        if (status === 401) {
          helpers.handleTokenExpiration();
        }
      }
    }
    if (this.props.bots.length > 0) {
      if (this.state.activeBot === null) {
        let selected = this.props.bots[0];
        this.handleClick(selected.id)
      }
    }


  }


  render() {
    let {
      isChattigo,
      isPelikanus,
      modal,
      titleInput,
      descriptionTextarea,
      confirmed,
      sent,
      file,
      exclusionsFile,
      cases,
      maxCalls,
      to,
      from,
      selected,
      showDatePicker,
      date,
      reportingFromDate,
      inboundReportingFromDate,
      reportingToDate,
      inboundReportingToDate,
      activeBot,
      activeFilter,
      activeReportingFilter,
      activeInboundReportingFilter,
      caseResultFilter,
      inboundCaseResultFilter,
      reportingData,
      inboundReportingData,
      totalreportingData,
      activeSection,
      activeTab,
      timeInvalid,
      search,
      inboundSearch,
      selectedBotCampaigns,
      selectedBotCampaignCases,
      selectedBotInboundCases,
      selectedBotDashboards,
      reportingFilterDict,
      casesStatusList,
      inboundCasesResultList,
      casesCount,
      inboundCasesCount,
      page,
      allBotCampaigns,
      allBotInboundCases,
      xhr,
      campaignId,
      campaignName,
      receivedWithErrors,
      processedWithErrors,
      network_failed,
      modalXhr,
      input,
      selectedCycles,
      availableCycles,
      canSelectCycles,
      shouldDownloadFile,
      contactability,
      columns_to_show,
      loadingBots,
      closes_eod,
      timezoneBot
    } = this.state;

    const { isLoading, ui } = this.props.ui;
    const { bots } = this.props;
    let campaignStatus;
    let inboundCasesStatus;
    if (!isLoading && ui) {
      campaignStatus = ui.campaignStatus;
      inboundCasesStatus = ui.inboundCasesStatus;
    }

    return (

      <div className={"home-page-container"}>
        {
          !activeBot
            ?
            <Loader />
            :
            <div className={"home-page"}>
              <div className={"home-page-sidebar"}>
                <Sidebar
                  onClick={this.handleClick}
                  bots={bots}
                  activeBot={activeBot}
                  xhr={loadingBots}
                  isChattigo={isChattigo}
                  isPelikanus={isPelikanus}
                />
              </div>
              <CustomModal
                modal={modal}
                backdropDisabled
                type={"campaign"}
                title={titleInput.value}
                description={descriptionTextarea.value}
                onChange={this.handleChange}
                confirmed={confirmed}
                sent={sent}
                onDrop={this.handleDrop}
                onDropExclusions={this.handleDropExclusions}
                onClick={this.handleSend}
                onCancel={this.handleCancel}
                file={file.value}
                exclusionsFile={exclusionsFile.value}
                titleIsValid={titleInput.isValid}
                descriptionIsValid={descriptionTextarea.isValid}
                fileIsValid={file.isValid}
                exclusionsFileIsValid={exclusionsFile.isValid}
                onDeleteFile={this.handleDeleteFile}
                onDeleteExclusions={this.handleDeleteExclusionsFile}
                cases={cases}
                maxCalls={maxCalls}
                onAdd={this.handleAdd}
                onRest={this.handleRest}
                from={from}
                to={to}
                onError={this.handleTimeError}
                onTimeChange={(value) => this.handleTimeChange(value)}
                onConfirm={this.handleConfirm}
                onSelect={this.handleSelect}
                selected={selected}
                showDatePicker={showDatePicker}
                onDateChange={this.handleDateChange}
                date={date}
                onDelete={this.handleDeleteCampaign}
                timeInvalid={timeInvalid}
                campaignId={campaignId}
                campaignName={campaignName}
                receivedWithErrors={receivedWithErrors}
                processedWithErrors={processedWithErrors}
                network_failed={network_failed}
                onBack={this.handleBack}
                xhr={modalXhr}
                onClose={this.handleClose}
                resultOptions={this.getResultOptions(activeBot)}
                filteredResults={this.getFilteredResults(activeBot)}
                selectedCycles={selectedCycles}
                availableCycles={availableCycles.sort()}
                resultInput={input}
                onAddResultFilter={this.handleAddResultFilter}
                onSelectChange={this.handleFilterSelectChange}
                onCycleAdd={this.onCycleAdd}
                onCycleSelectChange={this.handleFilterSelectChange}
                onDeleteCycle={this.handleCycleDelete}
                onDeleteFilterTag={this.handleResultFilterTagDelete}
                resultFilterTag
                phonePriority={this.state.phonePriorities[activeBot]}
                maxPhonePriority={this.state.maxPhonePriorities[activeBot]}
                onIncrementPhonePriority={this.handleIncrementPhonePriority}
                onDecrementPhonePriority={this.handleDecrementPhonePriority}
                dialmode={this.state.dialmode}
                onDialModeChange={this.handleDialModeChange}
                onClosesEODChange={this.handleClosesEODChange}
                clearInput={this.clearInput}
                canSelectCycles={canSelectCycles}
                shouldDownloadFile={shouldDownloadFile}
                downloadFileControl={this.handleFileDownloadInput}
                contactability={contactability.value}
                contactabilityIsValid={contactability.isValid}
                closes_eod={closes_eod}
                handleClosesEODChange={this.handleClosesEODChange}
              />
              {isLoading ? (
                <Loader />
              ) : (
                <>
                  <div className={"home-page-content"}>
                    {this.state.outboundBot ? (
                      <BotCampaignNavbar
                        activeSection={activeSection}
                        search={search}
                        onChange={this.handleChange}
                        onClick={this.handleBotCampaignSection}
                        openModal={this.handleModal}
                        xhr={xhr}
                      />
                    ) : (
                      <BotInboundNavbar
                        activeSection={activeSection}
                        search={inboundSearch}
                        onChange={this.handleChange}
                        onClick={this.handleBotInboundSection}
                        xhr={xhr}
                      />
                    )}
                    {activeSection === "campaigns" ? (
                      <BotCampaign
                        openModal={this.handleModal}
                        activeFilter={activeFilter}
                        activeSection={activeSection}
                        onClick={this.handleFilter}
                        selectedBotCampaigns={selectedBotCampaigns}
                        statusList={campaignStatus}
                        onSort={this.handleSort}
                        allBotCampaigns={allBotCampaigns}
                        onScrollCampaigns={this.handleScrollCampaigns}
                        search={search}
                        xhr={xhr}
                        isChattigo={isChattigo}
                        isPelikanus={isPelikanus}
                        timezoneBot={timezoneBot}
                      />
                    ) : activeSection === "reports" ? (
                      (<Reporting
                        onClick={this.handleReportingFilter}
                        onFromDateChange={this.handleReportingFromDate}
                        onToDateChange={this.handleReportingToDate}
                        onTableChange={this.handleReportingTableChange}
                        onReportingCasesResultFilter={this.handleCaseResultFilter}
                        onChangeReportingTab={this.handleReportingTab}
                        onExport={this.handleExport}
                        cases={selectedBotCampaignCases}
                        dashboards={selectedBotDashboards}
                        refreshDashboards={this.updateDashboards}
                        reportingData={reportingData}
                        totalreportingData={totalreportingData}
                        casesCount={casesCount}
                        filterDict={reportingFilterDict}
                        resultFilterList={casesStatusList}
                        activeFilter={activeReportingFilter}
                        resultFilter={caseResultFilter}
                        activeBot={activeBot}
                        fromDate={reportingFromDate}
                        toDate={reportingToDate}
                        page={page}
                        search={search}
                        xhr={xhr}
                        network_failed={network_failed}
                        handleCloseAlertNetworkError={this.handleCloseAlertNetworkError}
                        isChattigo={isChattigo}
                        isPelikanus={isPelikanus}
                      />)
                    ) : activeSection === "inbound_reports" ? (
                      <ReportingInbound
                        onClick={this.handleInboundReportingFilter}
                        onFromDateChange={this.handleInboundReportingFromDate}
                        onToDateChange={this.handleInboundReportingToDate}
                        onTableChange={this.handleInboundReportingTableChange}
                        onReportingCasesResultFilter={
                          this.handleInboundCaseResultFilter
                        }
                        onExport={this.handleInboundExport}
                        cases={selectedBotInboundCases}
                        reportingData={inboundReportingData}
                        dashboards={selectedBotDashboards}
                        refreshDashboards={this.updateDashboards}
                        casesCount={inboundCasesCount}
                        filterDict={reportingFilterDict}
                        resultFilterList={inboundCasesResultList}
                        activeFilter={activeInboundReportingFilter}
                        resultFilter={inboundCaseResultFilter}
                        activeBot={activeBot}
                        fromDate={inboundReportingFromDate}
                        toDate={inboundReportingToDate}
                        page={page}
                        search={inboundSearch}
                        xhr={xhr}
                        columns_to_show={columns_to_show}
                        network_failed={network_failed}
                        handleCloseAlertNetworkError={this.handleCloseAlertNetworkError}
                        isChattigo={isChattigo}
                        isPelikanus={isPelikanus}
                        timezoneBot={timezoneBot}
                        
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              )}
            </div>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ui: state.ui,
    bots: state.bots,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchCampaignStatuses,
      fetchInboundCaseStatuses,
      storeBots: storeBots,
      storeCasesDeleted,
      storeDetailsDeleted,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
