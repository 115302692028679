export const STORE_DETAILS = "STORE_DETAILS";
export const STORE_DETAILS_DELETED = "STORE_DETAILS_DELETED";

export const storeDetails = (details) => {
  return { type: "STORE_DETAILS", payload: details };
};

export const storeDetailsDeleted = (details) => {
  return { type: "STORE_DETAILS_DELETED", payload: details };
};
