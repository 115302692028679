import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import _ from 'lodash'
import BootstrapTable from 'react-bootstrap-table-next'
import overlayFactory from 'react-bootstrap-table2-overlay'
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'

import columns from './columns'
import helpers from '../../lib/helpers'
import Export from '../../components/Export'
import formatters from '../../lib/formatters'
import './TableGeneral.scss'
import { FormattedMessage } from 'react-intl'

class TableGeneral extends Component {

  ObjectFloatToPercentage = (val) => {
    val.contacted_p = `${(val.contacted_p * 100).toFixed(2)}%`;
    val.promise_p = `${(val.promise_p * 100).toFixed(2)}%`;
    return val
  }

  render() {
    let {
      cases,
      xhr,
      casesCount,
      page,
      selectedCheckboxes,
      checkAll,
      campaignId,
      statusList,
      status,
      reportingFromDate,
      reportingToDate,
      activeReportingFilter,
      resultFilter,
      activeSection,
      activeBot,
      from,
      prevPath,
      isChattigo,
      isPelikanus
    } = this.props

    const rowStyle = row => {
      return { backgroundColor: '#EFF3F9' }
    }
    let data = []
    if (this.props.cases && this.props.cases instanceof Array) {
      data = cases.map(x => this.ObjectFloatToPercentage(x))
    }

    return (
      <div className={isChattigo || isPelikanus?'campaign-table-container-chattigo':'campaign-table-container'}>
        <div className={'campaign-table'}>
          <BootstrapTable
            columns={columns}
            remote
            keyField={'day'}
            bordered={false}
            data={data}
            noDataIndication={() => {
              return <p className={'center'}>
                {<FormattedMessage id="empty.info" />}
                {/* No hay información disponible */}
              </p>
            }}
            loading={xhr}
            overlay={overlayFactory({
              spinner: true,
              background: 'rgba(192,192,192,0.85)',
              text: 'Cargando usuarios...'
            })}
            onTableChange={this.props.onTableChange}
            rowStyle={rowStyle}
          />
        </div>
      </div>
    )
  }
}

export default withRouter(TableGeneral)
