import React, { Component } from 'react'
import './NewBotForm.scss'
import Cross from "../../assets/red.png";
import Tic from "../../assets/tic.png";
import Loader from "../Loader";

class NewBotForm extends Component {
    render() {
        let { edit, created, company, companyInvalid, xhr } = this.props

        if (created) {
            return (
                <div className="new-bot-form-container">
                    <img src={Cross} alt={'Cerrar'} className={'cross'} onClick={this.props.onClose} />
                    <div className="new-bot-form-content-success">
                        <img src={Tic} alt={'Tic'} />
                        <span>¡BOT CREADO!</span>
                    </div>
                    <div className={'new-bot-form-btns success'}>
                        <button className={'ok'} onClick={this.props.onCancel}>
                            ACEPTAR
                        </button>
                    </div>
                </div>
            )
        }

        return (
            <div className={'new-bot-form-container'}>
                <img src={Cross} alt={'Cerrar'} className={'cross'} onClick={this.props.onClose} />
                <div className={'new-bot-form'}>
                    {xhr && <Loader />}
                    <div className={'new-bot-form-title'}>
                        <span>{edit ? '' : 'NUEVO BOT'} </span>
                    </div>
                    <div className={`new-bot-form-content ${xhr && 'xhr'}`}>
                        <div className="new-bot-form-input">
                            <input
                                value={company}
                                placeholder={'bot'}
                                name={'companyInput'}
                                onChange={e => this.props.onChange(e.target)}
                            />
                            <label className={'error'}>{companyInvalid ? '* Ingrese un bot' : ''}</label>
                        </div>
                    </div>
                    <div className={`new-bot-form-btns ${xhr && 'xhr'}`}>
                        <button className={'next'} onClick={edit ? this.props.onSave : this.props.onClick} disabled={xhr}>
                            {edit ? 'GUARDAR' : 'CREAR'}
                        </button>
                        <button className={'cancel'} onClick={this.props.onClose} disabled={xhr}>
                            Cancelar
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

export default NewBotForm