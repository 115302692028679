/* eslint-disable react/prop-types */
import React, { Component } from "react";
import "./EditForm.scss";
import Profile from "../../assets/profile-pic.png";
import Phone from "../../assets/phone.png";
import Arrow from "../../assets/chevron.png";
import TimeRangePicker from '../TimeRangePicker';
import Delete from "../../assets/delete.png";
import Cross from "../../assets/red.png";
import CustomDatePicker from "../CustomDatePicker";
import helpers from '../../lib/helpers'
import Loader from "../Loader";
import CustomDropzone from '../CustomDropzone';
import AddTags from "../AddTags";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import { FormattedMessage } from 'react-intl';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';




class EditForm extends Component {
  render() {
    let {
      type,
      title,
      cases,
      maxCalls,
      from,
      to,
      selected,
      showDatePicker,
      date,
      timeInvalid,
      status,
      exclusionsFile,
      exclusionsFileInvalid,
      xhr,
      selectedCycles,
      availableCycles,
      input,
      shouldDownloadFile,
      downloadFileControl,
      closes_eod
    } = this.props;
    // When creating a campaign, if the bot can select cycles but has yet to select one we should not let him submit before selecting a cycle.
    const should_first_select_cycle = type !== "edit-campaign" && this.props.canSelectCycles && this.props.selectedCycles !== undefined && this.props.selectedCycles.length === 0

    return (
      <form className={"edit-form-container"}>
        {type === "edit-campaign" && (
          <img
            src={Cross}
            alt={"Cerrar"}
            className={"cross"}
            onClick={this.props.onClose}
          />
        )}
        <div className={"edit-form"}>
          {xhr && <Loader />}
          <div className={"edit-form-header"}>
            <FormattedMessage id="config.title" defaultMessage="Configuración de la campaña">
              {text =>
                <span
                  className={`edit-form-title ${type === "edit-campaign" && "fixed"}`}
                >{`${type === "edit-campaign"
                  ? text
                  : title
                  }`}
                </span>
              }
            </FormattedMessage>
          </div>
          <div className={`edit-form-content ${xhr && 'xhr'}`}>
            <div className={"edit-form-first-row"}>
              <div className={"edit-form-cases"}>
                <label>
                  <FormattedMessage id="newcamp.casos" />
                  {/* Casos */}
                </label>
                <div className={"cases"}>
                  <img src={Profile} alt={"Casos"} />
                  <span>{cases}</span>
                </div>
              </div>
              <div className={"edit-form-max-calls"}>
                <label>
                  <FormattedMessage id="newcamp.max" />
                  {/* Max. llamadas por caso */}
                </label>
                <div className={"max-calls"}>
                  <img src={Phone} alt={"Llamadas"} />
                  <span>{maxCalls}</span>
                  <div className={"max-calls-btns"}>
                    <button className={"up"} onClick={this.props.onAdd}>
                      <img src={Arrow} alt={"Mas"} />
                    </button>
                    <button className={"down"} onClick={this.props.onRest}>
                      <img src={Arrow} alt={"Menos"} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className={"edit-form-second-row"}>
              <div className={"edit-form-picker"}>
                <label>
                  <span className={'from-text'}>
                    <FormattedMessage id="newcamp.from" />
                    {/* Llamar desde las */}
                  </span>
                  <span className={'to-text'}>
                    <FormattedMessage id="newcamp.to" />
                    {/* Hasta las */}
                  </span>
                </label>
                <div className={"edit-form-picker-content"}>
                  <TimeRangePicker
                    timeStart={from}
                    timeEnd={to}
                    handleErrorChange={error => this.props.onError(error)}
                    handleRangeChange={value => this.props.onTimeChange(value)}
                  />
                </div>
                <label className={'error'}>{
                  timeInvalid &&
                  <FormattedMessage id="newcamp.error.info.rango.horario" />
                  // '* Ingrese un rango de horario válido'
                }
                </label>
              </div>
            </div>
            {type === "campaign" && this.props.canSelectCycles &&
              <div className={"edit-form-third-row"}>
                <div className={"edit-form-start"}>
                  <div className={'new-campaign-result-filter'}>
                    <label>
                      <FormattedMessage id="newcamp.mora" />
                      {/* Seleccionar ciclo de mora */}
                    </label>
                    {xhr ? <Loader /> :
                      <AddTags
                        options={availableCycles}
                        data={selectedCycles}
                        input={input}
                        cyclesFilter={availableCycles}
                        onAdd={this.props.onCycleAdd}
                        onDelete={this.props.onDeleteFilterTag}
                        onSelectChange={this.props.onSelectChange}
                        resultFilterTag
                      />
                    }
                  </div>
                </div>
              </div>
            }
            <div className={"edit-form-third-row"}>
              {(status === helpers.campaignStatus.scheduled || type === 'campaign') &&
                <div className={"edit-form-start"}>
                  <label>
                    <FormattedMessage id="newcamp.start" />
                    {/* Inicio campaña */}
                  </label>
                  <div className={"edit-form-start-content"}>
                    <div className={"when"}>
                      <div
                        onClick={() => {
                          this.props.onSelect("now");
                        }}
                        className={`now ${selected === "now" && "selected"}`}
                      >
                        <span>
                          <FormattedMessage id="newcamp.today" />
                          {/* Hoy */}
                        </span>
                      </div>
                      <div
                        onClick={() => {
                          this.props.onSelect("program");
                        }}
                        className={`program ${selected === "program" &&
                          "selected"}`}
                      >
                        <span>
                          <FormattedMessage id="newcamp.program" />
                          {/* Programar */}
                        </span>
                      </div>
                    </div>
                    {showDatePicker &&
                      <CustomDatePicker date={date} onDateChange={this.props.onDateChange} disablePast />
                    }
                  </div>
                </div>
              }
            </div>
            <ClosesEOD
              closes_eod={closes_eod}
              onClosesEODChange={this.props.onClosesEODChange}
            />

            {type === 'campaign' &&
              <div className={'edit-form-fourth-row'}>
                <CustomDropzone onDrop={this.props.onDropExclusions} file={exclusionsFile} fileInvalid={exclusionsFileInvalid} onDelete={this.props.onDeleteExclusions} />
              </div>
            }
          </div>

          {/* <div className={`edit-form-delete ${xhr && 'xhr'}`}>
            {type === 'edit-campaign' && (
              <button onClick={this.props.onDelete}>
                <img src={Delete} alt={"Borrar"} />
                <span>
                  <FormattedMessage id="newcamp.delete" /> */}
                  {/* Eliminar campaña */}
                {/* </span>
              </button>
            )}
          </div> */}
          
          {type === "campaign" && this.props.canSelectCycles &&
            <FormControlLabel
              value={shouldDownloadFile}
              control={
                <Switch
                  color="primary"
                  checked={shouldDownloadFile}
                  onChange={downloadFileControl}
                />
              }
              label="Descargar archivo"
              labelPlacement="start"
            />
          }

         

          <div className={`edit-form-btns ${xhr && 'xhr'}`}>
            <Tooltip title={should_first_select_cycle ? "por favor seleccionar por lo menos un ciclo de mora para poder finalizar" : ""} placement="top">
              <div className="submit-btn-container">
                <button className={`confirm ${should_first_select_cycle && 'xhr'}`} onClick={this.props.onConfirm} disabled={xhr || should_first_select_cycle}>
                  {type === "edit-campaign"
                    ? <FormattedMessage id="config.save" />
                    // ? "GUARDAR"
                    : <FormattedMessage id="newcamp.confirm" />
                    // : "CONFIRMAR"}
                  }
                </button>
              </div>
            </Tooltip>
            <button className={"cancel"} onClick={type === 'edit-campaign' ? this.props.onClose : this.props.onDelete} disabled={xhr}>
              {type === "edit-campaign"
                ? <FormattedMessage id="config.discard" />
                // ? "Descartar cambios" 
                : "Cancelar"}
            </button>
          </div>
        </div>
      </form>
    );
  }

  componentDidMount() {
    this.props.clearInput();
  }
}
function ClosesEOD(props) {
  const [checked, setChecked] = React.useState(props.closes_eod);
  const handleChange = (event) => {
    setChecked(event.target.checked);
    props.onClosesEODChange(event.target.checked);
  };
  return (
    <FormGroup onChange={handleChange} value={checked} className="checkbox">
      <FormControlLabel 
        value={checked}
        control={
          <Checkbox checked={checked} />
        } 
        label={ <FormattedMessage id="newcamp.onCloses" />}
      />
    </FormGroup>
  );
}

export default EditForm;
