import React, { Component } from "react";
import "./CampaignInfo.scss";
import Progress from "../Progress";
import Skeleton from "react-loading-skeleton";
import { FormattedMessage } from "react-intl";

class CampaignInfo extends Component {
  render() {
    let { title, description, percentage_completed,percentage_called, from, xhr } = this.props;
    return (
      <div
        className={`campaign-info-container ${
          from === "campaign" && "campaign"
        }`}
        style={{
          lineHeight: "54px",
          fontSize: "34px",
          display: xhr ? "block" : "flex",
          paddingRight: xhr && "30px",
          paddingTop: xhr && "12px",
        }}
      >
        {xhr ? (
          <Skeleton count={3} />
        ) : (
          <div className={"campaign-info"}>
            <h4>{title}</h4>
            <span className={"campaign-info-description"}>{description}</span>
            <div className={"campaign_progress"}>
               <span className="title"><FormattedMessage id="percentage.COMPLETED"/></span>
              <Progress percentage={percentage_completed} size={"big"} />
            </div>
            <div className={"campaign_progress"}>
              <span className="title"><FormattedMessage id="percentage.CALLED"/></span>
              <Progress percentage={percentage_called} size={"big"} />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default CampaignInfo;
{/* <FormattedMessage id="login.close"/> */}